<div role="checkbox" [attr.aria-checked]="isSelected" tabindex="0">
  <input
    class="input"
    type="checkbox"
    [name]="name"
    [id]="customId"
    [(ngModel)]="isSelected"
  />
  <label [for]="customId" class="card-container">
    <ng-content></ng-content>
    <div class="checked-dot" *ngIf="isSelected">
      <em class="pi pi-check"></em>
    </div>
  </label>
</div>
