import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { LogService } from "src/app/shared/services/log.service";
import { RoleService } from "src/app/shared/services/role.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { ResponseValidarTesteInicial } from "src/app/shared/tos/response-validar-teste-inicial.to";

@Component({
  selector: "app-inicio-acesso-rapido",
  templateUrl: "./inicio-acesso-rapido.component.html",
  styleUrls: ["./inicio-acesso-rapido.component.scss"],
})
export class InicioAcessoRapidoComponent implements OnInit {
  @Output() alert = new EventEmitter();
  @Input() configuracao: ResponseValidarTesteInicial;

  menuItens = [
    {
      id: "DASHBOARD",
      nome: "inicio.dashboard",
      descricao: "inicio.descricaoDashboard",
      textoBotao: "inicio.gerenciarEquipe",
      img: "assets/images/inicio/dashboard.png",
      habilitado: false,
      exibir: false,
      msgAlerta: "",
      onClick: () => {
        this.logService.append("Clicou para Dashboard");
        this.routeService.goToPortal();
      },
    },
    {
      id: "EAD",
      nome: "moduloEad",
      descricao: "inicio.descricaoModuleEad",
      textoBotao: "inicio.acessar",
      img: "assets/images/inicio/ead.png",
      habilitado: false,
      exibir: false,
      msgAlerta: "",
      onClick: () => {
        this.logService.append("Clicou para EAD");
        this.routeService.goToEAD();
      },
    },
    {
      id: "SIMULADOR",
      nome: "inicio.simularTesteProntidao",
      descricao: "inicio.descricaoSimularTesteProntidao",
      textoBotao: "inicio.praticarTeste",
      img: "assets/images/inicio/simulador.png",
      habilitado: false,
      exibir: false,
      msgAlerta: "",
      onClick: () => {
        this.logService.append("Clicou para Simulador");
        this.routeService.toIntroducaoSimulacao();
      },
    },
    {
      id: "CHECKLIST",
      nome: "inicio.checklistCovid",
      descricao: "",
      textoBotao: "inicio.acessar",
      img: "/assets/images/inicio/checklist-covid.png",
      habilitado: false,
      exibir: true,
      msgAlerta: "",
      onClick: () => {
        this.logService.append("Clicou para CHECKLIST");
        this.routeService.toPerguntasInicial();
      },
    },
    {
      id: "SAUDE_MENTAL",
      nome: "inicio.saudeMental",
      descricao: "",
      textoBotao: "inicio.acessar",
      img: "/assets/images/inicio/saude_mental.png",
      habilitado: false,
      exibir: true,
      msgAlerta: "",
      onClick: () => {
        this.logService.append("Clicou para SAUDE_MENTAL");
        this.routeService.toSaudeMental();
      },
    },
    {
      id: "EMOCIOGRAMA",
      nome: "inicio.emociograma",
      descricao: "",
      textoBotao: "inicio.acessar",
      img: "assets/images/emociograma/muito_feliz.png",
      habilitado: false,
      exibir: true,
      msgAlerta: "",
      onClick: () => {
        this.logService.append("Clicou para EMOCIOGRAMA");
        this.acessarEmociograma();
      },
    },
    {
      id: "PERGUNTAS_MATUTINIDADE",
      nome: "inicio.perguntasMatutinidade",
      descricao: "",
      textoBotao: "inicio.acessar",
      img: "assets/images/inicio/matutinidade.png",
      habilitado: false,
      exibir: false,
      msgAlerta: "",
      onClick: () => {
        this.logService.append("Clicou para Perguntas Matutinidade");
        this.routeService.toPerguntasMatutinidade();
      },
    },
    {
      id: "SEGURANCA_PSICOLOGICA",
      nome: "inicio.segurancaPsicologica",
      descricao: "",
      textoBotao: "inicio.acessar",
      img: "assets/images/inicio/seguranca_psicologica.png",
      habilitado: false,
      exibir: false,
      msgAlerta: "",
      onClick: () => {
        this.logService.append("Clicou para Segurança Psicologica");
        this.routeService.toSegurancaPsicologicaInicial();
      },
    },
  ];
  exibirAcessoRapido = true;

  constructor(
    private logService: LogService,
    private routeService: RouterService,
    private translateService: TranslateService,
    private sessionStorageService: SessionStorageService,
    private roleService: RoleService
  ) {}

  ngOnInit(): void {
    this.validarMenusExibidos(this.configuracao);
    this.validarMenusHabilitados(this.configuracao);
    this.definirMsgAlertas(this.configuracao);
    this.exibirAcessoRapido = this.getEtapasAtivas().length > 0;
    this.sessionStorageService.setItem(
      SessionStorageKeys.POSSUI_VARIAS_ETAPAS,
      this.getEtapasAtivas().length
    );
  }

  validarMenusExibidos(configuracao: ResponseValidarTesteInicial) {
    this.validarShowEad(configuracao);
    this.findById("PERGUNTAS_MATUTINIDADE").exibir =
      configuracao.perguntasMatutinidade?.length > 0;
    this.findById("CHECKLIST").exibir =
      configuracao.flHabilitadoPreTeste == "SIM";
    this.findById("SIMULADOR").exibir =
      configuracao.flHabilitadoAvaliacao == "SIM" &&
      configuracao.flExibeSimulador == "SIM";
    this.findById("SAUDE_MENTAL").exibir =
      configuracao.flHabilitadoSaudeMental == "SIM";
    this.findById("EMOCIOGRAMA").exibir =
      configuracao.flHabilitadoEmociograma == "SIM";
    this.findById("DASHBOARD").exibir = !this.roleService.isOnlyOperador();
    this.findById("SEGURANCA_PSICOLOGICA").exibir =
      configuracao.flHabilitadoApenasSegPsicologica == "SIM";
  }

  validarMenusHabilitados(configuracao: ResponseValidarTesteInicial) {
    this.findById("PERGUNTAS_MATUTINIDADE").habilitado =
      configuracao.perguntasMatutinidade?.length > 0;
    this.findById("CHECKLIST").habilitado =
      configuracao.flExecutarPreTesteDisponivel == "SIM";
    this.findById("SAUDE_MENTAL").habilitado =
      configuracao.flHabilitadoSaudeMental == "SIM";
    this.findById("EMOCIOGRAMA").habilitado =
      configuracao.flExecutarEmociogramaDisponivel == "SIM";
    this.findById("SIMULADOR").habilitado =
      configuracao.flHabilitadoAvaliacao == "SIM" &&
      configuracao.flExibeSimulador == "SIM";
    this.findById("DASHBOARD").habilitado = !this.roleService.isOnlyOperador();
    this.findById("SEGURANCA_PSICOLOGICA").habilitado =
      configuracao.flHabilitadoApenasSegPsicologica == "SIM" &&
      !configuracao.msgNotificacao;
  }

  definirMsgAlertas(configuracao: ResponseValidarTesteInicial) {
    this.findById("SEGURANCA_PSICOLOGICA").msgAlerta =
      configuracao.msgNotificacao;
  }

  validarShowEad(configuracao: ResponseValidarTesteInicial) {
    const exibeEad = configuracao?.cdExibeEAD !== "DESATIVADO";
    this.findById("EAD").exibir = exibeEad;
    this.findById("EAD").habilitado = exibeEad;
  }

  findById(id) {
    return this.menuItens.find((menu) => menu.id == id);
  }

  acessarEmociograma() {
    const checklist = this.findById("CHECKLIST");
    if (checklist.habilitado && checklist.exibir) {
      this.alert.emit(this.translateService.instant("inicio.avisoEmociograma"));
    } else {
      this.routeService.toEmociogramaInicial();
    }
  }

  getEtapasAtivas() {
    return this.menuItens.filter((menu) => menu.exibir);
  }
}
