import { Injectable } from '@angular/core';
import { Location } from "@angular/common";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../session-storage.service';
import { SessionStorageKeys } from '../../enums/session-storage-keys.enum';
import { RouterService } from '../router-service.service';
import { PageNames } from '../../enums/page-names.enum';
import { LocalStorageService } from '../local-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CurrentUrlGuard  {
  routerByNavigationBrowser: string;

  constructor(
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private routerService: RouterService,
    private location: Location,
    private router: Router
  ) {
    // Fica escundo sempre que o usuário clica na <- de voltar no navegador
    this.location.subscribe(popstateEvent => {
      // Verifica se o usuário está voltando via browser
      if (popstateEvent.pop) {
        // Verifica se já voltou todas as páginas possivel
        if (popstateEvent.url != '/redirect') {
          // Adiciona a variavel o path que está sendo retornado
          this.routerByNavigationBrowser = popstateEvent.url.replace('/', '');
        }
      }
    });
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const currentUrl = this.sessionStorageService.getItem<PageNames>(SessionStorageKeys.CURRENT_URL);
    const nextUrl = state.url.replace('/', '');

    if (!this.localStorageService.getItem(SessionStorageKeys.PASS_IN_REDIRECT)) {
      this.router.navigate(['/']);
      return false;
    }

    if (this.isLoginPage(currentUrl)) {
      return true;
    }

    if (environment.dev && environment.permitirPularEtapas) {
      return true;
    }

    const isAltorizado: boolean = (currentUrl || this.routerByNavigationBrowser) === nextUrl;
    if (!isAltorizado) {
      this.location.go('/' + currentUrl);
      switch (currentUrl) {
        case 'perguntas':
          this.routerService.toPerguntas();
          break;
        case 'teste':
          this.routerService.toTeste();
          break;
        case PageNames.PERGUNTAS_EPWORTH:
          this.routerService.toPerguntasEpworth();
          break;
        case PageNames.EMOCIOGRAMA:
          this.routerService.toEmociograma();
          break;
        case PageNames.PROCESSAR_TESTE:
          this.routerService.toProcessarTeste();
          break;
        case PageNames.NIVELPRONTIDAO:
          this.routerService.toNivelProntidao();
          break;
        case PageNames.FINALIZAR:
          return true;
        default:
          this.routerService.toInicio();
          break;
      }

    }
    return isAltorizado;

  }

  private isLoginPage(currentUrl: string) {
    return currentUrl == undefined;
  }
}
