export const BROWSER_VERSIONS = [
  {
    name: "Chrome",
    minVersion: 109,
  },
  {
    name: "Edge",
    minVersion: 100,
  },
  {
    name: "Safari",
    minVersion: 15,
  },
  {
    name: "Firefox",
    minVersion: 115,
  },
  {
    name: "Opera",
    minVersion: 105,
  },
];
