import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { from } from "rxjs";
import { ClientError } from "src/app/core/ClientError";
import { environment } from "src/environments/environment";
import { TipoErro } from "../enums/tipo-erro.enum";
import { NotificationService } from "./notification.service";
import { dispatchError } from "src/app/core/dispatchError";

@Injectable({
  providedIn: "root",
})
export class RetryService {
  private qtdTentativas: number = 0;
  private funcao: any;
  private exibirPopup: boolean;

  tempoEsperaNovaTentaviva: number = environment.retryTime;
  esperandoNovaTentativa: boolean = false;

  constructor(
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) {}

  retryInit(funcao: any, exibirPopup: boolean = true) {
    this.qtdTentativas = environment.retryMax;
    this.funcao = funcao;
    this.exibirPopup = exibirPopup;
    this.executar();
  }

  private executar() {
    from(this.funcao()).subscribe(undefined, (err) => {
      if (
        !window.navigator.onLine ||
        err?.error?.tpErro == TipoErro.IMPEDITIVO ||
        (this.retry() == false && err instanceof HttpErrorResponse)
      ) {
        dispatchError("ERRO_RETRY", err);
        throw err;
      }
    });
  }

  private retry() {
    this.qtdTentativas--;
    if (this.qtdTentativas < 0) {
      this.exibirPopup &&
        this.notificationService.notify({
          message: this.translateService.instant("tentativasReq.excedido"),
        });
      return false;
    }
    this.esperandoNovaTentativa = true;
    setTimeout(() => {
      this.esperandoNovaTentativa = false;
      this.executar();
    }, this.tempoEsperaNovaTentaviva * 1000);
  }
}
