import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { KeycloakService } from "keycloak-angular";
import { ModulosEnum } from "src/app/shared/enums/Modulos.enum";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { AuthService } from "src/app/shared/services/auth.service";
import { InactiveTimerService } from "src/app/shared/services/inactive-timer.service";
import { LogService } from "src/app/shared/services/log.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { ConfiguracaoTesteTO } from "src/app/shared/tos/configuracao-teste.to";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-finalizar",
  templateUrl: "./finalizar.component.html",
  styleUrls: ["./finalizar.component.scss"],
})
export class FinalizarComponent implements OnInit {
  showBotaoVoltar: boolean = true;
  nomeOperador: string;
  nomeModulo: ModulosEnum;

  constructor(
    private notificationService: NotificationService,
    private authService: AuthService,
    private translateService: TranslateService,
    private routeService: RouterService,
    private timerService: InactiveTimerService,
    private sessionStorageService: SessionStorageService,
    private keyclockService: KeycloakService,
    private router: ActivatedRoute,
    private logService: LogService
  ) {}

  ngOnInit(): void {
    const tempoSegundos = 30;
    this.timerService.start(this.sair.bind(this), tempoSegundos * 1000);
    this.nomeOperador =
      this.keyclockService.getKeycloakInstance().tokenParsed["name"];
    this.nomeModulo = this.router.snapshot.queryParams["modulo"];
    this.showBotaoVoltar = this.handleShowBotaoVoltar(this.nomeModulo);
    this.logService.append("Acessou tela finalizar", {
      modulo: this.nomeModulo,
    });
  }

  possuiVariasEtapas() {
    return (
      this.sessionStorageService.getItem<number>(
        SessionStorageKeys.POSSUI_VARIAS_ETAPAS
      ) -
        1 >
      0
    );
  }

  handleShowBotaoVoltar(nomeModulo: string) {
    const isSimulacao = nomeModulo === ModulosEnum.SIMULADOR;
    return isSimulacao || this.possuiVariasEtapas();
  }

  voltar() {
    this.logService.append("Clicou para voltar ao inicio");
    this.routeService.toInicio();
  }

  sair() {
    this.logService.append("Clicou para sair");
    if (environment.flEmbedded) {
      this.exibirMensagemBloqueado();
    }
    this.authService.handleLogout();
  }

  private exibirMensagemBloqueado() {
    this.notificationService.notify({
      message: this.translateService.instant("resultado.textoFinalizar"),
      acceptVisible: false,
    });
  }
}
