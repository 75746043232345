<div style="position: relative;">
  <ng-container *ngIf="exibirTextoEvolucao">
    <p *ngIf="isRegrediu()" class="texto-regrediu-evoluiu" translate>nivelProntidao.textoRegrediu</p>
    <p *ngIf="isProgrediu()" class="texto-regrediu-evoluiu" translate>nivelProntidao.textoProgrediu</p>
    <div class="container-imagens-regrediu-progrediu">
        <app-cached-img *ngIf="isRegrediu()" src="assets/images/regrediu.svg"></app-cached-img>
        <app-cached-img *ngIf="isProgrediu()" src="assets/images/progrediu.svg"></app-cached-img>
    </div>
  </ng-container>
</div>
<div id="containeGrafico" class="card grafico" style="position: relative;">
  <div style="position: relative">
    <span class="texto-excelente" translate>nivelProntidao.excelente</span>
    <img src="assets/images/escala-grafico.svg" class="escala-grafico"/>
    <span class="texto-precisa-melhorar" translate>nivelProntidao.precisaMelhorar</span>
  </div>
  <div [chart]="chart" class="escala-grafico"></div>
  <div id="ultimoTeste" [ngClass]="{'exibe-texto-ultimo-teste':showLabelUltimoTeste}" style="position: absolute; display: none;">
    <span class="label-ultimo-teste" translate> nivelProntidao.ultimoResultado</span>
  </div>
</div>


