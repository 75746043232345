import {
  JsonObject,
  JsonProperty,
  PropertyConvertingMode,
} from "json2typescript";
import { ItemPerguntaTo } from "./item-pergunta.to";
import { TipoPeriodicidadePerguntaEnum } from "../enums/tipo-periodicidade-pergunta.enum";

@JsonObject("PerguntaTO")
export class PerguntaTO {
  @JsonProperty("ar1", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  id: number = null;

  @JsonProperty("ar2", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsPergunta: string = null;

  @JsonProperty("ar3", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  nrOrdem: number = null;

  @JsonProperty("ar4", String, PropertyConvertingMode.IGNORE_NULLABLE)
  nmImagem: string = null;

  @JsonProperty("ar5", [ItemPerguntaTo], PropertyConvertingMode.IGNORE_NULLABLE)
  itens: ItemPerguntaTo[] = null;

  @JsonProperty("ar6", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsResposta: string = null;

  @JsonProperty("ar7", String, PropertyConvertingMode.IGNORE_NULLABLE)
  cdTipoPeriodicidade: TipoPeriodicidadePerguntaEnum = null;
}
