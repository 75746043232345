import { JsonObject, JsonProperty, PropertyConvertingMode } from "json2typescript";

@JsonObject("PerguntaDiscursivaTO")
export class PerguntaDiscursivaTO {

  @JsonProperty("pd2", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsPerguntaDiscursiva: string = null;

  @JsonProperty("pd1", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  id: number = null;

  @JsonProperty("pd3", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  nrOrdem: number = null;

  @JsonProperty("pd4", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsValorPadrao: string = null;

  @JsonProperty("pd5", String, PropertyConvertingMode.IGNORE_NULLABLE)
  cdValorPadrao: string = null;
}
