<app-header [titulo]="textoHeader" [subtitulo]="textoOrientacao | translate" />

<app-form-apoio-profissional
  [show]="showQuestionarioApoioProfissional"
  (submitData)="handleApoioProfisonalSuccess()"
/>

<div>
  <div
    class="conteudoDetalhe"
    [ngClass]="{ big: flExibeQuestionarioIlustrado }"
  >
    <span
      class="breadcrumb"
      tabindex="0"
      *ngIf="tiposPeriodicidade?.length > 1"
    >
      {{ "perguntas.textoQuestionario" | translate }}:
      {{ perguntasBemEstarIndex + 1 }} / {{ tiposPeriodicidade?.length }}
    </span>

    <app-bem-estar-ilustrado
      *ngIf="flExibeQuestionarioIlustrado"
      [perguntas]="perguntasBemEstar"
      [curentIndex]="perguntasBemEstarIndex"
      [tiposPeriodicidade]="tiposPeriodicidade"
      [(respostas)]="respostasBemEstar"
    />

    <app-bem-estar-texto
      *ngIf="!flExibeQuestionarioIlustrado"
      [perguntas]="perguntasBemEstar"
      [curentIndex]="perguntasBemEstarIndex"
      [tiposPeriodicidade]="tiposPeriodicidade"
      [(respostas)]="respostasBemEstar"
    />
  </div>

  <div class="buttons-container" style="text-align: center">
    <button
      *ngIf="tiposPeriodicidade?.length > 1"
      pButton
      [label]="'voltar' | translate"
      type="button"
      class="botao-branco"
      [disabled]="perguntasBemEstarIndex == 0"
      (click)="handleVoltar()"
    ></button>
    <button
      [label]="(isLast() ? 'botaoConcluir' : 'botaoProximo') | translate"
      pButton
      class="botao"
      (click)="handleConcluir()"
      [disabled]="loading"
    >
      <div class="loader" *ngIf="loading"></div>
    </button>
  </div>
</div>

<app-questionario-complementar
  (onSuccess)="handleQuestionarioSuccess($event)"
  (onChangeQuestion)="resetTempoUsuarioInativo()"
  [show]="showQuestionario"
  [perguntas]="questionarioComplementar"
/>
