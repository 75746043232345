<div class="container">
  <app-cached-img
    src="assets/images/inicio/avaliacao_prontidao.png"
    class="saudacao-img"
  />
  <div>
    <h2 class="titulo" translate [translateParams]="{ '0': nomeOperador }">
      inicio.ola
    </h2>
    <p class="subtitulo" translate>
      {{ textoSaudacao }}
    </p>
  </div>
</div>
