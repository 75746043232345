<app-header
  [titulo]="'segurancaPsicologica.titulo' | translate"
  [subtitulo]="'segurancaPsicologica.subTitulo' | translate"
>
</app-header>

<app-modal-adiar-seguranca-psicologica
  [exibir]="exibirModalAdiamento"
  [isQuestionarioOpcional]="isQuestionarioOpcional"
  (opcaoSelecionada)="handleOpcaoSelecionada($event)"
>
</app-modal-adiar-seguranca-psicologica>

<div class="conteudoDetalhe p-mt-5">
  <form>
    <app-questionario-geral
      [questionarios]="questionarios"
      [exibirStepper]="true"
      [exibirBreadcrumb]="false"
      [exibirTempoResposta]="true"
      [instrucao]="'segurancaPsicologica.instrucao' | translate"
      [loading]="loading"
      (onSuccess)="handleSubmit($event)"
    />
  </form>
</div>
