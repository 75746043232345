import { JsonObject, JsonProperty, PropertyConvertingMode } from "json2typescript";

@JsonObject("AlvoTO")
export class AlvoTO {
  @JsonProperty("ae1", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idSubBloco: number = null;

  @JsonProperty("ae2", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  tipo: number = null;

  @JsonProperty("ae3", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  responseTime: number = null;

  //auxiliares
  @JsonProperty("ae4", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idImagem: number = null;
}
