import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simulador-borda',
  templateUrl: './simulador-borda.component.html',
  styleUrls: ['./simulador-borda.component.scss']
})
export class SimuladorBordaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
