import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { JsonSerializeService } from "../services/json-serialize.service";
import { RespostaPerguntaMatutinidadeV2TO } from "../tos/request-resposta-pergunta-matutinidade.to";
import { RequestUploadMatutinidadeV2TO } from "../tos/request-upload-pergunta-matutinidade.to copy";
import { SessionStorageService } from "../services/session-storage.service";
import { ResponseValidarTesteInicial } from "../tos/response-validar-teste-inicial.to";
import { SessionStorageKeys } from "../enums/session-storage-keys.enum";

@Injectable({
  providedIn: "root",
})
export class PerguntasMatutinidadeService {
  constructor(
    private httpClient: HttpClient,
    private serializer: JsonSerializeService,
    private sessionStorageService: SessionStorageService
  ) {}

  getPerguntas() {
    const configuracao =
      this.sessionStorageService.getItem<ResponseValidarTesteInicial>(
        SessionStorageKeys.CONFIGURACAO
      );
    const perguntasMatutinidade = configuracao?.perguntasMatutinidade;
    return perguntasMatutinidade;
  }

  salvarPerguntasMatutinidade(
    respostas: RespostaPerguntaMatutinidadeV2TO[]
  ): Observable<any> {
    const request = new RequestUploadMatutinidadeV2TO();
    request.respostasPerguntasMatutinidade = respostas;
    const payload = this.serializer.serialize(
      request,
      RequestUploadMatutinidadeV2TO
    );
    return this.httpClient.put<any>(
      `v2/teste-executado/upload-matutinidade`,
      payload
    );
  }
}
