import { Component, OnInit } from '@angular/core';
import { RouterService } from 'src/app/shared/services/router-service.service';

@Component({
  selector: 'app-configuracao',
  templateUrl: './configuracao.component.html',
  styleUrls: ['./configuracao.component.scss']
})
export class ConfiguracaoComponent implements OnInit {

  isOnlineSelecionado: boolean;
  classBotaoOnline: string;
  classBotaoOffline: string;

  constructor() { }

  ngOnInit() {
    this.isOnlineSelecionado = true;
    this.atualizaClassBotoes();
  }

  atualizaClassBotoes() {
    if (this.isOnlineSelecionado) {
      this.classBotaoOnline = 'backgroundGreen';
      this.classBotaoOffline = 'backgroundWhite';
    } else {
      this.classBotaoOffline = 'backgroundGreen';
      this.classBotaoOnline = 'backgroundWhite';
    }
  }

  setIsOnlineSelecionado(selecionado: boolean) {
    this.isOnlineSelecionado = selecionado;
    this.atualizaClassBotoes();
  }

}
