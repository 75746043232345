import {
  JsonObject,
  JsonProperty,
  PropertyConvertingMode,
} from "json2typescript";
import { TipoExibicaoPerguntaEnum } from "../enums/tipo-exibicao-pergunta.enum";
import { PerguntaOpcaoCompInseguroV2TO } from "./pergunta-opcao-comp-inseguro.to copy";

@JsonObject("PerguntaAdicionalV2TO")
export class PerguntaAdicionalV2TO {
  @JsonProperty("al1", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  id: number = null;

  @JsonProperty("al2", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsPergunta: string = null;

  @JsonProperty("al3", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  nrOrdem: number = null;

  @JsonProperty("al4", String, PropertyConvertingMode.IGNORE_NULLABLE)
  cdTipoExibicao: TipoExibicaoPerguntaEnum = null;

  @JsonProperty(
    "al5",
    [PerguntaOpcaoCompInseguroV2TO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  opcoes: PerguntaOpcaoCompInseguroV2TO[] = null;
}
