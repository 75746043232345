import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { TipoExibicaoPerguntaEnum } from "../../enums/tipo-exibicao-pergunta.enum";
import { PerguntaQuestionario, Questionario } from "./PerguntaQuestionario.to";

@Component({
  selector: "app-questionario-geral",
  templateUrl: "./questionario-geral.component.html",
  styleUrls: ["./questionario-geral.component.scss"],
})
export class QuestionarioGeralComponent implements OnInit, OnChanges {
  @Output() onSuccess = new EventEmitter<PerguntaQuestionario[]>();
  @Output() onChangeQuestion = new EventEmitter();
  @Input() questionarios: Questionario[];
  @Input() show: boolean;
  @Input() loading: boolean;
  @Input() exibirBreadcrumb: boolean = true;
  @Input() exibirStepper: boolean = false;
  @Input() exibirTempoResposta: boolean = false;
  @Input() instrucao: string = "";

  currentGroupIdx = 0;
  tempoResposta: number;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    if (this.exibirTempoResposta) {
      this.tempoResposta = this.calcularTempoResposta(this.questionarios);
    }
  }

  calcularTempoResposta(questionarios: Questionario[]) {
    const totalPerguntas = questionarios.reduce(
      (prev, cur) => (prev += cur.perguntas.length),
      0
    );
    const tempoEmMinutos = Math.ceil((totalPerguntas * 15) / 60);
    return tempoEmMinutos;
  }

  get qtdQuestionarios() {
    return this.questionarios?.length;
  }

  get currentGroup(): Questionario {
    return this.questionarios?.[this.currentGroupIdx];
  }

  get perguntas() {
    return this.currentGroup?.perguntas;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.questionarios && changes.questionarios.currentValue) {
      this.initRespostas(this.questionarios);
    }
  }

  initRespostas(questionario: Questionario[]) {
    questionario.forEach((q) => {
      q.perguntas.forEach((p) => {
        p.respostas = this.isRadio(p.tipo) ? null : [];
        if (this.isSelect(p.tipo)) {
          p.itens = [
            {
              label: this.translateService.instant("selecione"),
              value: null,
            },
            ...p.itens,
          ];
        }
      });
    });
  }

  isUltimaPergunta() {
    return this.currentGroupIdx === this.qtdQuestionarios - 1;
  }

  handleOnSubmit(form: NgForm) {
    if (form.valid) {
      this.onChangeQuestion.emit();
      window.scrollTo(0, 0);
      const nextQuestion = this.getProximoQuestionario(this.currentGroupIdx);
      if (nextQuestion == this.currentGroupIdx) {
        this.enviarRespostas();
      } else {
        this.currentGroupIdx = nextQuestion;
      }
    }
  }

  getProximoQuestionario(idxAtual: number) {
    for (let i = idxAtual + 1; i < this.qtdQuestionarios; i++) {
      for (let j = 0; j < this.questionarios[i].perguntas.length; j++) {
        const nextQuestion = this.questionarios[i].perguntas[j];
        if (
          !nextQuestion.shouldDisplay ||
          nextQuestion.shouldDisplay?.(this.questionarios, nextQuestion) == true
        ) {
          return i;
        }
      }
    }
    return idxAtual;
  }

  enviarRespostas() {
    const perguntas = this.questionarios
      .reduce((prev, cur) => [...prev, ...cur.perguntas], [])
      .filter((p) => p.respostas);
    this.onSuccess.emit(perguntas);
  }

  isRadio(tipo: TipoExibicaoPerguntaEnum) {
    return tipo === TipoExibicaoPerguntaEnum.RADIO || !tipo;
  }

  isCheck(tipo: TipoExibicaoPerguntaEnum) {
    return tipo === TipoExibicaoPerguntaEnum.CHECK;
  }

  isSelect(tipo: TipoExibicaoPerguntaEnum) {
    return tipo === TipoExibicaoPerguntaEnum.SELECT;
  }
}
