import { JsonObject, JsonProperty, PropertyConvertingMode } from "json2typescript";
import { TipoLiberacaoTesteInicialEnum } from "../enums/tipo-liberacao-teste-inicial.enum";

@JsonObject("ResultadoTesteInicialTO")
export class ResultadoTesteInicialTO {
  @JsonProperty("aa", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idPerguntaInicial: number = null
  
  @JsonProperty("ab", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsDica: string = null

  @JsonProperty("ad", String, PropertyConvertingMode.IGNORE_NULLABLE)
  cdTipoLiberacao: TipoLiberacaoTesteInicialEnum = null;
}
