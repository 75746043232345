import { Injectable, inject } from "@angular/core";

import { TranslateService } from "@ngx-translate/core";
import { KeycloakService } from "keycloak-angular";
import { PrimeNGConfig } from "primeng/api";
import { dateTranslateConfig } from "src/app/translate/date";

@Injectable({
  providedIn: "root",
})
export class IdiomaService {
  private config = inject(PrimeNGConfig);
  private translateService = inject(TranslateService);
  private keycloakAngularService = inject(KeycloakService);
  private avaiableTranslates = [
    "pt",
    "en",
    "de",
    "ar",
    "es",
    "hi",
    "ur",
    "id",
    "zh",
  ];

  configurarServicoTraducao() {
    const lang = this.getBaseLang();
    this.translateService.use(lang);
    this.translateService.setDefaultLang(lang);
    this.config.setTranslation(dateTranslateConfig[lang]);
  }

  getBaseLang() {
    const languageDefault =
      this.keycloakAngularService.getKeycloakInstance().tokenParsed[
        "usuario.idioma"
      ];
    const baseLang = languageDefault?.split("_")?.[0];
    const lang = this.hasI18nFile(baseLang) ? baseLang : "pt";
    return lang;
  }

  hasI18nFile(lang: string) {
    return this.avaiableTranslates.indexOf(lang) >= 0;
  }
}
