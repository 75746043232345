import { dispatchError } from "./../../core/dispatchError";
import { Injectable } from "@angular/core";

import { KeycloakService } from "keycloak-angular";
import { RouterService } from "./router-service.service";
import { SessionStorageKeys } from "../enums/session-storage-keys.enum";
import { LocalStorageService } from "./local-storage.service";
import { SessionStorageService } from "./session-storage.service";
import { RedirectUriService } from "./redirect-uri.service";
import { environment } from "src/environments/environment";
import { ConfiguracaoTesteTO } from "../tos/configuracao-teste.to";
import { BaseAutenticacaoEnum } from "../enums/BaseAutenticacao.enum";
import { LogService } from "./log.service";
import { finalize } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private keycloakService: KeycloakService,
    private routerService: RouterService,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private redirectUriService: RedirectUriService,
    private logService: LogService
  ) {}

  handleLogout() {
    this.logService.append("Deslogou", { flEmbedded: environment.flEmbedded });
    this.logService
      .sendLoteLogsObservable(2000)
      .pipe(
        finalize(() => {
          if (environment.flEmbedded) {
            this.logoutSemFluxo();
          } else {
            this.logoutFluxoPadrao();
          }
        })
      )
      .subscribe({ error: console.log });
  }

  private logoutFluxoPadrao() {
    const config = this.sessionStorageService.getItem<ConfiguracaoTesteTO>(
      SessionStorageKeys.CONFIGURACAO
    );
    const { cdBaseAutenticacao, dsUrlLogoutBaseAutenticacao } = config || {};
    let waitTime = 0;
    if (cdBaseAutenticacao === BaseAutenticacaoEnum.ADFS) {
      this.appendIframeLogoutAdfs(dsUrlLogoutBaseAutenticacao);
      waitTime = 500;
    }
    setTimeout(() => {
      const urlLogoutAzure = this.getLogoutAzure();
      const redirectUri = this.redirectUriService.getUri();
      const urlBase = this.routerService.getRootUrl();
      const searchParams = this.getSearchParams();
      this.localStorageService.removeItem(SessionStorageKeys.DESLOGAR_USUARIO);
      this.keycloakService.logout(
        (urlLogoutAzure || redirectUri || urlBase) + searchParams
      );
    }, waitTime);
  }

  private logoutSemFluxo() {
    this.localStorageService.removeItem(SessionStorageKeys.DESLOGAR_USUARIO);
    this.sessionStorageService.clear();
  }

  private getLogoutAzure() {
    try {
      const loginUsuario =
        this.keycloakService?.getKeycloakInstance?.()?.tokenParsed?.[
          "preferred_username"
        ];
      const loginIsEmail = loginUsuario?.indexOf?.("@") >= 0;
      const usarAzure =
        this.sessionStorageService.getItem<ConfiguracaoTesteTO>(
          SessionStorageKeys.CONFIGURACAO
        )?.cdBaseAutenticacao == BaseAutenticacaoEnum.AZURE;
      if (usarAzure && loginIsEmail) {
        return this.redirectUriService.getRedirectAzure();
      }
    } catch (err) {
      console.log(err);
      dispatchError("Erro logout Azure", err);
      return null;
    }
  }

  private appendIframeLogoutAdfs(link: string) {
    const iframe: HTMLIFrameElement = document.createElement("iframe");
    iframe.src = link;
    iframe.style.display = "none";
    document.body.appendChild(iframe);
  }

  private getSearchParams() {
    const params = new URLSearchParams();
    const tvParam: string = this.localStorageService.getItem(
      SessionStorageKeys.SME_TV
    );
    if (tvParam) {
      params.set("tv", tvParam);
    }
    return params.toString() ? `?${params.toString()}` : "";
  }
}
