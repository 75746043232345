import { Component, Input, OnInit } from "@angular/core";
import { FocusService } from "../../services/focus.service";

@Component({
  selector: "app-alert-bar",
  templateUrl: "./alert-bar.component.html",
  styleUrls: ["./alert-bar.component.scss"],
})
export class AlertBarComponent implements OnInit {
  @Input() text: string;

  show: boolean = false;

  constructor(private focusService: FocusService) {}

  ngOnInit(): void {}

  toggleAlert(mensagem: string) {
    this.text = mensagem;
    this.show = !this.show;
    if (this.show) {
      setTimeout(() => {
        this.focusService.setElementFocus("#alert-bar-container");
      });
    }
  }

  hideAlert() {
    this.show = false;
  }
}
