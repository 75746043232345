import { Component, OnInit } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { Roles } from "src/app/shared/enums/roles.enum";
import { RouterService } from "src/app/shared/services/router-service.service";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { Meta } from "@angular/platform-browser";
import { LocalStorageService } from "src/app/shared/services/local-storage.service";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";

@Component({
  selector: "app-redirect-auth",
  templateUrl: "./redirect-auth.component.html",
  styleUrls: ["./redirect-auth.component.scss"],
})
export class RedirectAuthComponent implements OnInit {
  constructor(
    protected keycloakAngularService: KeycloakService,
    private routerService: RouterService,
    private translateService: TranslateService,
    private metaService: Meta,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.localStorageService.setItem(SessionStorageKeys.PASS_IN_REDIRECT, true);

    //Continuar com o teste
    if (this.keycloakAngularService.isUserInRole(Roles.OPERADOR)) {
      //this.routerService.toIntroducao();
      this.routerService.toInicio();
    }
    //Redirecionar para o portal
    else {
      this.routerService.goToPortal();
    }
  }
}
