import {
  JsonObject,
  JsonProperty,
  PropertyConvertingMode,
} from "json2typescript";

@JsonObject("ResponseHistoricoClassifResultadoV2TO")
export class ResponseHistoricoClassifResultadoV2TO {
  @JsonProperty("aa", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  vlPercAtencao: number = null;

  @JsonProperty("ab", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  vlPercControleImpulsos: number = null;

  @JsonProperty("ac", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  vlPercTempoReacao: number = null;

  @JsonProperty("ad", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  vlPercConcentracao: number = null;
}
