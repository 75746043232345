export class DefaultPage {

  loading: boolean;
  isLogginOut: boolean;


  constructor(
  ) {
    document.onkeydown = function (e) {
      // keycode for F1 block func
      if (e.keyCode === 112) {
        return false;
      }
    };
  }
}




