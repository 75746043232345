<app-inicio-card>
  <h3 class="titulo" translate> inicio.historicoResultado </h3>
  <div class="content" *ngIf="!isLoading; else loadingTemplate">
    <app-historico-resultado 
      *ngIf="responseHistoricoEvolucao" 
      [historico]="responseHistoricoEvolucao"
      [exibirTextoEvolucao]="false"
      class="small"
    ></app-historico-resultado>
  </div>
</app-inicio-card>

<ng-template #loadingTemplate>
  <div class="loading-content">
    <div class="loader"></div>
  </div>
</ng-template>