import {
  Component,
  OnInit,
  HostListener,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { AlvoTO } from "src/app/shared/tos/alvo.to";
import { ResultadoTesteTO } from "src/app/shared/tos/resultado-teste.to";
import { AuthService } from "src/app/shared/services/auth.service";
import { RespostaPerguntaTO } from "src/app/shared/tos/resposta-pergunta.to";
import { ConfiguracaoTesteTO } from "src/app/shared/tos/configuracao-teste.to";
import { TesteService } from "src/app/shared/api-services/teste.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { ConfiguracaoTesteApiService } from "src/app/shared/api-services/configuracao-teste.api-service";
import { TranslateService } from "@ngx-translate/core";
import { saveAs as importedSaveAs } from "file-saver";

import { DefaultPage } from "../default-page";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { PerguntaInicialTO } from "src/app/shared/tos/perguntaInicial.to";
import { KeycloakService } from "keycloak-angular";
import { NotificationService } from "src/app/shared/services/notification.service";
import { TesteInicialTO } from "src/app/shared/tos/teste-inicial.to";
import { NotificationConfig } from "src/app/shared/model/notification-config.model";
import { environment } from "src/environments/environment";
import { PerguntaDiscursivaTO } from "src/app/shared/tos/pergunta-discursiva.to";
import { RespostaPerguntaDiscursivaTO } from "src/app/shared/tos/resposta-pergunta-discursiva.to";
import { RetryService } from "src/app/shared/services/retry.service";
import { PesquisaVacinacaoComponent } from "./pesquisa-vacinacao/pesquisa-vacinacao.component";
import { ModulosEnum } from "src/app/shared/enums/Modulos.enum";
import { ResponseValidarTesteInicial } from "src/app/shared/tos/response-validar-teste-inicial.to";
import { ResultadoTesteInicialTO } from "src/app/shared/tos/resultado-teste-inicial.to";
import { TipoLiberacaoTesteInicialEnum } from "src/app/shared/enums/tipo-liberacao-teste-inicial.enum";
import { LogService } from "src/app/shared/services/log.service";

@Component({
  selector: "app-perguntasinicial",
  templateUrl: "./perguntasinicial.component.html",
  styleUrls: ["./perguntasinicial.component.scss"],
})
export class PerguntasInicialComponent extends DefaultPage implements OnInit {
  @ViewChild(PesquisaVacinacaoComponent)
  pesquisaVacinacaoComponent: PesquisaVacinacaoComponent;

  perguntas: Array<PerguntaInicialTO>;
  respostas: Array<RespostaPerguntaTO>;
  configuracao: ResponseValidarTesteInicial;
  private acoes: Array<AlvoTO>;
  private resultadoTesteTO: ResultadoTesteTO;
  waiting: boolean;
  tempoEsperaParaHabilitarBotao: number;
  private temporizadorPerguntas: any;
  private isTempoInatividade: boolean = false;
  private nomeUsuario: any;
  isShowMesagem: boolean;
  isShowDownload: boolean;
  mostrarPerguntaSecundario: boolean = false;
  dsDica: any;
  idPerguntaInicial: number;
  addDica: any;
  TituloRetornoTeste: any;
  cssMsg: string;
  FlSegundoQuestionarioInicial: boolean = false;

  checked: boolean = false;
  exibirQuestionarioVacinacao: boolean = false;
  enviar: boolean = true;
  perguntaDiscursiva: PerguntaDiscursivaTO[];
  respostasVacinacao: RespostaPerguntaDiscursivaTO[];

  constructor(
    private sessionStorageService: SessionStorageService,
    private routerService: RouterService,
    private testeService: TesteService,
    private readonly authService: AuthService,
    private readonly configuracaoTesteApiService: ConfiguracaoTesteApiService,
    private readonly keycloakAngularService: KeycloakService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private retryService: RetryService,
    private logService: LogService
  ) {
    super();
  }

  ngOnInit() {
    this.logService.append("Acessou perguntas inicial");
    this.definirDadosDeAcordoProfile();
    this.loading = true;
    this.configuracao = this.sessionStorageService.getItem(
      SessionStorageKeys.CONFIGURACAO
    );
    this.exibirQuestionarioVacinacao = this.verificarQuestionarioVacinacao();
    if (this.configuracao.flQuestionarioDiscursivo == "SIM") {
      this.FlSegundoQuestionarioInicial = true;
      this.perguntaDiscursiva = this.configuracao.perguntasDiscursivas;
    }

    if (this.configuracao.flQuestionarioInicial == "SIM") {
      this.acoes = this.sessionStorageService.getItem(SessionStorageKeys.ALVOS);
      this.perguntas = this.configuracao.perguntasIniciais;

      this.respostas = this.perguntas.map((pergunta) => ({
        id: pergunta.id,
        flMarcada: false,
      }));
      //this.loading = true;

      this.tempoEsperaParaHabilitarBotao = 2300;
      setTimeout(() => {
        this.loading = false;
      }, this.tempoEsperaParaHabilitarBotao);
    } else {
      if (this.configuracao.flTesteProntidao == "SIM") {
        this.routerService.toIntroducao();
      } else {
        if (
          this.configuracao.msgNotificacao != "" &&
          this.configuracao.msgNotificacao != null
        ) {
          this.notificationService.notifyInfo({
            message: this.configuracao.msgNotificacao,
            handler: () => {
              this.Finalizar();
            },
          });
        }
      }
    }

    document.onkeydown = function (e) {
      //Bloqueio da tecla Ctrl
      if (e.ctrlKey) {
        return false;
      }
    };
  }

  definirDadosDeAcordoProfile() {
    this.nomeUsuario =
      this.keycloakAngularService.getKeycloakInstance().tokenParsed[
        "given_name"
      ];
  }

  handleConcluir(flEnviaRetornoParam: boolean) {
    this.retryService.retryInit(
      this.concluirTeste.bind(this, flEnviaRetornoParam)
    );
  }

  async concluirTeste(flEnviaRetornoParam: boolean) {
    if (this.enviar == true) {
      this.enviar = false;
      this.loading = true;

      //fazer filter, so abrir caso algum marcado this.respostas

      if (
        this.FlSegundoQuestionarioInicial &&
        this.respostas.find((x) => x.flMarcada == true) != null
      ) {
        this.mostrarPerguntaSecundario = true;
      } else {
        await this.salvar();
      }
    } //if(this.enviar == true)
  }

  private async salvar() {
    const processaTesteTO: TesteInicialTO = {
      idUsuario: this.configuracao.idUsuario,
      respostaPerguntas: this.respostas,
      respostaPerguntasDiscursivas: null,
    };
    if (
      this.FlSegundoQuestionarioInicial &&
      this.respostas.find((x) => x.flMarcada == true) != null
    ) {
      processaTesteTO.respostaPerguntasDiscursivas = [];
      this.perguntaDiscursiva.forEach(function (x) {
        if (
          x.dsValorPadrao != null &&
          x.dsValorPadrao != "" &&
          x.dsValorPadrao != undefined
        ) {
          let pg = new RespostaPerguntaDiscursivaTO();
          pg.id = x.id;
          pg.dsResposta = x.dsValorPadrao;
          processaTesteTO.respostaPerguntasDiscursivas.push(pg);
        }
      });
    }

    if (this.respostasVacinacao?.length) {
      processaTesteTO.respostaPerguntasDiscursivas =
        processaTesteTO.respostaPerguntasDiscursivas
          ? [
              ...processaTesteTO.respostaPerguntasDiscursivas,
              ...this.respostasVacinacao,
            ]
          : this.respostasVacinacao;
    }

    try {
      const retorno: ResultadoTesteInicialTO = await this.testeService
        .salvarTesteInicial(processaTesteTO)
        .toPromise();
      this.loading = false;
      this.isShowMesagem = true;
      this.cssMsg = this.getCssMessageClass(retorno.cdTipoLiberacao);
      this.addDica = "";
      this.setTituloRetornoTeste(retorno.cdTipoLiberacao);
      if (retorno.dsDica != "" && retorno.dsDica != undefined) {
        this.isShowDownload = false;
        this.idPerguntaInicial = retorno.idPerguntaInicial;
        //this.isShowMesagem = true;
        //URLs starting with http://, https://, or ftp://
        var replacePattern1 =
          /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        var replacedText = retorno.dsDica.replace(
          replacePattern1,
          '<a href="$1" target="_blank">$1</a>'
        );
        this.addDica += replacedText;
      } else {
        //this.authService.logoutFluxoPadrao();
        //this.isShowDownload =false;
        this.idPerguntaInicial = 0;
        this.isShowMesagem = true;
        this.addDica += this.translateService.instant(
          "perguntasInicial.semQueixa"
        );
      }
    } catch (error) {
      this.loading = false;
      this.enviar = true;
      throw error;
    }
  }

  getCssMessageClass(tipoLiberato: TipoLiberacaoTesteInicialEnum) {
    switch (tipoLiberato) {
      case TipoLiberacaoTesteInicialEnum.LIBERADO:
        return "sucesso";
      case TipoLiberacaoTesteInicialEnum.ALERTA:
        return "alerta";
      default:
        return "";
    }
  }

  setTituloRetornoTeste(liberado: TipoLiberacaoTesteInicialEnum) {
    if (liberado === TipoLiberacaoTesteInicialEnum.LIBERADO) {
      this.TituloRetornoTeste = this.translateService.instant(
        "perguntasInicial.tituloLiberado"
      );
    } else {
      this.TituloRetornoTeste =
        this.translateService.instant("notificacao.titulo");
    }
  }

  Finalizar(): void {
    this.routerService.toFinalizar(ModulosEnum.QUESTIONARIO_PRE_TESTE);
  }

  handleFinalizarClick() {
    this.retryService.retryInit(
      this.FinalizarQuestionarioComplementar.bind(this)
    );
  }

  FinalizarQuestionarioComplementar(): void {
    let campoObrigatorioVazio = false;
    this.perguntaDiscursiva.forEach(function (x) {
      if (
        x.dsValorPadrao == null ||
        x.dsValorPadrao == "" ||
        x.dsValorPadrao == undefined
      ) {
        campoObrigatorioVazio = true;
      }
    });

    if (!campoObrigatorioVazio) {
      this.mostrarPerguntaSecundario = false;
      this.salvar();
    } else {
      this.mostrarPerguntaSecundario = false;
      this.notificationService.notifyInfo({
        message: this.translateService.instant("campoObrigatorio"),
        handler: () => {
          this.mostrarPerguntaSecundario = true;
        },
      });
    }
  }

  Download(): void {
    this.testeService.downloadTesteInicialBlob().subscribe(
      (retorno) => {
        importedSaveAs(
          new Blob([retorno], { type: "application/pdf" }),
          "questionario-" + new Date().getTime()
        );
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
        this.enviar = true;
        throw error;
      }
    );
  }

  get esperandoNovaTentativa() {
    return this.retryService.esperandoNovaTentativa;
  }

  get tempoEsperaNovaTentaviva() {
    return this.retryService.tempoEsperaNovaTentaviva;
  }

  @HostListener("document:keypress", ["$event"])
  public onKey(event: KeyboardEvent) {
    const tecladoNumerico = event.keyCode || event.which;
  }

  private atualizarRespostaQuestionario(posicao: number) {
    this.respostas[posicao].flMarcada = !this.respostas[posicao].flMarcada;
  }

  abrirPesquisaVacinacao() {
    this.pesquisaVacinacaoComponent.toggle();
  }

  get isPesquisaVacinacaoVisible() {
    return this.pesquisaVacinacaoComponent?.show;
  }

  verificarQuestionarioVacinacao() {
    return this.configuracao.flQuestionarioVacinacao === "SIM";
  }

  setRespostasVacinacao(respostas: RespostaPerguntaDiscursivaTO[]) {
    this.respostasVacinacao = respostas;
  }
}
