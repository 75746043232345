import { Injectable, ErrorHandler } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { NotificationService } from "./notification.service";
import { ErroTO } from "../tos/erro.to";
import { NotificationConfig } from "../model/notification-config.model";
import { TipoErro } from "../enums/tipo-erro.enum";
import { AuthService } from "./auth.service";
import { ClientError } from "src/app/core/ClientError";
import { TranslateService } from "@ngx-translate/core";
import { LogService } from "./log.service";
import { dispatchError } from "src/app/core/dispatchError";

/**
 * Classe que faz o tratamento de erros global do sistema. Qualquer exceção gerada no sistema que não for tratada, passa
 * por esta classe. Caso o erro seja proveniente do backend, é feito o tratamento apropriado de acordo com o status do
 * erro.
 */
@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(
    private notificationService: NotificationService,
    private authService: AuthService,
    private translateService: TranslateService,
    private logService: LogService
  ) {}

  /**
   * Faz o tratamento do erro lançado.
   *
   * @param error erro capturado pelo ErrorHandler
   */
  handleError(error: any): void {
    if (!window.navigator.onLine) {
      this.logService.append("Offline, Sem internet", error, "ERROR");
      dispatchError("Offline", "Sem internet");
      this.notificationService.notifyError({
        message: this.translateService.instant("tentativasReq.semInternet"),
      });
    } else if (error instanceof HttpErrorResponse) {
      this.logService.append("Erro backend", error, "ERROR");
      this.handleBackendError(error);
      if (error.status == 0) {
        this.logService.append("Erro network", error, "ERROR");
        dispatchError("Erro network", { error, errorDetails: error?.error });
      } else if (error.status > 400) {
        dispatchError("Erro backend", error);
      }
    } else if (error.message && error.message.indexOf("Uncaught") < 0) {
      this.logService.append("Erro cliente", error, "ERROR");
      this.handleClientError(error);
      dispatchError("Erro cliente", error);
    } else {
      this.logService.append("Erro genérico", error, "ERROR");
      this.logService.sendLoteLogs();
      dispatchError("Erro genérico", error);
      throw error;
    }
    this.logService.sendLoteLogs();
  }

  /**
   * Faz o tratamento de um erro proveniente da comunicação com o backend do sistema.
   *
   * @param error erro de comunicação Http
   */
  handleBackendError(error: any): void {
    const erroTO: ErroTO = error.error;
    const message =
      (erroTO ? erroTO.dsErro : null) ||
      this.getDefaultMessage(error.status) ||
      "Erro inesperado";

    const config: NotificationConfig = { message: message };

    if (erroTO.tpErro == TipoErro.IMPEDITIVO) {
      config.handler = () => this.authService.handleLogout();
    }
    this.notificationService.notifyError(config);
  }

  handleClientError(message: string) {
    this.notificationService.notifyError({ message });
  }

  /**
   * Recupera a mensagem padrão para os determinados status HTTP.
   *
   * @param status status HTTP presente na requisição
   * @return a mensagem associada ao status HTTP
   */
  getDefaultMessage(status: number): string {
    switch (status) {
      case 0:
        return this.translateService.instant("statusError0");
      case 401:
        return this.translateService.instant("statusError401");
      case 403:
        return this.translateService.instant("statusError403");
      case 500:
        return this.translateService.instant("statusError500");
    }
  }
}
