import { NotificationType } from '../enums/notification-type.enum';
import { NotificationConfig } from '../model/notification-config.model';

export class Notification {
    constructor(
        public notificationConfig: NotificationConfig,
    ) {

    }
}
