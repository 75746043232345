import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";

import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

@Injectable()
export class ApiEndpointInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headers: any = null;
    let urlReq: any = "";
    if (req.url.includes("{external}") || req.url.includes("assets/i18n")) {
      urlReq = req.clone({ url: req.url.replace("{external}", "") });
    } else if (
      req.url.includes("prontos-temporario.s3") ||
      req.url.includes("prontos-temporario-dev-hmg.s3")
    ) {
      urlReq = req.clone({ headers, responseType: "arraybuffer" });
      urlReq.headers.lazyUpdate = [];
      return next.handle(urlReq);
    } else if (req.url.includes("geral-api")) {
      urlReq = req.clone({
        url: this.createURL(environment.geralEndpointPath, req.url),
      });
    } else {
      urlReq = req.clone({
        url: this.createURL(environment.apiEndpointPath, req.url),
      });
    }

    return next.handle(urlReq);
  }

  createURL(serverPath: string, reqURL: string): string {
    serverPath = serverPath.endsWith("/")
      ? serverPath.slice(0, -1)
      : serverPath;
    reqURL = reqURL.startsWith("/") ? reqURL : `/${reqURL}`;
    return serverPath + reqURL;
  }
}
