<app-header
 [titulo]="'epworth.titulo' | translate"
 [subtitulo]="'epworth.subtitulo' | translate">
</app-header>

<div class="conteudoDetalhe">
  <section class="epworth__legenda">
    <div class="epworth__item-legenda" *ngFor="let i of [0,1,2,3]">
      <span class="epworth__valor-legenda--{{i}}"> {{ i }} </span>
      <span class="epworth__label-legenda" translate> epworth.probabilidade{{i}} </span>
    </div>
  </section>

  <form #form="ngForm" class="epworth__perguntas" (submit)="finalizar(form)">
    <app-question-scale *ngFor="let pergunta of perguntas; let i = index"
      [label]="pergunta.dsPergunta"
      [(ngModel)]="respostas[i].vlEscala"
      name="vlEscala{{i}}"
      required>
    </app-question-scale>

    <div class="p-mt-4 p-mb-3">
      <button [label]="'botaoFinalizar' | translate" pButton class="botao" [disabled]="!form.valid"></button>
    </div>
  </form>

</div>