import { JsonObject, JsonProperty, PropertyConvertingMode } from 'json2typescript';
import { HistoricoNormalEnum } from "../enums/historico-normal.enum";
import { ResponseHistNivelResultadoTO } from "./response-hits-nivel-resultado.to";

@JsonObject("ResponseHistoricoV2TO")
export class ResponseHistoricoV2TO {
  
  @JsonProperty("aa", String, PropertyConvertingMode.IGNORE_NULLABLE)
  cdEvoluiuRegrediu: HistoricoNormalEnum = null;
  
  @JsonProperty("ab", [ResponseHistNivelResultadoTO], PropertyConvertingMode.IGNORE_NULLABLE)
  histNivelResultadoValores: ResponseHistNivelResultadoTO[] = null;
  
}
