import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { environment } from "src/environments/environment";
import { timeout } from "rxjs/operators";
import { of } from "rxjs";

interface Log {
  info: string;
  data?: Object;
  type?: "INFO" | "ERROR";
}

@Injectable({
  providedIn: "root",
})
export class LogService {
  private logs: string[] = [];

  constructor(private httpClient: HttpClient) {}

  append(msg: string, data?: Object, type: "INFO" | "ERROR" = "INFO") {
    const message = this.prepareLogMessage(msg, data, type);
    this.logs.push(message);
  }

  private getAndResetLogs() {
    setTimeout(() => this.reset());
    return this.logs;
  }

  private reset() {
    this.logs = [];
  }

  private prepareLogMessage(
    msg: string,
    data?,
    type: "INFO" | "ERROR" = "INFO"
  ) {
    const message = `${moment().format("HH:mm:ss")} [FRONT][${type}] ${msg}${
      data ? `[DATA] ${JSON.stringify(data?.message || data)}` : ""
    }`;
    return message;
  }

  sendLoteLogsObservable(maxWaitTime = 30000) {
    const lote = this.getAndResetLogs();
    if (lote.length && !environment.dev) {
      return this.httpClient
        .post<any>(`v2/teste-executado/log`, { aa: JSON.stringify(lote) })
        .pipe(timeout(maxWaitTime));
    } else {
      return of();
    }
  }

  sendLoteLogs() {
    this.sendLoteLogsObservable().subscribe({
      error: (err) => console.log("Erro ao enviar log", err),
    });
  }
}
