import { NgModule, ModuleWithProviders } from "@angular/core";

// TODO acertar os imports

import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DialogModule } from "primeng/dialog";
import { CheckboxModule } from "primeng/checkbox";
import { RadioButtonModule } from "primeng/radiobutton";
import { DropdownModule } from "primeng/dropdown";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { CalendarModule } from "primeng/calendar";
import { ToastModule } from "primeng/toast";
import { SkeletonModule } from "primeng/skeleton";
import { InputMaskModule } from "primeng/inputmask";
import { KnobModule } from "primeng/knob";
import { FocusTrapModule } from "primeng/focustrap";

const modules = [
  CheckboxModule,
  ConfirmDialogModule,
  RadioButtonModule,
  DialogModule,
  DropdownModule,
  CalendarModule,
  ToastModule,
  SkeletonModule,
  InputMaskModule,
  KnobModule,
  FocusTrapModule,
];

const services = [ConfirmationService, MessageService];

@NgModule({
  imports: [modules],
  exports: [modules],
})
export class PrimeNgModule {
  static forRoot(): ModuleWithProviders<PrimeNgModule> {
    return {
      ngModule: PrimeNgModule,
      providers: [services],
    };
  }
}
