import { Pipe, PipeTransform } from '@angular/core';
import { PerguntaDiscursivaTO } from 'src/app/shared/tos/pergunta-discursiva.to';

@Pipe({
  name: 'filtrarPerguntas'
})
export class FiltrarPerguntasPipe implements PipeTransform {

  transform(perguntas: PerguntaDiscursivaTO[], primeiraDoseTomada: boolean): unknown {
    return perguntas.filter(p => this.exibirPergunta(p, primeiraDoseTomada))
  }

  exibirPergunta(pergunta: PerguntaDiscursivaTO, primeiraDoseTomada: boolean) {
    const isPerguntaProximaDose = pergunta.nrOrdem === 3;
    return !isPerguntaProximaDose ||
      (isPerguntaProximaDose && primeiraDoseTomada)
  }

}
