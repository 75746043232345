import { Component, OnInit } from "@angular/core";
import saveAs from "file-saver";
import { ConfiguracaoTesteApiService } from "src/app/shared/api-services/configuracao-teste.api-service";
import { ModulosEnum } from "src/app/shared/enums/Modulos.enum";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { InactiveTimerService } from "src/app/shared/services/inactive-timer.service";
import { LogService } from "src/app/shared/services/log.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { ConfiguracaoTesteTO } from "src/app/shared/tos/configuracao-teste.to";
import { ResultadoTesteTO } from "src/app/shared/tos/resultado-teste.to";
import { environment } from "src/environments/environment";
import { BooleanEnum } from "../../enums/boolean.enum";

@Component({
  selector: "app-botoes-resultado",
  templateUrl: "./botoes-resultado.component.html",
  styleUrls: ["./botoes-resultado.component.scss"],
})
export class BotoesResultadoComponent implements OnInit {
  isEmbedded = environment.flEmbedded;
  configuracao: ConfiguracaoTesteTO;
  loadingDownload: boolean = false;
  exibirDownload: boolean = false;
  exibirDesempenho: boolean = false;

  constructor(
    private sessionStorageService: SessionStorageService,
    private configuracaoTesteService: ConfiguracaoTesteApiService,
    private inactiveTimerService: InactiveTimerService,
    private logService: LogService,
    private routerService: RouterService
  ) {}

  ngOnInit(): void {
    this.configuracao = this.sessionStorageService.getItem(
      SessionStorageKeys.CONFIGURACAO_TESTE
    );
    this.exibirDownload =
      this.configuracao.flExibeComprovanteProntidao == BooleanEnum.SIM;
    this.exibirDesempenho = this.shouldExibirDesempenho();
  }

  exibirBotaoEvolucao() {
    return (
      !this.isEmbedded &&
      this.configuracao.flExibeEvolucaoTeste == BooleanEnum.SIM
    );
  }

  shouldExibirDesempenho() {
    const resultado: ResultadoTesteTO = this.sessionStorageService.getItem(
      SessionStorageKeys.RESULTADO
    );
    return (
      resultado.vlDesempenhoAtual > 0 &&
      this.configuracao.flExibeDesempenhoTeste == "SIM"
    );
  }

  async concluir() {
    this.inactiveTimerService.stop();
    this.logService.append(`Concluir teste, encaminhando para Finalizar`);
    this.routerService.toFinalizar(ModulosEnum.AVALIACAO);
  }

  downloadComprovante() {
    this.loadingDownload = true;
    this.logService.append(`Download do comprovante`);
    this.configuracaoTesteService.getComprovanteProntidao().subscribe(
      (r) => {
        this.loadingDownload = false;
        saveAs(r, "Resultado_" + new Date().toLocaleString());
      },
      (err) => {
        this.loadingDownload = false;
        console.log(err);
        throw err;
      }
    );
  }

  goToNivelProntidao() {
    this.routerService.toNivelProntidao();
  }

  goToResultado() {
    this.routerService.toResultado();
  }

  goToDesempenho() {
    this.routerService.toDesempenho();
  }
}
