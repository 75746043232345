import { ConfiguracaoTesteTO } from "src/app/shared/tos/configuracao-teste.to";
import { AlvosEnum } from "../enums/Alvos.enum";

const CONFIGURACAO_SIMULADOR = new ConfiguracaoTesteTO();
CONFIGURACAO_SIMULADOR.referencia = {
  id: 1,
  qtTesteRegressao: 30,
  qtTesteIniciaisDescarte: 6,
  dsReferencia: "Referencia 01",
  dsAlvos: "!01.png,!02.png",
  qtAlvosErrados: 1,
  qtAlvosCertos: 1,
  dsAlvoConsufionalCentro: "setas_quadrado.jpg",
  dsAlvoConsufionalDireita: "setas_dir.jpg",
  dsAlvoConsufionalEsquerda: "setas_esq.jpg",
  dsTextoApresentacao: "referencia abc",
  dsTextoInstrucao: "instrucao abc",
  dsTextoInstrucaoQuestionario: "questionario abc",
  dsAlvosWeb: AlvosEnum.A2,
  subBlocos: [
    {
      id: 167,
      qtAlvosCorretos: 6,
      qtAlvosErrados: 6,
      qtTempoReset: 250,
      qtTempoDelay: 250,
      qtTempoExposicao: 400,
      qtTempoHide: 500,
      nrOrdem: 1,
    },
  ],
};

export { CONFIGURACAO_SIMULADOR };
