import { Injectable } from "@angular/core";
import { dispatchError } from "src/app/core/dispatchError";
import { dispatchEvent } from "src/app/core/dispatchEvent";
import { CacheService } from "./cache.service";
import { StringUtils } from "../utils/StringUtils";
import { DomSanitizer } from "@angular/platform-browser";
import v from "../../../assets/version.json";

@Injectable({
  providedIn: "root",
})
export class ImagemCacheService {
  private memory = {};
  private cacheName = `img-cache-${v.version}`;
  private cacheInitialUrls: string[] = [
    "assets/images/logo.png",
    "assets/images/teste/testeA/seta_direita_esquerda.png",
    "assets/images/teste/testeA/seta_esquerda.png",
    "assets/images/teste/testeA/seta_direita.png",
    "assets/images/teste/testeB/seta_redonda_direita.png",
    "assets/images/teste/testeB/seta_redonda_direita_cima.png",
    "assets/images/teste/testeB/seta_redonda_esquerda_baixo.png",
    "assets/images/teste/testeB/seta_redonda_esquerda_cima.png",
    "assets/images/teste/!01.png",
    "assets/images/teste/!02.png",
    "assets/images/teste/!05.png",
    "assets/images/teste/!06.png",
    "assets/images/teste/!p01.png",
    "assets/images/teste/!p02.png",
    "assets/images/teste/!p05.png",
    "assets/images/teste/resultado/atencao.png",
    "assets/images/teste/resultado/liberado.png",
    "assets/images/teste/resultado/supervisor.png",
    "assets/images/emociograma/agradecido.png",
    "assets/images/emociograma/cansado_sme.png",
    "assets/images/emociograma/estressado.png",
    "assets/images/emociograma/muito_feliz.png",
    "assets/images/emociograma/muito_triste_sme.png",
    "assets/images/emociograma/neutro_sme.png",
    "assets/images/emociograma/triste.png",
    "assets/images/emociograma/ansioso.png",
    "assets/images/emociograma/com_sono.png",
    "assets/images/emociograma/feliz.png",
    "assets/images/emociograma/muito_feliz_sme.png",
    "assets/images/emociograma/nervoso.png",
    "assets/images/emociograma/preocupado.png",
    "assets/images/emociograma/triste_sme.png",
    "assets/images/emociograma/bem.png",
    "assets/images/emociograma/doente.png",
    "assets/images/emociograma/feliz_sme.png",
    "assets/images/emociograma/muito_triste.png",
    "assets/images/emociograma/neutro.png",
    "assets/images/emociograma/relaxado.png",
    "assets/images/bem-estar/bacteria.png",
    "assets/images/bem-estar/cough.png",
    "assets/images/bem-estar/gerdau_confirmacao.png",
    "assets/images/bem-estar/gerdau_mais_cuidado_qrcode.jpg",
    "assets/images/bem-estar/snowflake.png",
    "assets/images/bem-estar/temperature.png",
    "assets/images/bem-estar/bacteria2.png",
    "assets/images/bem-estar/flower.png",
    "assets/images/bem-estar/gerdau_mais_cuidado.jpg",
    "assets/images/bem-estar/lungs.png",
    "assets/images/bem-estar/stomach.png",
    "assets/images/bem-estar-ilustrado/bebida_alcoolica.png",
    "assets/images/bem-estar-ilustrado/dificuldade_familiar.png",
    "assets/images/bem-estar-ilustrado/dificuldade_trabalho.png",
    "assets/images/bem-estar-ilustrado/indisposto.png",
    "assets/images/bem-estar-ilustrado/sonolento.png",
    "assets/images/bem-estar-ilustrado/dificuldade_concentrar.png",
    "assets/images/bem-estar-ilustrado/dificuldade_financeira.png",
    "assets/images/bem-estar-ilustrado/dor.png",
    "assets/images/bem-estar-ilustrado/medicamento_controlado.png",
    "assets/images/bem-estar-ilustrado/tristeza.png",
    "assets/images/inicio/avaliacao_prontidao.png",
  ];

  constructor(
    private cacheService: CacheService,
    private sanitizer: DomSanitizer
  ) {}

  acao(name: string) {
    dispatchEvent("IMAGE_CACHE", { acao: name });
  }

  async init() {
    try {
      this.acao("Iniciando cache");
      this.acao("Limpando cache antigo");
      await this.cacheService.cleanOldCaches(this.cacheName);
      this.acao("Cacheando imagens iniciais");
      await this.cacheImagensIniciais();
      this.acao("Carregando cache na memória");
      await this.saveCachedInMemory();
      this.acao("Cache inicializado com sucesso");
    } catch (err) {
      this.acao("Erro ao inicializar cache");
      dispatchError("IMAGE_CACHE_ERROR", err);
    }
  }

  async verificarImagensCacheCarregadas() {
    const cacheKeys = await this.cacheService.getCacheKeys(this.cacheName);
    if (cacheKeys.length < this.cacheInitialUrls.length) {
      return false;
    }
    return this.cacheInitialUrls.every((url) => {
      return cacheKeys.find((cacheUrl) => cacheUrl.indexOf(url) >= 0);
    });
  }

  public async getImagem(url: string) {
    const responeFromMemory = this.memory[url];
    if (responeFromMemory) {
      return responeFromMemory;
    }
    const responseFromCache = await this.cacheService.getFromCache(
      this.cacheName,
      url
    );
    if (responseFromCache) {
      const buffer = await responseFromCache.arrayBuffer();
      const base64 = StringUtils.arrayBufferToBase64(buffer);
      return this.sanitizer.bypassSecurityTrustUrl(
        "data:image;base64," + base64
      );
    }
    this.cacheService.addToCache(this.cacheName, url);
    return url;
  }

  private async saveCachedInMemory() {
    for (let url of this.cacheInitialUrls) {
      this.memory[url] = await this.getImagem(url);
    }
  }

  private async cacheImagensIniciais() {
    const requests = this.cacheInitialUrls.map((url) => {
      return this.cacheService.addToCache(this.cacheName, url);
    });
    await Promise.all(requests);
  }
}
