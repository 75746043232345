import { Component, Input, OnInit } from "@angular/core";

import { KeycloakService } from "keycloak-angular";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-header",
  templateUrl: "header.component.html",
  styleUrls: ["header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() titulo?: string;
  @Input() subtitulo?: string;
  @Input() exibirSair: boolean = false;

  nome: string;

  constructor(
    private keyclockService: KeycloakService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.nome = this.keyclockService.getKeycloakInstance().tokenParsed["name"];
  }

  sair() {
    this.authService.handleLogout();
  }
}
