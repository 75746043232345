<app-header></app-header>
<div class="conteudoCabecalho">
<div class="tituloSaudacao">
  {{dicaDia}}
</div>
</div>
<div class="dica">
    Para uma boa noite de sono...</div>
    <div class="conteudoDetalhe">
      <div class="p-col-7" [innerHTML]="textoDica"></div>

      </div>
      <div style="display:flex; padding-top: 60px;">
      <button style="margin: 0 auto"  label="Finalizar" pButton class="botao"></button>
    </div>
