<button
  *ngIf="exibirConversaLider"
  (click)="handleSolicitarApoio()"
  class="link--destaque-secundario"
  translate
  [disabled]="loadingApoio || !isConversaHabilitado"
>
  resultado.conversarComLider
  <div class="loader" *ngIf="loadingApoio"></div>
</button>
