import { Component, OnDestroy, OnInit } from "@angular/core";
import { ResultadoTesteTO } from "src/app/shared/tos/resultado-teste.to";
import { DefaultPage } from "../default-page";
import { RouterService } from "src/app/shared/services/router-service.service";
import { KeycloakService } from "keycloak-angular";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { getNmImagem } from "src/app/shared/enums/ImagemFinal.enum";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { environment } from "src/environments/environment";
import { ConfiguracaoTesteTO } from "src/app/shared/tos/configuracao-teste.to";
import { InactiveTimerService } from "src/app/shared/services/inactive-timer.service";
import { LogService } from "src/app/shared/services/log.service";
import { ModulosEnum } from "src/app/shared/enums/Modulos.enum";
import { FocusService } from "src/app/shared/services/focus.service";

@Component({
  selector: "app-resultado",
  templateUrl: "./resultado.component.html",
  styleUrls: ["./resultado.component.scss"],
})
export class ResultadoComponent
  extends DefaultPage
  implements OnInit, OnDestroy
{
  resultadoTesteTO: ResultadoTesteTO;
  nmImagem: string;
  isLogginOut: boolean;
  isDesempenho: boolean = true;
  exibirID: boolean = false;
  nomeUsuario: string;
  configuracao: ConfiguracaoTesteTO;
  isEmbedded = environment.flEmbedded;
  exibirDica = false;
  exibirBotaoContinuar = false;

  constructor(
    private sessionStorageService: SessionStorageService,
    private routerService: RouterService,
    protected keycloakAngularService: KeycloakService,
    private inactiveTimerService: InactiveTimerService,
    private logService: LogService,
    private focusSevice: FocusService
  ) {
    super();
  }

  ngOnInit() {
    this.logService.append("Acessou Resultado");
    this.resultadoTesteTO = this.sessionStorageService.getItem(
      SessionStorageKeys.RESULTADO
    );
    this.nmImagem = getNmImagem(this.resultadoTesteTO.cdImagemFinal);
    this.nomeUsuario =
      this.keycloakAngularService.getKeycloakInstance().tokenParsed[
        "given_name"
      ];
    this.configuracao = this.sessionStorageService.getItem(
      SessionStorageKeys.CONFIGURACAO_TESTE
    );

    //Definir se vai exibir o id do teste na tela
    let flExibirIdCondicao1 =
      this.configuracao.flExibeIdTesteImpedimentoResultado == "SIM" &&
      this.configuracao.flExibeIdTesteUsuarioApenasLiberado == "NAO";
    let flExibirIdCondicao2 =
      this.configuracao.flExibeIdTesteImpedimentoResultado == "SIM" &&
      this.configuracao.flExibeIdTesteUsuarioApenasLiberado == "SIM" &&
      this.resultadoTesteTO.flUsuarioLiberado == "SIM";
    this.exibirID = flExibirIdCondicao1 || flExibirIdCondicao2;

    document.onkeydown = function (e) {
      //Bloqueio da tecla Ctrl
      if (e.ctrlKey) {
        return false;
      }
    };

    //Timeout da tela
    this.inactiveTimerService.start();

    setTimeout(() => {
      this.exibirBotaoContinuar = true;
    }, 2000);
    this.logService.sendLoteLogs();
  }

  visualizarNivelDeProntidao() {
    this.inactiveTimerService.stop();
    this.routerService.toNivelProntidao();
  }

  continuar() {
    this.exibirDica = true;
    this.exibirBotaoContinuar = false;
    setTimeout(() => {
      this.focusSevice.setElementFocus("#resultado-dica");
    });
  }

  finalizar() {
    this.logService.append(
      "Finalizou resultado, encaminhar para tela Finalizar"
    );
    this.routerService.toFinalizar(ModulosEnum.AVALIACAO);
  }

  ngOnDestroy(): void {
    this.inactiveTimerService.stop();
  }
}
