<div class="pergunta-container">
  <div class="imagem-content" *ngIf="pergunta?.nmImagem">
    <app-cached-img
      src="assets/images/bem-estar/{{ pergunta?.nmImagem }}"
    ></app-cached-img>
  </div>
  <div class="pergunta-content">
    <span [innerHTML]="pergunta?.dsPerguntaInicial"></span>
  </div>
</div>
