import { Injectable } from "@angular/core";
import { SessionStorageKeys } from "../enums/session-storage-keys.enum";
import { getCaseSensitiveParams } from "../utils/url.utils";
import { LocalStorageService } from "./local-storage.service";

@Injectable({
  providedIn: "root",
})
export class LoginParamsService {
  private allowParams = [
    {
      storageKey: SessionStorageKeys.SME_DEVICE,
      paramUrl: "device",
      formatter: (value: string) => value.toUpperCase(),
    },
    {
      storageKey: SessionStorageKeys.SME_REDIRECT,
      paramUrl: "redirurl",
      formatter: (value: string) => value.toLowerCase(),
    },
    {
      storageKey: SessionStorageKeys.SME_TV,
      paramUrl: "tv",
      formatter: (value: string) => value.toLowerCase(),
    },
  ];

  constructor(private localStorage: LocalStorageService) {}

  saveParamsOnStorage() {
    const url = new URL(document.URL);
    this.allowParams.forEach((param) => {
      const paramValue = getCaseSensitiveParams(url.search).get(param.paramUrl);
      if (paramValue) {
        this.localStorage.setItem(
          param.storageKey,
          param.formatter(paramValue)
        );
      }
    });
  }
}
