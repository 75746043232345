import { JsonObject, JsonProperty, PropertyConvertingMode } from "json2typescript";

@JsonObject("PerguntaInicialTO")
export class PerguntaInicialTO {
  
  @JsonProperty("ac1", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  id: number = null;
  
  @JsonProperty("ac2", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsPerguntaInicial: string = null;
  
  @JsonProperty("ac3", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  nrOrdem: number = null;
  
  @JsonProperty("ac4", String, PropertyConvertingMode.IGNORE_NULLABLE)
  nmImagem: string = null;
}
