import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter, find } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FocusService {
  constructor(private router: Router) {}

  focusOnNavigate() {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => {
        setTimeout(() => {
          const focusOrdem = ["#header-titulo", "#header-sub-titulo"];
          for (let id of focusOrdem) {
            const findedId = this.setElementFocus(id);
            if (findedId) return;
          }
        }, 200);
      });
  }

  setElementFocus(element: string) {
    const el: any = document.querySelector(element);
    if (el) {
      el.focus();
      return true;
    }
  }
}
