import {
  JsonObject,
  JsonProperty,
  PropertyConvertingMode,
} from "json2typescript";

@JsonObject("ResponsePerguntaEpworthTO")
export class ResponsePerguntaEpworthTO {
  @JsonProperty("ap1", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  id: number = null;

  @JsonProperty("ap2", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsPergunta: string = null;

  @JsonProperty("ap3", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  nrOrdem: number = null;
}
