import { Subscription } from "rxjs";
import {
  Component,
  OnInit,
  HostListener,
  Renderer2,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import { AlvoTO } from "src/app/shared/tos/alvo.to";
import { ConfiguracaoTesteTO } from "src/app/shared/tos/configuracao-teste.to";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { DefaultPage } from "../default-page";
import { AlvosEnum } from "src/app/shared/enums/Alvos.enum";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { TipoDispositivoService } from "src/app/shared/services/tipo-dispositivo.service";
import { LogService } from "src/app/shared/services/log.service";
import { ACAO_TESTE } from "./acao-teste.enum";
import { ActivatedRoute } from "@angular/router";
import { ModulosEnum } from "src/app/shared/enums/Modulos.enum";
import { ZoomBlockerService } from "src/app/shared/services/zoom-blocker.service";

let worker: any;
@Component({
  selector: "app-teste",
  templateUrl: "./teste.component.html",
  styleUrls: ["./teste.component.scss"],
})
export class TesteComponent extends DefaultPage implements OnInit, OnDestroy {
  isSimulacao: boolean;
  isMobile: boolean;
  isTesteStarted: boolean = false;
  /* variáveis do teste */
  private idImg: number = 0;
  private delayIni: any;
  private delayAtual: any;
  private qtTempoReset: number = 0;
  private qtTempoHide: number = 0;
  private qtTempoDelay: number = 0;
  private qtTempoExposicao: number = 0;
  private qtdCorretos: number = 0;
  private qtdErrados: number = 0;
  private numeroExibicoes: number = 0;
  public tempoEsperaInicioTeste: number = 4000;

  public imagemDistracao: string = "";
  public caminhoImagemExame: string = "";
  public idImagemExame: string = "";
  public currentImagemDist: string = "";
  private subBlocoAtual: number = 0;

  public imagens: Array<string>;
  public imagensIncorretas: Array<string> = new Array<string>();
  public imagensCorretas: Array<string> = new Array<string>();
  public imagensTela: Array<string> = Array<string>();
  public imagensTotais: Array<string> = Array<string>();
  private acoesUsuario: Array<AlvoTO> = new Array<AlvoTO>();
  private acoesGeradas: Array<AlvoTO> = new Array<AlvoTO>();
  private acoesGeradasMap = new Map();
  private acoesUsuarioMap = new Map();
  private acoesTotais: Array<AlvoTO> = new Array<AlvoTO>();

  private indiceImagemAtual: number = 0;

  private tempoReacoes = new Map();
  private tempoImagem = new Map();

  configuracaoTeste: ConfiguracaoTesteTO;

  isImagemExameVisible: boolean = false;
  isImagemDistracaoVisible: boolean = false;

  isMobileRemove: boolean;

  tipoEventoRegistrarTap: string;
  isTipoTesteA: boolean;

  cantoTelaAtual: string = "";

  private cantosAleatorios: number[];
  private imgDistracaoAleatorio: number[];

  private caminhoFigurasDistracao: string = "assets/images/teste/";
  private pathTesteA: string = "testeA";
  private pathTesteB: string = "testeB";
  private zoomSubscription: Subscription;

  private imagesDistracaoTipoA = {
    1: `${this.caminhoFigurasDistracao}${this.pathTesteA}/seta_direita.png`,
    2: `${this.caminhoFigurasDistracao}${this.pathTesteA}/seta_esquerda.png`,
    3: `${this.caminhoFigurasDistracao}${this.pathTesteA}/seta_direita_esquerda.png`,
  };

  private imagesDistracaoTipoB = {
    1: `${this.caminhoFigurasDistracao}${this.pathTesteB}/seta_redonda_direita.png`,
    2: `${this.caminhoFigurasDistracao}${this.pathTesteB}/seta_redonda_direita_cima.png`,
    3: `${this.caminhoFigurasDistracao}${this.pathTesteB}/seta_redonda_esquerda_baixo.png`,
    4: `${this.caminhoFigurasDistracao}${this.pathTesteB}/seta_redonda_esquerda_cima.png`,
  };

  private cantosTelaClass = {
    1: "supEsquerda",
    2: "supDireita",
    3: "infEsquerda",
    4: "infDireita",
  };

  constructor(
    private readonly sessionStorageService: SessionStorageService,
    private readonly routerService: RouterService,
    private renderer: Renderer2,
    private tipoDispositivoService: TipoDispositivoService,
    private logService: LogService,
    private activatedRoute: ActivatedRoute,
    private zoomBlockerService: ZoomBlockerService
  ) {
    super();
  }

  ngOnInit() {
    this.isSimulacao = this.activatedRoute.snapshot.data["isSimulacao"];
    this.resetWebWorker();
    this.imagesInvisibles();
    this.visualizarOrientacaoToqueTela();
    this.listenWorker();
    this.verificarZoomAplicado();

    this.configuracaoTeste = this.sessionStorageService.getItem(
      SessionStorageKeys.CONFIGURACAO
    );
    this.imagensIncorretas = this.sessionStorageService.getItem(
      SessionStorageKeys.IMAGENS_INCORRETAS
    );
    this.imagensCorretas = this.sessionStorageService.getItem(
      SessionStorageKeys.IMAGENS_CORRETAS
    );

    this.imagens = new Array<string>();
    this.imagens = this.imagens.concat(this.imagensCorretas);
    this.imagens = this.imagens.concat(this.imagensIncorretas);

    document.body.style.background = "black";

    this.renderer.setStyle(document.body, "background-color", "black");
    this.renderer.setStyle(document.documentElement, "cursor", "none");

    this.startExame();

    this.logService.append("Iniciou teste", {
      webWorker: !!worker,
      dispositivo: this.tipoDispositivoService.getInfoDevice(),
      portrait: window.matchMedia("(orientation: portrait)").matches,
      landscape: window.matchMedia("(orientation: landscape)").matches,
      height: window.innerHeight,
      width: window.innerWidth,
      device: this.tipoDispositivoService.getDevice(),
      isTouch: this.tipoDispositivoService.isTouchDevice(),
      configuracao: this.configuracaoTeste,
      imagensIncorretas: this.imagensIncorretas,
      imagensCorretas: this.imagensCorretas,
    });

    document.onkeydown = function (e) {
      // keycode for F5 func
      if (e.keyCode === 116) {
        return false;
      }
      // keycode for block F3 func
      if (e.keyCode === 114) {
        return false;
      }
      // keycode for backspace
      if (e.keyCode === 8) {
        // try to cancel the backspace
        return false;
      }
      if (e.ctrlKey) {
        return false;
      }
    };
    this.logService.sendLoteLogs();
  }

  verificarZoomAplicado() {
    this.zoomSubscription = this.zoomBlockerService
      .onDimensionsMismatch()
      .subscribe(() => {
        if (this.isSimulacao) {
          this.routerService.toIntroducaoSimulacao();
        } else {
          this.routerService.toIntroducao();
        }
      });
  }

  listenWorker() {
    if (!worker) return;
    const acoesMap = {
      [ACAO_TESTE.EXIBIR_IMAGEM_DISTRACAO]: this.exibeImagemConf.bind(this),
      [ACAO_TESTE.ESCONDER_IMAGEM_DISTRACAO]:
        this.esconderImagemConf.bind(this),
      [ACAO_TESTE.EXIBIR_IMAGEM_CORRETA]: this.exibeImagem.bind(this),
      [ACAO_TESTE.ESCONDER_IMAGEM_CORRETA]: this.esconderImagem.bind(this),
      [ACAO_TESTE.VERIFICAR_PARADA]: this.verificaCriterioParada.bind(this),
      [ACAO_TESTE.REINICIAR]: this.configuracaoExame.bind(this),
    };
    worker.onmessage = ({ data }) => {
      acoesMap[data.acao]();
    };
  }

  imagesInvisibles() {
    this.isImagemDistracaoVisible = false;
    this.isImagemExameVisible = false;
    this.idImagemExame = null;
  }

  visualizarOrientacaoToqueTela() {
    if (this.tipoDispositivoService.isDesktop() == false) {
      this.isMobile = true;
    }
  }

  private montarArrayComTodasAsImagensdoTeste() {
    let contador = 0;
    for (contador = 0; contador < this.qtdCorretos; contador++) {
      this.imagensTotais.push(this.imagensCorretas[0]);
    }
    for (contador = 0; contador < this.qtdErrados; contador++) {
      const randomErrada = Math.floor(
        Math.random() * this.imagensIncorretas.length
      );
      this.imagensTotais.push(this.imagensIncorretas[randomErrada]);
    }
    this.imagensTotais = this.shuffle(this.imagensTotais);
    this.indiceImagemAtual = 0;
  }

  shuffle(array) {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  ngOnDestroy() {
    this.logService.append(`Destroy teste`);
    this.resetWebWorker();
    this.renderer.setStyle(document.body, "background-color", "initial");
    this.renderer.setStyle(document.documentElement, "cursor", "default");
    this.sessionStorageService.setItem(
      SessionStorageKeys.CONFIGURACAO,
      this.configuracaoTeste
    );
    this.zoomSubscription?.unsubscribe();
  }

  public registrarTap(event: Event) {
    if (!this.tipoEventoRegistrarTap) {
      this.tipoEventoRegistrarTap = event.type;
    }

    if (this.tipoEventoRegistrarTap === event.type) {
      this.logService.append("Tap pressionada: " + event.type);
      this.isMouseDown();
      this.gravarAcao();
    }
  }

  @HostListener("document:keypress", ["$event"])
  public onKey(event: KeyboardEvent) {
    const codigoEspaco = 32;
    const codigoEnter = 13;
    if (
      event.key == "Enter" ||
      event.key === " " ||
      event.keyCode === codigoEspaco ||
      event.keyCode === codigoEnter
    ) {
      this.logService.append("Tecla pressionada: " + event.key);
      this.gravarAcao();
    }
  }

  private getTipoImagem() {
    if (this.idImagemExame == "imagemCerta") {
      return 1;
    } else if (this.idImagemExame == "imagemErrada") {
      return 0;
    } else {
      return 2;
    }
  }

  saveImgTime(time: number) {
    this.tempoImagem.set(this.idImg, time);
  }

  saveReacaoTime() {
    this.tempoReacoes.set(this.idImg, Date.now());
    this.logService.append(
      `Tempo reação: ${this.tempoReacoes.get(this.idImg)} : ${Math.abs(
        this.tempoReacoes.get(this.idImg) - this.tempoImagem.get(this.idImg)
      )}`
    );
  }

  private gravarAcao() {
    const tipoImagemAtual: number = this.getTipoImagem();
    if (this.isTesteStarted && tipoImagemAtual != 2) {
      console.log("Ação foi gravada [" + tipoImagemAtual + "].");
      this.saveReacaoTime();

      const alvoTO: AlvoTO = {
        idSubBloco:
          this.configuracaoTeste.referencia.subBlocos[this.subBlocoAtual].id,
        tipo: tipoImagemAtual,
        responseTime: 0,
        idImagem: this.idImg,
      };

      const alvo = this.acoesUsuarioMap.get(this.idImg);
      if (alvo == undefined) {
        this.acoesUsuarioMap.set(this.idImg, alvoTO);
      }
    } else {
      this.logService.append(
        "Ação NAO  foi gravada [" + tipoImagemAtual + "]."
      );
      console.log("Ação NAO  foi gravada [" + tipoImagemAtual + "].");
    }
  }

  private startExame() {
    this.idImg = 0;
    this.subBlocoAtual = 0;
    setTimeout(
      () => {
        this.configuracaoExame();
      },
      !worker ? this.tempoEsperaInicioTeste : 0
    );
  }

  private configuracaoExame() {
    clearTimeout(this.delayIni);

    const subBloco =
      this.configuracaoTeste.referencia.subBlocos[this.subBlocoAtual];
    this.isTipoTesteA =
      this.configuracaoTeste.referencia.dsAlvosWeb === AlvosEnum.A ||
      this.configuracaoTeste.referencia.dsAlvosWeb === AlvosEnum.A2;

    this.qtTempoReset = +subBloco.qtTempoReset;
    this.qtTempoHide = +subBloco.qtTempoHide;
    this.qtTempoDelay = +subBloco.qtTempoDelay;
    this.qtTempoExposicao = +subBloco.qtTempoExposicao;
    this.qtdCorretos = +subBloco.qtAlvosCorretos;
    this.qtdErrados = +subBloco.qtAlvosErrados;
    this.numeroExibicoes = +this.qtdCorretos + this.qtdErrados;
    this.montarArrayComTodasAsImagensdoTeste();
    this.montarCantosAleatorios(this.imagensTotais);
    this.montarImagemDistracaoAleatorio(this.imagensTotais);

    this.sendMessageWorker(ACAO_TESTE.START, {
      tempoEsperaInicioTeste: this.tempoEsperaInicioTeste,
      qtTempoReset: this.qtTempoReset,
      qtTempoHide: this.qtTempoHide,
      qtTempoDelay: this.qtTempoDelay,
      qtTempoExposicao: this.qtTempoExposicao,
    });
    if (!worker) {
      this.delayAtual = setTimeout(() => {
        this.exibeImagemConf();
      }, this.qtTempoHide);
    }
  }

  private montarCantosAleatorios(imagens: string[]) {
    this.cantosAleatorios = imagens.map(() =>
      Math.floor(Math.random() * 4 + 1)
    );
  }

  private montarImagemDistracaoAleatorio(imagens: any[]) {
    this.imgDistracaoAleatorio = imagens.map(() => {
      const offset = this.isTipoTesteA ? 3 : 4;
      return Math.floor(Math.random() * offset + 1);
    });
  }

  private getImagemDistracao(id: number) {
    return this.isTipoTesteA
      ? this.imagesDistracaoTipoA[id]
      : this.imagesDistracaoTipoB[id];
  }

  private getCantoAleatorio(nmCanto: number) {
    return this.cantosTelaClass[nmCanto];
  }

  private exibeImagemConf() {
    this.isImagemExameVisible = false;
    this.isImagemDistracaoVisible = true;
    this.imagemDistracao = this.getImagemDistracao(
      this.imgDistracaoAleatorio[this.idImg]
    );
    this.sendMessageWorker(ACAO_TESTE.CONFIRMAR_EXIBIR_IMAGEM_DISTRACAO, null);
    if (!worker) {
      this.delayAtual = setTimeout(() => {
        this.esconderImagemConf();
      }, this.qtTempoReset);
    }
  }

  private esconderImagemConf() {
    clearTimeout(this.delayAtual);
    this.isImagemDistracaoVisible = false;
    this.sendMessageWorker(
      ACAO_TESTE.CONFIRMAR_ESCONDER_IMAGEM_DISTRACAO,
      null
    );
    if (!worker) {
      this.delayAtual = setTimeout(() => {
        this.exibeImagem();
      }, this.qtTempoDelay);
    }
  }

  private gerarIdTipoImagem(nmImagem) {
    if (this.imagensCorretas.includes(nmImagem)) return "imagemCerta";
    if (this.imagensIncorretas.includes(nmImagem)) return "imagemErrada";
    return null;
  }

  private exibeImagem() {
    clearTimeout(this.delayAtual);
    this.saveImgTime(Date.now());
    const nmImagem = this.imagensTotais[this.indiceImagemAtual];

    //canto da tela que a imagem será exibida
    this.cantoTelaAtual = this.getCantoAleatorio(
      this.cantosAleatorios[this.idImg]
    );
    this.idImagemExame = this.gerarIdTipoImagem(nmImagem);
    this.caminhoImagemExame = `assets/images/teste/${nmImagem}`;
    this.isImagemExameVisible = true;
    this.indiceImagemAtual++;

    this.logService.append(
      `${this.indiceImagemAtual} - Img ${this.idImagemExame}: ${Date.now()}`
    );
    this.gravarAcoesGeradas();
    this.isTesteStarted = true;
    this.sendMessageWorker(ACAO_TESTE.CONFIRMAR_EXIBIR_IMAGEM_CORRETA, null);
    if (!worker) {
      this.delayAtual = setTimeout(() => {
        this.esconderImagem();
      }, this.qtTempoExposicao);
    }
  }

  private gravarAcoesGeradas() {
    const alvoTO: AlvoTO = {
      idSubBloco:
        this.configuracaoTeste.referencia.subBlocos[this.subBlocoAtual].id,
      tipo: this.getTipoImagem(),
      responseTime: 0,
      idImagem: this.idImg,
    };

    this.acoesGeradasMap.set(this.idImg, alvoTO);
    this.acoesGeradas.push(alvoTO);
  }

  private esconderImagem() {
    clearTimeout(this.delayAtual);
    this.isImagemExameVisible = false;
    this.sendMessageWorker(ACAO_TESTE.CONFIRMAR_ESCONDER_IMAGEM_CORRETA, null);
    if (!worker) {
      this.delayAtual = setTimeout(() => {
        this.verificaCriterioParada();
      }, this.qtTempoHide);
    }
  }

  private verificaCriterioParada() {
    this.imagesInvisibles();
    clearTimeout(this.delayAtual);
    this.idImg++;
    this.numeroExibicoes = this.numeroExibicoes - 1;
    if (this.numeroExibicoes > 0) {
      //Tempo em que a tela fica preta
      this.sendMessageWorker(
        ACAO_TESTE.CONFIRMAR_VERIFICAR_PARADADA_CONTINUAR,
        null
      );
      if (!worker) {
        this.delayAtual = setTimeout(() => {
          this.exibeImagemConf();
        }, 0);
      }
    } else {
      this.subBlocoAtual = this.subBlocoAtual + 1;

      if (
        this.subBlocoAtual < this.configuracaoTeste.referencia.subBlocos.length
      ) {
        this.sendMessageWorker(
          ACAO_TESTE.CONFIRMAR_VERIFICAR_PARADADA_REINICIAR,
          null
        );
        if (!worker) {
          this.delayIni = setTimeout(() => {
            this.configuracaoExame();
          }, 0);
        }
      } else {
        this.sendMessageWorker(
          ACAO_TESTE.CONFIRMAR_VERIFICAR_PARADADA_FINALIZAR,
          null
        );
        if (this.isSimulacao) {
          this.logService.append("Finalizou simulacao");
          this.routerService.toFinalizar(ModulosEnum.SIMULADOR);
        } else {
          this.logService.append(`Ajustar Alvos`);
          this.ajustarAlvos();
          this.sessionStorageService.setItem(
            SessionStorageKeys.ALVOS,
            this.acoesTotais
          );
          this.sessionStorageService.setItem(
            SessionStorageKeys.PERGUNTAS,
            this.configuracaoTeste.perguntas
          );

          this.logService.append("Finalizou teste");
          this.routerService.toPerguntas();
        }
      }
    }
  }

  private ajustarAlvos() {
    this.acoesGeradasMap.forEach((value: AlvoTO, key: number) => {
      const alvo = this.acoesUsuarioMap.get(key);
      if (alvo == undefined) {
        this.acoesTotais.push(value);
      } else {
        this.calcularTempoReacao(alvo);
        this.acoesTotais.push(alvo);
      }
    });
  }

  calcularTempoReacao(alvo: AlvoTO) {
    const tempoImg = this.tempoImagem.get(alvo.idImagem);
    const tempoReacao = this.tempoReacoes.get(alvo.idImagem);
    alvo.responseTime = Math.abs(tempoImg - tempoReacao);
    return alvo;
  }

  isMouseDown() {
    if (this.isMobile == true) {
      this.isMobile = false;
      this.isMobileRemove = true;
    }
    setTimeout((_) => {
      if (this.isMobileRemove == true) {
        this.isMobileRemove = false;
        this.isMobile = true;
      }
    }, 90);
  }

  private sendMessageWorker(acao: ACAO_TESTE, info: any) {
    if (worker) {
      worker.postMessage({ acao, info });
    }
  }

  private resetWebWorker() {
    this.sendMessageWorker(ACAO_TESTE.RESET_WORKER, null);
  }
}

try {
  if (typeof Worker == "function") {
    worker = new Worker(
      window.location.origin + "/app/pages/teste/teste.worker.js"
    );
    console.log("Web Worker suportado");
  } else {
    worker = null;
    console.log("Web Worker não suportado.");
  }
} catch (err) {
  worker = null;
  console.log(err);
  console.log("Web Worker não suportado.");
}
