import { Component, OnInit } from "@angular/core";
import { PerguntasMatutinidadeService } from "src/app/shared/api-services/perguntas-matutinidade.service";
import {
  PerguntaQuestionario,
  Questionario,
} from "src/app/shared/components/questionario-geral/PerguntaQuestionario.to";
import { ModulosEnum } from "src/app/shared/enums/Modulos.enum";
import { LogService } from "src/app/shared/services/log.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { RespostaPerguntaMatutinidadeV2TO } from "src/app/shared/tos/request-resposta-pergunta-matutinidade.to";
import { ResponsePerguntaMatutinidadeTO } from "src/app/shared/tos/response-pergunta-matutinidade.to";

@Component({
  selector: "app-perguntas-matutinidade",
  templateUrl: "./perguntas-matutinidade.component.html",
  styleUrls: ["./perguntas-matutinidade.component.scss"],
})
export class PerguntasMatutinidadeComponent implements OnInit {
  questionario: Questionario[];
  loading: boolean = false;

  constructor(
    private perguntasMatutinidadeService: PerguntasMatutinidadeService,
    private routerService: RouterService,
    private logService: LogService
  ) {}

  ngOnInit(): void {
    const perguntasMatutinidade =
      this.perguntasMatutinidadeService.getPerguntas();
    this.logService.append("Acessou perguntas matutinidade");
    if (!perguntasMatutinidade) {
      this.logService.append(
        "Nao possui perguntas matutinidade, encaminhando para Introducao"
      );

      this.routerService.toIntroducao();
    } else {
      this.questionario = this.prepararPerguntas(perguntasMatutinidade);
    }
  }

  prepararPerguntas(
    perguntas: ResponsePerguntaMatutinidadeTO[]
  ): Questionario[] {
    const questionario = [{ perguntas: [] }];
    questionario[0].perguntas = perguntas.map((p) => ({
      id: p.id,
      dsPergunta: p.dsPergunta,
      obrigatorio: true,
      tipo: p.cdTipoExibicao,
      itens: p.opcoes.map((opc) => ({
        value: opc.id,
        label: opc.dsOpcao,
      })),
    }));
    return questionario;
  }

  onQuestionarioSuccess(perguntas: PerguntaQuestionario[]) {
    const respostasRequest: RespostaPerguntaMatutinidadeV2TO[] = perguntas.map(
      (p) => ({
        idPergunta: p.id,
        idOpcao: p.respostas,
      })
    );
    this.loading = true;
    this.perguntasMatutinidadeService
      .salvarPerguntasMatutinidade(respostasRequest)
      .subscribe(
        (response) => {
          this.loading = false;
          this.logService.append(
            "Finalizou perguntas matutinidade, encaminhando para Finalizar"
          );
          this.routerService.toFinalizar(ModulosEnum.PERGUNTAS_MATUTINIDADE);
        },
        (err) => {
          this.loading = false;
          throw err;
        }
      );
  }
}
