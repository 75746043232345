import { JsonObject, JsonProperty, PropertyConvertingMode } from "json2typescript";

@JsonObject("PerguntaOpcaoCompInseguroV2TO")
export class PerguntaOpcaoCompInseguroV2TO {
  @JsonProperty("al5a", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  id: number = null;

  @JsonProperty("al5b", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsOpcao: string = null;

  @JsonProperty("al5c", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  nrOrdem: number = null;
}
