import { ConfiguracaoComponent } from "./pages/configuracao/configuracao.component";
import { DicaComponent } from "./pages/dica/dica.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AuthComponent } from "./auth/auth.component";
import { IntroducaoComponent } from "./pages/introducao/introducao.component";
import { PerguntasComponent } from "./pages/perguntas/perguntas.component";
import { PerguntasInicialComponent } from "./pages/perguntasinicial/perguntasinicial.component";
import { TesteComponent } from "./pages/teste/teste.component";
import { CurrentUrlGuard } from "./shared/services/guards/current-url.guard";
import { AuthKeyCloakGuard } from "./shared/services/guards/auth-keycloak.guard";
import { RedirectAuthComponent } from "./pages/redirect-auth/redirect-auth.component";
import { ResultadoComponent } from "./pages/resultado/resultado.component";
import { InicioComponent } from "./pages/inicio/inicio.component";
import { FinalizarComponent } from "./pages/finalizar/finalizar.component";
import { ProcessarTesteComponent } from "./pages/processar-teste/processar-teste.component";
import { EmociogramaTesteComponent } from "./pages/emociograma/emociograma-teste.component";
import { EmociogramaInicioComponent } from "./pages/emociograma/emociograma-inicio.component";
import { DesempenhoComponent } from "./pages/desempenho/desempenho.component";
import { AbordagensComponent } from "./pages/abordagens/abordagens.component";
import { PerguntasCompInseguroComponent } from "./pages/perguntas-comp-inseguro/perguntas-comp-inseguro.component";
import { PerguntasMatutinidadeComponent } from "./pages/perguntas-matutinidade/perguntas-matutinidade.component";
import { PerguntasEpworthComponent } from "./pages/perguntas-epworth/perguntas-epworth.component";
import { PerguntasSamPerelliComponent } from "./pages/perguntas-sam-perelli/perguntas-sam-perelli.component";
import { PerguntasKarolinskaComponent } from "./pages/perguntas-karolinska/perguntas-karolinska.component";
import { SegurancaPsicologicaTesteComponent } from "./pages/seguranca-psicologica/seguranca-psicologica-teste.component";
import { SegurancaPsicologicaInicioComponent } from "./pages/seguranca-psicologica/seguranca-psicologica-inicio.component";
import { PerguntasAvulsoClienteComponent } from "./pages/perguntas-avulso-cliente/perguntas-avulso-cliente.component";

const routes: Routes = [
  {
    path: "inicio",
    component: InicioComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "redirect",
    component: RedirectAuthComponent,
    canActivate: [AuthKeyCloakGuard],
  },
  { path: "login", component: AuthComponent },
  {
    path: "introducao",
    component: IntroducaoComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
    children: [
      {
        path: "termo",
        loadChildren: () =>
          import(
            "./pages/introducao/termo-consentimento/termo-consentimento.module"
          ).then((m) => m.TermoConsentimentoModule),
      },
    ],
  },
  {
    path: "processar",
    component: ProcessarTesteComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "inicio/emociograma",
    component: EmociogramaInicioComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "emociograma",
    component: EmociogramaTesteComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "perguntas",
    component: PerguntasComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "perguntasinicial",
    component: PerguntasInicialComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "teste",
    component: TesteComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "resultado",
    component: ResultadoComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "desempenho",
    component: DesempenhoComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "dica",
    component: DicaComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "configuracao",
    component: ConfiguracaoComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "saude-mental",
    loadChildren: () =>
      import("./pages/saude-mental/saude-mental.module").then(
        (m) => m.SaudeMentalModule
      ),
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "finalizar",
    component: FinalizarComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "abordagens",
    component: AbordagensComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "perguntas-comp-inseguro",
    component: PerguntasCompInseguroComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "perguntas-sam-perelli",
    component: PerguntasSamPerelliComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "perguntas-karolinska",
    component: PerguntasKarolinskaComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "perguntas-avulso",
    component: PerguntasAvulsoClienteComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "perguntas-matutinidade",
    component: PerguntasMatutinidadeComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "nivel-prontidao",
    loadChildren: () =>
      import("./pages/nivel-prontidao/nivel-prontidao.module").then(
        (m) => m.NivelProntidaoModule
      ),
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "seguranca-psicologica",
    component: SegurancaPsicologicaTesteComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "inicio/seguranca-psicologica",
    component: SegurancaPsicologicaInicioComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "perguntas-epworth",
    component: PerguntasEpworthComponent,
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  {
    path: "simulador/introducao",
    component: IntroducaoComponent,
    canActivate: [AuthKeyCloakGuard],
    data: { isSimulacao: true },
  },
  {
    path: "simulador/teste",
    component: TesteComponent,
    canActivate: [AuthKeyCloakGuard],
    data: { isSimulacao: true },
  },
  {
    path: "comunicados",
    loadComponent: () =>
      import("./pages/comunicados/comunicados.component").then(
        (m) => m.ComunicadosComponent
      ),
    canActivate: [CurrentUrlGuard, AuthKeyCloakGuard],
  },
  { path: "", redirectTo: "redirect", pathMatch: "full" },
  { path: "**", redirectTo: "inicio" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
