export const zhDateTranslation = {
  accept: "是",
  addRule: "添加规则",
  am: "上午",
  apply: "应用",
  cancel: "取消",
  choose: "选择",
  chooseDate: "选择日期",
  chooseMonth: "选择月份",
  chooseYear: "选择年份",
  clear: "清除",
  completed: "已完成",
  contains: "包含",
  custom: "自定义",
  dateAfter: "日期晚于",
  dateBefore: "日期早于",
  dateFormat: "yy/mm/dd",
  dateIs: "日期为",
  dateIsNot: "日期不为",
  dayNames: [
    "星期日",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
  ],
  dayNamesMin: ["日", "一", "二", "三", "四", "五", "六"],
  dayNamesShort: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
  emptyFilterMessage: "未找到结果",
  emptyMessage: "无可用选项",
  emptySearchMessage: "未找到结果",
  emptySelectionMessage: "未选择任何项",
  endsWith: "以...结尾",
  equals: "等于",
  fileSizeTypes: ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
  filter: "筛选",
  firstDayOfWeek: 0,
  gt: "大于",
  gte: "大于等于",
  lt: "小于",
  lte: "小于等于",
  matchAll: "全部匹配",
  matchAny: "任意匹配",
  medium: "中",
  monthNames: [
    "一月",
    "二月",
    "三月",
    "四月",
    "五月",
    "六月",
    "七月",
    "八月",
    "九月",
    "十月",
    "十一月",
    "十二月",
  ],
  monthNamesShort: [
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月",
  ],
  nextDecade: "下一个十年",
  nextHour: "下一小时",
  nextMinute: "下一分钟",
  nextMonth: "下个月",
  nextSecond: "下一秒",
  nextYear: "下一年",
  noFilter: "无筛选",
  notContains: "不包含",
  notEquals: "不等于",
  now: "现在",
  passwordPrompt: "输入密码",
  pending: "待处理",
  pm: "下午",
  prevDecade: "上一个十年",
  prevHour: "上一小时",
  prevMinute: "上一分钟",
  prevMonth: "上个月",
  prevSecond: "上一秒",
  prevYear: "上一年",
  reject: "否",
  removeRule: "移除规则",
  searchMessage: "有 {0} 条结果可用",
  selectionMessage: "已选择 {0} 项",
  showMonthAfterYear: true,
  startsWith: "以...开始",
  strong: "强",
  today: "今天",
  upload: "上传",
  weak: "弱",
  weekHeader: "周",
  aria: {
    cancelEdit: "取消编辑",
    close: "关闭",
    collapseLabel: "折叠",
    collapseRow: "折叠行",
    editRow: "编辑行",
    expandLabel: "展开",
    expandRow: "展开行",
    falseLabel: "否",
    filterConstraint: "筛选条件",
    filterOperator: "筛选运算符",
    firstPageLabel: "首页",
    gridView: "网格视图",
    hideFilterMenu: "隐藏筛选菜单",
    jumpToPageDropdownLabel: "跳至页面下拉框",
    jumpToPageInputLabel: "跳至页面输入框",
    lastPageLabel: "尾页",
    listView: "列表视图",
    moveAllToSource: "全部移至源",
    moveAllToTarget: "全部移至目标",
    moveBottom: "移至底部",
    moveDown: "下移",
    moveTop: "移至顶部",
    moveToSource: "移至源",
    moveToTarget: "移至目标",
    moveUp: "上移",
    navigation: "导航",
    next: "下一个",
    nextPageLabel: "下一页",
    nullLabel: "未选择",
    otpLabel: "请输入一次性密码字符 {0}",
    pageLabel: "{page}",
    passwordHide: "隐藏密码",
    passwordShow: "显示密码",
    previous: "上一个",
    previousPageLabel: "上一页",
    removeLabel: "消除",
    rotateLeft: "向左旋转",
    rotateRight: "向右旋转",
    rowsPerPageLabel: "每页行数",
    saveEdit: "保存编辑",
    scrollTop: "滚动到顶部",
    selectAll: "已选择所有项目",
    selectLabel: "选择",
    selectRow: "选择行",
    showFilterMenu: "显示筛选菜单",
    slide: "滑动",
    slideNumber: "{slideNumber}",
    star: "1颗星",
    stars: "{star}颗星",
    trueLabel: "是",
    unselectAll: "全部取消选择",
    unselectLabel: "取消选择",
    unselectRow: "取消选择行",
    zoomImage: "查看大图",
    zoomIn: "放大",
    zoomOut: "缩小",
  },
};
