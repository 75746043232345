import { Component, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { KeycloakService } from "keycloak-angular";
import { ConfiguracaoTesteApiService } from "src/app/shared/api-services/configuracao-teste.api-service";
import { AlertBarComponent } from "src/app/shared/components/alert-bar/alert-bar.component";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { TipoErro } from "src/app/shared/enums/tipo-erro.enum";
import { AuthService } from "src/app/shared/services/auth.service";
import { BrowserVersionService } from "src/app/shared/services/browser-version.service";
import { LogService } from "src/app/shared/services/log.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { ResponseValidarTesteInicial } from "src/app/shared/tos/response-validar-teste-inicial.to";
import { InicioAcessoRapidoComponent } from "./inicio-acesso-rapido/inicio-acesso-rapido.component";
import { InactiveTimerService } from "src/app/shared/services/inactive-timer.service";
import { RoleService } from "src/app/shared/services/role.service";
import { Roles } from "src/app/shared/enums/roles.enum";

@Component({
  selector: "app-inicio",
  templateUrl: "./inicio.component.html",
  styleUrls: ["./inicio.component.scss"],
})
export class InicioComponent implements OnInit {
  @ViewChild(AlertBarComponent) alertBar: AlertBarComponent;
  @ViewChild(InicioAcessoRapidoComponent)
  inicioAcessoRapidoComponent: InicioAcessoRapidoComponent;

  nomeOperador: string;
  loadingConfig: boolean = true;
  configuracaoTeste: ResponseValidarTesteInicial;
  exibirComunicados: boolean;
  exibirNivelGeralProntidao: boolean;

  constructor(
    private keyclockService: KeycloakService,
    private configuracaoTesteApiService: ConfiguracaoTesteApiService,
    private sessionStorageService: SessionStorageService,
    private notificationService: NotificationService,
    private routeService: RouterService,
    private translateService: TranslateService,
    private browserVersionService: BrowserVersionService,
    private readonly authService: AuthService,
    private logService: LogService,
    private inactiveTimerService: InactiveTimerService,
    private roleService: RoleService
  ) {}

  ngOnInit() {
    this.logService.append("Acessou tela inicial");
    this.nomeOperador =
      this.keyclockService.getKeycloakInstance().tokenParsed["name"];
    this.getConfiguracao();
    this.inactiveTimerService.stop();
  }

  showMensagemBrowserInvalido() {
    this.notificationService.notifyInfo({
      message: this.translateService.instant("inicio.versaoBrowserInvalida"),
      handler: () => {
        this.authService.handleLogout();
      },
    });
  }

  getConfiguracao() {
    this.loadingConfig = true;
    this.configuracaoTesteApiService.getvalidarTesteInicialTO().subscribe(
      (configuracao) => {
        if (!this.browserVersionService.isValidBrowserVersion()) {
          this.logService.append("Browser Invalido");

          this.showMensagemBrowserInvalido();
          return;
        }

        this.configuracaoTeste = configuracao;
        this.sessionStorageService.setItem(
          SessionStorageKeys.CONFIGURACAO,
          configuracao
        );
        this.handlePermitirSessoes(this.configuracaoTeste);
        if (this.roleService.isOnlyOperador() && configuracao.msgNotificacao) {
          this.loadingConfig = false;
          this.logService.append("Exibiu mensagem de notificacao");
          this.notificationService.notifyInfo({
            message: configuracao.msgNotificacao,
            handler: () => {
              this.Finalizar();
            },
          });
        } else {
          setTimeout(() => {
            this.loadingConfig = this.proximaEtapa();
          });
        }
      },
      (err) => {
        this.loadingConfig = false;
        if (err?.error?.tpErro == TipoErro.EAD_PENDENTE) {
          this.notificationService.notifyInfo({
            message: this.translateService.instant("inicio.eadObrigatorio"),
            handler: () => {
              this.routeService.goToEAD();
            },
          });
        } else {
          throw err;
        }
      }
    );
  }

  Finalizar(): void {
    this.authService.handleLogout();
  }

  proximaEtapa(): boolean {
    const etapasAtivas = this.inicioAcessoRapidoComponent.getEtapasAtivas();
    if (etapasAtivas.length == 0) {
      if (this.configuracaoTeste?.flHabilitadoAvaliacao) {
        this.acessarAvaliacao();
        return true;
      } else {
        this.notificationService.notifyWarning({
          message: this.translateService.instant("inicio.moduloCompleto"),
          handler: this.sair.bind(this),
        });
      }
    }
    return false;
  }

  sair() {
    this.authService.handleLogout();
  }

  async acessarAvaliacao() {
    try {
      const checklist = this.inicioAcessoRapidoComponent.findById("CHECKLIST");
      this.logService.append("Clicou para AVALIACAO");
      if (checklist.habilitado && checklist.exibir) {
        this.logService.append("Realizar checklist primeiro");
        this.alertBar.toggleAlert(
          this.translateService.instant("inicio.avisoAvaliacao")
        );
        throw "Primeiro realizar o checklist";
      }
      this.routeService.toIntroducao();
    } catch (err) {
      console.log(err);
    }
  }

  isSimulador(id) {
    return id == "SIMULADOR";
  }

  handlePermitirSessoes(configuracaoTeste: ResponseValidarTesteInicial) {
    this.exibirComunicados = this.roleService.isRole("RL_VISUALIZAR_NOVIDADE");
    this.exibirNivelGeralProntidao =
      configuracaoTeste?.flHabilitadoApenasSegPsicologica == "NAO" &&
      this.roleService.isRole("RL_VISUALIZAR_PARAMETROS_TESTE");
  }
}
