import { ReferenciaTO } from "./referencia.to";
import { PerguntaTO } from "./pergunta.to";
import { BooleanEnum } from "../enums/boolean.enum";
import { PerguntaSegurancaPsicologicaTO } from "./pergunta-seguranca-psicologia.to";
import { EmociogramaTO } from "./emociograma.to";
import { BaseAutenticacaoEnum } from "../enums/BaseAutenticacao.enum";
import { ResponderQuestionarioSegPsicologicaEnum } from "../enums/responder-questionario-seg-psicologica.enum";
import {
  JsonObject,
  JsonProperty,
  PropertyConvertingMode,
} from "json2typescript";
import { ResponsePerguntaEpworthTO } from "./response-pergunta-epworth.to";

@JsonObject("ConfiguracaoTesteTO")
export class ConfiguracaoTesteTO {
  @JsonProperty("aa", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idUsuario: number = null;

  @JsonProperty("ab", String, PropertyConvertingMode.IGNORE_NULLABLE)
  nmUsuario: string = null;

  @JsonProperty("ac", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idTesteExecutado: number = null;

  @JsonProperty("ad", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dtExecucao: string = null;

  @JsonProperty("ae", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flExibeIdTesteImpedimentoResultado: BooleanEnum = null;

  @JsonProperty("af", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flExibeIdTesteUsuarioApenasLiberado: BooleanEnum = null;

  @JsonProperty("ag", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flExibeOrientacaoInicial: BooleanEnum = null;

  @JsonProperty("ah", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flIntrajornada: BooleanEnum = null;

  @JsonProperty("ai", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flExibeAnaliseGestaoOperador: BooleanEnum = null;

  @JsonProperty("aj", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flExibeTermoConsentimento: BooleanEnum = null;

  @JsonProperty("ak", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flExibeComprovanteProntidao: BooleanEnum = null;

  @JsonProperty("al", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flExibeQuestionarioIlustrado: BooleanEnum = null;

  @JsonProperty("am", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flExibeMensagem3TestesIncompletos: BooleanEnum = null;

  @JsonProperty("an", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flExibeSegurancaPsicologica: ResponderQuestionarioSegPsicologicaEnum = null;

  @JsonProperty("ao", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flExibeEmociograma: BooleanEnum = null;

  @JsonProperty("ap", String, PropertyConvertingMode.IGNORE_NULLABLE)
  cdBaseAutenticacao: BaseAutenticacaoEnum = null;

  @JsonProperty("aq", ReferenciaTO, PropertyConvertingMode.IGNORE_NULLABLE)
  referencia: ReferenciaTO = null;

  @JsonProperty("ar", [PerguntaTO], PropertyConvertingMode.IGNORE_NULLABLE)
  perguntas: PerguntaTO[] = null;

  @JsonProperty(
    "as",
    [PerguntaSegurancaPsicologicaTO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  perguntasSegurancaPsicologica: PerguntaSegurancaPsicologicaTO[] = null;

  @JsonProperty("at", [EmociogramaTO], PropertyConvertingMode.IGNORE_NULLABLE)
  emociogramas: EmociogramaTO[] = null;

  @JsonProperty("aw", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsUrlLogoutBaseAutenticacao: string = null;

  @JsonProperty("ax", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flExibeQuestionarioBemEstar: BooleanEnum = null;

  @JsonProperty("ay", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flExibeEvolucaoTeste: BooleanEnum = null;

  @JsonProperty("az", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flExibeDesempenhoTeste: BooleanEnum = null;

  @JsonProperty("bb", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsCelularUsuario: string = null;

  @JsonProperty("bc", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idContrato: number = null;
}
