import { JsonObject, JsonProperty, PropertyConvertingMode } from "json2typescript";
import { SituacaoSegurancaPsicologicaEnum } from "../enums/situacao-seguranca-psicologica.enum";

@JsonObject("RespostaSegurancaPsicologiaTO")
export class RespostaSegurancaPsicologiaTO {
  @JsonProperty("ai1", String, PropertyConvertingMode.IGNORE_NULLABLE)
  stResposta: SituacaoSegurancaPsicologicaEnum = null;
  
  @JsonProperty("ai2", [Number], PropertyConvertingMode.IGNORE_NULLABLE)
  idOpcoesMarcadas: number[] = null;
}
