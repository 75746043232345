import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PerguntaTO } from "src/app/shared/tos/pergunta.to";
import { RespostaPerguntaTO } from "src/app/shared/tos/resposta-pergunta.to";
import {
  BemEstarComponent,
  PerguntasBemEstar,
  RespostasBemEstar,
} from "../bem-estar-component.interface";
import { TipoPeriodicidadePerguntaEnum } from "src/app/shared/enums/tipo-periodicidade-pergunta.enum";

@Component({
  selector: "app-bem-estar-ilustrado",
  templateUrl: "./bem-estar-ilustrado.component.html",
  styleUrls: ["./bem-estar-ilustrado.component.scss"],
})
export class BemEstarIlustradoComponent implements OnInit, BemEstarComponent {
  @Input() perguntas: PerguntasBemEstar;
  @Input() respostas: RespostasBemEstar;
  @Input() curentIndex: number = 0;
  @Input() tiposPeriodicidade: TipoPeriodicidadePerguntaEnum[];
  @Output() respostasChange = new EventEmitter<Array<RespostaPerguntaTO>>();

  constructor() {}

  ngOnInit(): void {}

  getPerguntas(): PerguntaTO[] {
    const periodicidadeAtual = this.tiposPeriodicidade[this.curentIndex];
    return this.perguntas[periodicidadeAtual];
  }

  getRespostas(): RespostaPerguntaTO[] {
    const periodicidadeAtual = this.tiposPeriodicidade[this.curentIndex];
    return this.respostas[periodicidadeAtual];
  }
}
