import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SituacaoSegurancaPsicologicaEnum } from "src/app/shared/enums/situacao-seguranca-psicologica.enum";
import { NotificationService } from "src/app/shared/services/notification.service";

@Component({
  selector: "app-modal-adiar-seguranca-psicologica",
  templateUrl: "./modal-adiar-seguranca-psicologica.component.html",
  styleUrls: ["./modal-adiar-seguranca-psicologica.component.scss"],
})
export class ModalAdiarSegurancaPsicologicaComponent implements OnInit {
  @Input() exibir = false;
  @Input() isQuestionarioOpcional = false;
  @Output() opcaoSelecionada =
    new EventEmitter<SituacaoSegurancaPsicologicaEnum>();

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  handleClick(situacao: SituacaoSegurancaPsicologicaEnum | string) {
    this.exibir = false;
    this.opcaoSelecionada.emit(situacao as SituacaoSegurancaPsicologicaEnum);
  }

  handleConfirm(situacao: SituacaoSegurancaPsicologicaEnum) {
    this.notificationService.notify({
      rejectVisible: true,
      acceptLabel: this.translateService.instant(
        "segurancaPsicologica.realizarPesquisa"
      ),
      rejectLabel: this.translateService.instant(
        "segurancaPsicologica.naoRealizarPesquisa"
      ),
      message: this.translateService.instant(
        "segurancaPsicologica.notificarNaoRealizarPesquisa"
      ),
      handler: () =>
        this.handleClick(SituacaoSegurancaPsicologicaEnum.RESPONDIDO),
      handlerReject: () => this.handleClick(situacao),
    });
  }
}
