import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { NotificationType } from '../enums/notification-type.enum';
import { Notification } from '../view/notification.view'
import { NotificationConfig } from '../model/notification-config.model';

@Injectable({
  providedIn: 'root'
})
// TODO definir se vai ser por interface com múltiplas implementações ou não
export class NotificationService {

  private notifier = new Subject<Notification>();


  constructor(

  ) { }

  notify(notificationConfig: NotificationConfig): void {
    this.notifier.next(new Notification(notificationConfig));
  }

  notifySuccess(notificationConfig: NotificationConfig): void {
    const successNotificationConfig = { ...notificationConfig, type: NotificationType.SUCCESS };
    this.notify(successNotificationConfig);
  }

  notifyError(notificationConfig: NotificationConfig): void {
    const errorNotificationConfig = { ...notificationConfig, type: NotificationType.ERROR };
    this.notify(errorNotificationConfig);
  }

  notifyWarning(notificationConfig: NotificationConfig): void {
    const warningNotificationConfig = { ...notificationConfig, type: NotificationType.WARNING };
    this.notify(warningNotificationConfig);
  }

  notifyInfo(notificationConfig: NotificationConfig): void {
    const infoNotificationConfig = { ...notificationConfig, type: NotificationType.INFO };
    this.notify(infoNotificationConfig);
  }

  listen(): Observable<Notification> {
    return this.notifier.asObservable();
  }

}
