import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { JsonSerializeService } from "../services/json-serialize.service";
import { RespostaSegurancaPsicologiaTO } from "../tos/resposta-seguranca-psicologia.to";
import { RequestUploadSegPsicologicaTO } from "../tos/request-upload-seguranca-psicologica";

@Injectable({
  providedIn: "root",
})
export class SegurancaPsicologicaService {
  constructor(
    private httpClient: HttpClient,
    private serializer: JsonSerializeService
  ) {}

  salvarSegurancaPsicologica(
    respostas: RespostaSegurancaPsicologiaTO
  ): Observable<any> {
    const request = new RequestUploadSegPsicologicaTO();
    request.respostaSegurancaPsicologica = respostas;
    const payload = this.serializer.serialize(
      request,
      RequestUploadSegPsicologicaTO
    );
    return this.httpClient.post<any>(`v2/seg-psicologica/upload`, payload);
  }
}
