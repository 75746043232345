import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { BooleanEnum } from "src/app/shared/enums/boolean.enum";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { LogService } from "src/app/shared/services/log.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { ConfiguracaoTesteTO } from "src/app/shared/tos/configuracao-teste.to";
import { EmociogramaTO } from "src/app/shared/tos/emociograma.to";
import { Emociograma } from "./emociograma.int";

@Component({
  selector: "app-emociograma-teste",
  template: ` <app-emociograma-cards
    [emociogramas]="emociogramas"
    (salvar)="salvar($event)"
  >
  </app-emociograma-cards>`,
})
export class EmociogramaTesteComponent implements OnInit, Emociograma {
  emociogramas: EmociogramaTO[] = [];

  constructor(
    private sessionService: SessionStorageService,
    private routeService: RouterService,
    private logService: LogService
  ) {}

  ngOnInit(): void {
    this.logService.append(`Acessou Emociograma`);
    const configuracao = <ConfiguracaoTesteTO>(
      this.sessionService.getItem(SessionStorageKeys.CONFIGURACAO)
    );
    this.emociogramas = configuracao.emociogramas;
    const exibirEmociograma =
      configuracao.flExibeEmociograma == BooleanEnum.SIM &&
      this.emociogramas?.length;
    if (!exibirEmociograma) {
      this.logService.append(
        `Nao possui Emociograma, encaminhando para Abordagens de Notificacoes`
      );
      this.routeService.toAbordagensNotificacoes();
    }
  }

  salvar(idEmociograma: number) {
    this.sessionService.setItem(
      SessionStorageKeys.ID_EMOCIOGRAMA,
      idEmociograma
    );
    this.logService.append(
      `Finalizou Emociograma, encaminhando para Abordagens de Notificacoes`
    );
    this.routeService.toAbordagensNotificacoes();
  }
}
