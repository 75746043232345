<app-header
  [titulo]="'perguntasSamPerelli.titulo' | translate"
  [subtitulo]="'perguntasSamPerelli.subtitulo' | translate"
></app-header>

<div class="conteudoDetalhe">
  <app-questionario-geral
    [questionarios]="questionarios"
    [exibirBreadcrumb]="false"
    (onSuccess)="onSubmit($event)"
  />
</div>
