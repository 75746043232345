import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StringUtils } from '../../utils/StringUtils';

@Component({
  selector: 'app-question-scale',
  templateUrl: './question-scale.component.html',
  styleUrls: ['./question-scale.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: QuestionScaleComponent
    }
  ]
})
export class QuestionScaleComponent implements OnInit, ControlValueAccessor {
  @Input() id = StringUtils.randomString();
  @Input() label: string;

  escala: number;
  disabled: boolean;

  onChange = (escala) => {};
  onTouched = () => {};

  constructor() { }

  ngOnInit(): void {
  }
  
  escalaChanged() {
    this.onChange(this.escala);
    this.onTouched();
  }

  writeValue(escala: number) {
    this.escala = escala;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
