export enum ImagemFinalEnum {
  ATENCAO = 'ATENCAO',
  LIBERADO = 'LIBERADO',
  OUTROS = 'OUTROS',
  SUPERVISOR = 'SUPERVISOR'
}

export function getNmImagem(imagemFinalEnum: ImagemFinalEnum): string {
  switch (imagemFinalEnum) {
    case ImagemFinalEnum.ATENCAO:
      return 'atencao.png';
    case ImagemFinalEnum.LIBERADO:
      return 'liberado.png';
    case ImagemFinalEnum.SUPERVISOR:
      return 'supervisor.png';
    case ImagemFinalEnum.OUTROS:
      return 'supervisor.png';
    default:
      return 'supervisor.png';
  }
}



