import { Component, OnInit } from "@angular/core";
import { ResponderQuestionarioSegPsicologicaEnum } from "src/app/shared/enums/responder-questionario-seg-psicologica.enum";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { LogService } from "src/app/shared/services/log.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { PerguntaSegurancaPsicologicaTO } from "src/app/shared/tos/pergunta-seguranca-psicologia.to";
import { ResponseValidarTesteInicial } from "src/app/shared/tos/response-validar-teste-inicial.to";
import { ContratoSegurancaPsicologicaComponent } from "./components/seguranca-psicologica-component.int";
import { RespostaSegurancaPsicologiaTO } from "src/app/shared/tos/resposta-seguranca-psicologia.to";
import { SegurancaPsicologicaService } from "src/app/shared/api-services/seguranca-psicologica.service";
import { ModulosEnum } from "src/app/shared/enums/Modulos.enum";
@Component({
  selector: "app-seguranca-psicologica-teste",
  template: `<app-seguranca-psicologica
    *ngIf="isSegurancaPsicologicaDisponivel"
    [perguntas]="perguntas"
    [flExibeSegurancaPsicologica]="flExibeSegurancaPsicologica"
    [loading]="loading"
    (onSubmit)="salvar($event)"
  />`,
})
export class SegurancaPsicologicaInicioComponent
  implements OnInit, ContratoSegurancaPsicologicaComponent
{
  perguntas: PerguntaSegurancaPsicologicaTO[];
  flExibeSegurancaPsicologica: ResponderQuestionarioSegPsicologicaEnum;
  isSegurancaPsicologicaDisponivel: boolean;
  loading: boolean;

  constructor(
    private sessionStorageService: SessionStorageService,
    private routerService: RouterService,
    private logService: LogService,
    private segurancaPsicologicaService: SegurancaPsicologicaService
  ) {}

  ngOnInit(): void {
    const configuracao: ResponseValidarTesteInicial =
      this.sessionStorageService.getItem(SessionStorageKeys.CONFIGURACAO);
    this.perguntas = configuracao.perguntasSegurancaPsicologica;
    this.flExibeSegurancaPsicologica = configuracao.flExibeSegurancaPsicologica;
    this.isSegurancaPsicologicaDisponivel =
      this.handleSegurancaPsicologicaDisponivel(
        this.perguntas,
        this.flExibeSegurancaPsicologica
      );

    if (!this.isSegurancaPsicologicaDisponivel) {
      this.logService.append(
        `Nao possui seguranca psicologica, encaminhando para Inicio`
      );
      this.routerService.toInicio();
    }
  }

  handleSegurancaPsicologicaDisponivel(
    perguntas: PerguntaSegurancaPsicologicaTO[],
    flExibeSegurancaPsicologica: ResponderQuestionarioSegPsicologicaEnum
  ): boolean {
    return (
      flExibeSegurancaPsicologica !=
        ResponderQuestionarioSegPsicologicaEnum.NAO && perguntas?.length > 0
    );
  }

  salvar(request: RespostaSegurancaPsicologiaTO) {
    this.loading = true;
    this.logService.append("Upload Segurança Psicologica");
    this.segurancaPsicologicaService
      .salvarSegurancaPsicologica(request)
      .subscribe(
        (res) => {
          this.loading = false;
          this.logService.append("Finalizou Upload Segurança Psicologica");
          this.logService.sendLoteLogs();
          this.routerService.toFinalizar(ModulosEnum.SEGURANCA_PSICOLOGICA);
        },
        (err) => {
          this.loading = false;
          throw err;
        }
      );
  }
}
