<app-header
  titulo="perguntasInicial.textoPerguntasInicial"
  [subtitulo]="'perguntasInicial.textoOrientacao' | translate"
/>

<div class="conteudoDetalhe" style="margin-top: 40px">
  <ul class="p-grid">
    <ng-container *ngFor="let pergunta of perguntas; let i = index">
      <li class="p-col-12 p-md-6">
        <app-select-card [(ngModel)]="respostas[i].flMarcada">
          <app-pergunta-inical-content [pergunta]="pergunta" />
        </app-select-card>
      </li>
    </ng-container>
  </ul>

  <div class="p-grid btn-concluir-container">
    <div class="p-col-12 p-md-6 text-right" *ngIf="exibirQuestionarioVacinacao">
      <button
        pbutton
        class="botao-branco"
        (click)="abrirPesquisaVacinacao()"
        [disabled]="loading || esperandoNovaTentativa"
        [attr.aria-controls]="
          isPesquisaVacinacaoVisible ? 'pesquisa-vacionacao-dialog' : null
        "
        [attr.aria-expanded]="isPesquisaVacinacaoVisible ? true : false"
      >
        <span translate>perguntas.pesquisaVacinacao</span>
      </button>
    </div>
    <app-pesquisa-vacinacao
      *ngIf="exibirQuestionarioVacinacao"
      [perguntasVacinacao]="configuracao?.perguntasVacinacao"
      (onSuccess)="setRespostasVacinacao($event)"
    />
    <div
      class="p-col-12 concluir-content"
      style="margin-top: 45px"
      [class.text-center]="!exibirQuestionarioVacinacao"
      [class.p-md-6]="exibirQuestionarioVacinacao"
    >
      <button
        [label]="'botaoConcluir' | translate"
        pButton
        class="botao"
        (click)="handleConcluir(true)"
        [disabled]="loading || esperandoNovaTentativa"
      >
        <div class="loader" *ngIf="loading"></div>
      </button>
      <small style="display: block" *ngIf="esperandoNovaTentativa">
        <span translate> tentativasReq.erroNovaTentativa </span>
        <app-timer-txt [tempo]="tempoEsperaNovaTentaviva"></app-timer-txt>
        <span translate> tentativasReq.segundos </span>.
      </small>
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="isShowMesagem"
  [modal]="true"
  [closable]="false"
  [styleClass]="cssMsg"
>
  <ng-template pTemplate="header">
    <p>{{ TituloRetornoTeste }}</p>
  </ng-template>

  <p style="font-size: 20px !important" [innerHtml]="addDica"></p>

  <p-footer>
    <button
      *ngIf="isShowDownload"
      pbutton
      class="botao"
      (click)="Download()"
      type="button"
      style="width: 250px !important; margin-top: 5px !important"
      icon="pi pi-download"
    >
      <span translate>notificacao.download</span>
    </button>
    <button
      pbutton
      class="botao-branco"
      (click)="Finalizar()"
      type="button"
      style="width: 250px !important; margin-top: 5px !important"
      icon="pi pi-check"
    >
      <span translate>notificacao.ok</span>
    </button>
  </p-footer>
</p-dialog>

<p-dialog
  [(visible)]="mostrarPerguntaSecundario"
  [modal]="true"
  [closable]="false"
>
  <ng-template pTemplate="header">
    <span translate> perguntas.textoQuestionarioComplementar</span>
  </ng-template>
  <ng-container *ngFor="let pd of perguntaDiscursiva; let i = index">
    <div class="p-grid">
      <div class="p-col-1"></div>
      <div class="p-col-11">
        <label class="checkboxPergunta"
          >{{ i + 1 }}. {{ pd.dsPerguntaDiscursiva }}</label
        >
        <div style="display: flex; width: 100%">
          <input
            type="text"
            style="width: 100%"
            required
            [(ngModel)]="perguntaDiscursiva[i].dsValorPadrao"
            pInputText
          />
        </div>
      </div>
    </div>
  </ng-container>

  <p-footer>
    <button
      pbutton
      class="botao"
      (click)="FinalizarQuestionarioComplementar()"
      type="button"
      style="min-width: 250px !important; margin-top: 0 !important"
      icon="pi pi-check"
    >
      <span translate>botaoFinalizar</span>
    </button>
  </p-footer>
</p-dialog>
