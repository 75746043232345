import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { LoginTO } from '../shared/tos/login.to';
import { TipoDispositivo } from '../shared/enums/tipo-dispositivo.enum';
import { ConfiguracaoTesteApiService } from '../shared/api-services/configuracao-teste.api-service';
import { SessionStorageService } from '../shared/services/session-storage.service';
import { RouterService } from '../shared/services/router-service.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfiguracaoTesteTO } from '../shared/tos/configuracao-teste.to';

@Component({
  selector: 'app-auth',
  templateUrl: 'auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  loginTO: LoginTO = new LoginTO();
  loading: boolean;
  configuracaoTesteTO: ConfiguracaoTesteTO;

  constructor(
    private configuracaoTesteApiService: ConfiguracaoTesteApiService,
    private sessionStorageService: SessionStorageService,
    private routerService: RouterService,
    private translateService: TranslateService
  ) { }

  elem;

  ngOnInit() {
    this.elem = document.documentElement;
  }


  autenticarUsuario(form: NgForm) {
    //this.openFullscreen();

    if (form.valid) {
      this.startLoad();
    }
  }

  openFullscreen() {
    if (!document.fullscreenEnabled) {
      return;
    }
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  startLoad() {
    this.loading = true;
  }

  stopLoad() {
    this.loading = false;
  }
}
