<app-header
  [titulo]="'perguntasKarolinska.titulo' | translate"
  [subtitulo]="'perguntasKarolinska.subtitulo' | translate"
/>

<div class="conteudoDetalhe">
  <app-questionario-geral
    [questionarios]="questionarios"
    [exibirBreadcrumb]="false"
    (onSuccess)="onSubmit($event)"
    [loading]="loading"
  />
</div>
