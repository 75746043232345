<app-header></app-header>

<div class="processar" *ngIf="loading">
  <p class="processar__texto">
    <span class="processar__aguarde" translate> processar.aguardeTexto </span> <br />
    <span translate> processar.processarTexto </span>
  </p>
  <div class="processar__loading">
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  </div>
</div>

<div class="processar" *ngIf="esperandoNovaTentativa">
  <p class="processar__texto">
    <span class="processar__aguarde--erro" translate> processar.problemaTexto </span> <br />
    <span translate> tentativasReq.erroNovaTentativa </span>
    <app-timer-txt [tempo]="tempoEsperaNovaTentaviva"></app-timer-txt>
    <span translate> tentativasReq.segundos </span>.
  </p>
</div>

<div class="processar" *ngIf="!loading && !esperandoNovaTentativa">
  <p class="processar__texto">
    <span class="processar__aguarde--erro" translate> processar.problemaTexto </span> <br />
    <span translate> processar.erroTexto </span>
    <button type="button" class="botao" (click)="handleConcluir()"> {{ 'botaoTentar' | translate }} </button>
  </p>
</div>
