import { SubBlocoTO } from './sub-bloco.to';
import { AlvosEnum } from '../enums/Alvos.enum';
import { JsonObject, JsonProperty, PropertyConvertingMode } from 'json2typescript';

@JsonObject("ReferenciaTO")
export class ReferenciaTO {

  @JsonProperty("aq1", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  id: number = null;

  @JsonProperty("aq2", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  qtTesteRegressao: number = null;

  @JsonProperty("aq3", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  qtTesteIniciaisDescarte: number = null;

  @JsonProperty("aq4", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsReferencia: string = null;

  @JsonProperty("aq5", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsAlvos: string = null;

  @JsonProperty("aq6", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  qtAlvosErrados: number = null;

  @JsonProperty("aq7", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  qtAlvosCertos: number = null;

  @JsonProperty("aq8", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsAlvoConsufionalCentro: string = null;

  @JsonProperty("aq9", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsAlvoConsufionalDireita: string = null;

  @JsonProperty("aq10", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsAlvoConsufionalEsquerda: string = null;

  @JsonProperty("aq11", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsTextoApresentacao: string = null;

  @JsonProperty("aq12", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsTextoInstrucao: string = null;

  @JsonProperty("aq13", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsTextoInstrucaoQuestionario: string = null;

  @JsonProperty("aq14", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsAlvosWeb: AlvosEnum = null;

  @JsonProperty("aq15", [SubBlocoTO], PropertyConvertingMode.IGNORE_NULLABLE)
  subBlocos: SubBlocoTO[] = null;
}
