import {
  JsonObject,
  JsonProperty,
  PropertyConvertingMode,
} from "json2typescript";

@JsonObject("RespostaBuscaAtivaV2TO")
export class RespostaBuscaAtivaV2TO {
  @JsonProperty("ao1", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsOpcoesBuscaAtiva: string = null;

  @JsonProperty("ao2", String, PropertyConvertingMode.IGNORE_NULLABLE)
  nrTelefoneBuscaAtiva: string = null;

  @JsonProperty("ao3", String, PropertyConvertingMode.IGNORE_NULLABLE)
  hrContatoBuscaAtiva: string = null;

  formatToApi() {
    const data = Object.assign(new RespostaBuscaAtivaV2TO(), this);
    data.dsOpcoesBuscaAtiva = (data.dsOpcoesBuscaAtiva as any).join(",");
    return data;
  }
}
