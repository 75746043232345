import {
  JsonObject,
  JsonProperty,
  PropertyConvertingMode,
} from "json2typescript";
import { PerguntaOpcaoSegurancaPsicologicaTO } from "./pergunta-opcao-seguranca-psicologica.to";

@JsonObject("PerguntaSegurancaPsicologicaTO")
export class PerguntaSegurancaPsicologicaTO {
  @JsonProperty("as1", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  id: number = null;

  @JsonProperty("as2", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsPergunta: string = null;

  @JsonProperty("as3", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  nrOrdem: number = null;

  @JsonProperty(
    "as4",
    [PerguntaOpcaoSegurancaPsicologicaTO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  opcoes: PerguntaOpcaoSegurancaPsicologicaTO[] = null;

  @JsonProperty("as5", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  nrGrupo: number = null;
}
