import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { ResponseValidarTesteInicial } from "src/app/shared/tos/response-validar-teste-inicial.to";

@Component({
  selector: "app-inicio-saudacao",
  templateUrl: "./inicio-saudacao.component.html",
  styleUrls: ["./inicio-saudacao.component.scss"],
})
export class InicioSaudacaoComponent implements OnInit, OnChanges {
  @Input() configuracao: ResponseValidarTesteInicial;
  textoSaudacao: string;
  nomeOperador: string;

  constructor(private keyclockService: KeycloakService) {}

  ngOnInit(): void {
    this.nomeOperador =
      this.keyclockService.getKeycloakInstance().tokenParsed["name"];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.configuracao.currentValue) {
      this.textoSaudacao = this.getTextoSaudadacao(
        changes.configuracao.currentValue
      );
    }
  }

  getTextoSaudadacao(configuracao: ResponseValidarTesteInicial) {
    if (configuracao?.flHabilitadoApenasSegPsicologica == "NAO") {
      return "inicio.saudacao";
    }
    const today = new Date();
    const curHr = today.getHours();
    if (curHr < 12) {
      return "inicio.bomDia";
    } else if (curHr < 18) {
      return "inicio.boaTarde";
    } else {
      return "inicio.boaNoite";
    }
  }
}
