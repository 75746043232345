import { JsonObject, JsonProperty, PropertyConvertingMode } from "json2typescript";
import { BooleanEnum } from "../enums/boolean.enum";
import { EmociogramaTO } from "./emociograma.to";

@JsonObject("ResponseValidarEmociogramaTO")
export class ResponseValidarEmociogramaTO {
  @JsonProperty("aa", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idUsuario: number = null;

  @JsonProperty("ab", String, PropertyConvertingMode.IGNORE_NULLABLE)
  nmUsuario: string = null;

  @JsonProperty("ac", [EmociogramaTO], PropertyConvertingMode.IGNORE_NULLABLE)
  emociogramas: EmociogramaTO[] = null;
}
