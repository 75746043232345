import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { BooleanEnum } from "src/app/shared/enums/boolean.enum";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { LogService } from "src/app/shared/services/log.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { ConfiguracaoTesteTO } from "src/app/shared/tos/configuracao-teste.to";
import { RequestPerguntaEpworh } from "src/app/shared/tos/request-pergunta-epworth.to";
import { ResponsePerguntaEpworthTO } from "src/app/shared/tos/response-pergunta-epworth.to";
import { ResultadoTesteTO } from "src/app/shared/tos/resultado-teste.to";

@Component({
  selector: "app-perguntas-epworth",
  templateUrl: "./perguntas-epworth.component.html",
  styleUrls: ["./perguntas-epworth.component.scss"],
})
export class PerguntasEpworthComponent implements OnInit {
  perguntas: ResponsePerguntaEpworthTO[];
  respostas: RequestPerguntaEpworh[] = [];

  constructor(
    private sessionStorage: SessionStorageService,
    private routerService: RouterService,
    private logService: LogService
  ) {}

  ngOnInit(): void {
    const resultado = this.sessionStorage.getItem<ResultadoTesteTO>(
      SessionStorageKeys.RESULTADO
    );
    this.logService.append(`Acessou Perguntas Epworth`);
    if (!resultado.perguntasEpworth?.length) {
      this.logService.append(
        `Nao possui Perguntas Epworth, encaminhando para Avulso Cliente`
      );
      this.routerService.toPerguntasAvulsoCliente();
      return;
    }
    this.perguntas = resultado.perguntasEpworth;
    this.respostas = this.perguntas.map((p) => {
      const r = new RequestPerguntaEpworh();
      r.id = p.id;
      return r;
    });
  }

  finalizar(form: NgForm) {
    if (form.valid) {
      this.logService.append(
        `Finalizou Perguntas Epworth, encaminhando para Avulso Cliente`
      );
      this.sessionStorage.setItem(
        SessionStorageKeys.RESPOSTA_EPWORTH,
        this.respostas
      );
      this.routerService.toPerguntasAvulsoCliente();
    }
  }
}
