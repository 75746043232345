import { JsonObject, JsonProperty, PropertyConvertingMode } from "json2typescript";
import { TipoExibicaoPerguntaEnum } from "../enums/tipo-exibicao-pergunta.enum";
import { ResponsePerguntaOpcaoMatutinidadeTO } from "./response-pergunta-opcao-matutiniade.to";

@JsonObject("ResponsePerguntaMatutinidadeTO")
export class ResponsePerguntaMatutinidadeTO {
  @JsonProperty("ba1", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  id: number = null;

  @JsonProperty("ba2", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsPergunta: string = null;

  @JsonProperty("ba3", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  nrOrdem: number = null;

  @JsonProperty("ba4", [ResponsePerguntaOpcaoMatutinidadeTO], PropertyConvertingMode.IGNORE_NULLABLE)
  opcoes: ResponsePerguntaOpcaoMatutinidadeTO[] = null;

  @JsonProperty("ba5", String, PropertyConvertingMode.IGNORE_NULLABLE)
  cdTipoExibicao: TipoExibicaoPerguntaEnum = null;
}
