<p-dialog
  [(visible)]="show"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{
    width: '90%',
    'max-width': '640px',
    'max-height': '98vh',
    overflow: 'auto'
  }"
  [closable]="!hasPerguntaTerceiraDose()"
  ariaLabelledBy="pesquisa-vacionacao-titulo"
>
  <ng-template pTemplate="header">
    <div class="dialog-title p-dialog-titlebar">
      <span translate id="pesquisa-vacionacao-titulo">
        perguntas.pesquisaVacinacao
      </span>
    </div>
  </ng-template>

  <div class="container">
    <form
      id="pesquisaVacinacaoForm"
      #form="ngForm"
      class="p-grid"
      (submit)="handleSubmit()"
    >
      <div
        class="p-col-12"
        *ngFor="
          let pergunta of perguntasVacinacao
            | filtrarPerguntas : primeiraDoseTomada();
          let i = index
        "
      >
        <label
          class="label-input"
          for="resposta{{ pergunta.id }}"
          id="a11y-resposta{{ pergunta.id }}"
        >
          {{ i + 1 }}. {{ pergunta.dsPerguntaDiscursiva }}
        </label>
        <p-dropdown
          *ngIf="isPerguntaDoses(pergunta.nrOrdem)"
          [options]="doses"
          [(ngModel)]="respostas[i].dsResposta"
          appendTo="body"
          name="resposta{{ pergunta.id }}"
          required="true"
          ariaLabelledBy="a11y-resposta{{ pergunta.id }}"
        >
        </p-dropdown>

        <p-calendar
          *ngIf="isPerguntaCalendario(pergunta.nrOrdem)"
          [(ngModel)]="respostas[i].dsResposta"
          dateFormat="dd/mm/yy"
          [maxDate]="today"
          panelStyleClass="small"
          appendTo="body"
          [placeholder]="'selecione' | translate"
          name="resposta{{ pergunta.id }}"
          required="true"
          inputId="resposta{{ pergunta.id }}"
        >
        </p-calendar>

        <p-dropdown
          *ngIf="isPerguntaProximaDose(pergunta.nrOrdem)"
          [options]="proximasDoses"
          [(ngModel)]="respostas[i].dsResposta"
          appendTo="body"
          name="resposta{{ pergunta.id }}"
          required="true"
        >
        </p-dropdown>

        <ng-container *ngIf="isPerguntaTerceiraDose(pergunta.nrOrdem)">
          <div class="p-formgroup-inline p-mt-2">
            <div class="p-field-checkbox">
              <p-radioButton
                name="resposta{{ pergunta.id }}"
                [(ngModel)]="respostas[i].dsResposta"
                inputId="sim{{ pergunta.id }}"
                value="SIM"
                required="true"
              >
              </p-radioButton>
              <label for="sim{{ pergunta.id }}" translate> sim </label>
            </div>
            <div class="p-field-checkbox">
              <p-radioButton
                name="resposta{{ pergunta.id }}"
                [(ngModel)]="respostas[i].dsResposta"
                inputId="nao{{ pergunta.id }}"
                value="'NAO"
                required="true"
              >
              </p-radioButton>
              <label for="nao{{ pergunta.id }}" translate> nao </label>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="p-col-12">
        <button
          type="submit"
          pButton
          [disabled]="!form.valid"
          [label]="'botaoConcluir' | translate"
          class="botao"
        ></button>
      </div>
    </form>
  </div>
</p-dialog>
