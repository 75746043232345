import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RespostaBuscaAtivaV2TO } from "src/app/shared/tos/request-resposta-busca-ativa.to";

@Component({
  selector: "app-form-apoio-profissional",
  templateUrl: "./form-apoio-profissional.component.html",
  styleUrls: ["./form-apoio-profissional.component.scss"],
})
export class FormApoioProfissionalComponent {
  @Output() submitData = new EventEmitter<RespostaBuscaAtivaV2TO>();
  @Input() show: boolean;

  ngOnInit() {}

  submit() {
    this.submitData.emit();
    this.sair();
  }

  sair() {
    this.show = false;
  }
}
