<div
  class="alert alert--bottom"
  *ngIf="show"
  role="dialog"
  tabindex="0"
  id="alert-bar-container"
  [attr.aria-modal]="show"
>
  <p class="alert-text">
    {{ text }}
  </p>
  <button class="alert-button" type="button" (click)="hideAlert()" translate>
    notificacao.ok
  </button>
</div>
