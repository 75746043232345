import { Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { dispatchError } from 'src/app/core/dispatchError';

@Injectable({
  providedIn: 'root'
})
export class JsonSerializeService {

  constructor() { }

  serialize(data: any, classReference?: new () => {}) {
    try {
      const converter = new JsonConvert();
      return converter.serialize(data, classReference);
    } catch(err) {
      dispatchError("Json Serializar", err);
      return data;
    }
  }
  
  deserialize(json: any, classReference?: new () => {}) {
    try {
      const converter = new JsonConvert();
      return converter.deserialize(json, classReference);
    } catch(err) {
      dispatchError("Json Deserializar", err);
      return json;
    }
  }
}
