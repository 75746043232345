import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { catchError, tap } from "rxjs";
import { dispatchError } from "src/app/core/dispatchError";
import { TesteService } from "src/app/shared/api-services/teste.service";
import { BooleanEnum } from "src/app/shared/enums/boolean.enum";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { AuthService } from "src/app/shared/services/auth.service";
import { InactiveTimerService } from "src/app/shared/services/inactive-timer.service";
import { LogService } from "src/app/shared/services/log.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { RetryService } from "src/app/shared/services/retry.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { AlvoTO } from "src/app/shared/tos/alvo.to";
import { ConfiguracaoTesteTO } from "src/app/shared/tos/configuracao-teste.to";
import { ProcessaTesteTO } from "src/app/shared/tos/processa-teste.to";
import { RequestPerguntaEpworh } from "src/app/shared/tos/request-pergunta-epworth.to";
import { RespostaBuscaAtivaV2TO } from "src/app/shared/tos/request-resposta-busca-ativa.to";
import { RespostaPerguntaCompInseguroV2TO } from "src/app/shared/tos/request-resposta-pergunta-comp-insguro.to";
import { RespostaPerguntaMatutinidadeV2TO } from "src/app/shared/tos/request-resposta-pergunta-matutinidade.to";
import { RespostaPerguntaTO } from "src/app/shared/tos/resposta-pergunta.to";
import { RespostaSegurancaPsicologiaTO } from "src/app/shared/tos/resposta-seguranca-psicologia.to";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-processar-teste",
  templateUrl: "./processar-teste.component.html",
  styleUrls: ["./processar-teste.component.scss"],
})
export class ProcessarTesteComponent implements OnInit, OnDestroy {
  intervalo: any;
  loading: boolean = false;

  constructor(
    private retryService: RetryService,
    private sessionStorageService: SessionStorageService,
    private logService: LogService,
    private testeService: TesteService,
    private inativoService: InactiveTimerService,
    private routerService: RouterService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.logService.append(`Acessou processar teste`);
    this.inativoService.start(
      this.handleTempoExcedido.bind(this),
      60000 * 5,
      false
    );
    this.handleConcluir();
  }

  handleConcluir() {
    this.setLoading(true);
    try {
      const exibirPopup = false;
      this.retryService.retryInit(this.concluirTeste.bind(this), exibirPopup);
    } catch (err) {
      console.log(err);
    }
  }

  private concluirTeste() {
    this.setLoading(true);
    const request = this.buildProcessaTesteTo();
    this.logService.append(`Realizando upload do teste`);

    return this.testeService.upload(request).pipe(
      tap((retorno) => {
        if (request.flEnviaRetorno) {
          this.sessionStorageService.setItem(
            SessionStorageKeys.RESULTADO,
            retorno
          );
          this.logService.append(
            `Finalizou processamento, encaminhando para Perguntas comportamento inseguro`
          );
          this.routerService.toPerguntaCompInseguro();
        } else {
          this.logService.append("EnviaRetorno: false, deslogando usuario");
          this.authService.handleLogout();
        }
        this.setLoading(false);
      }),
      catchError((err) => {
        this.logService.append(`Erro ao processar teste`, err, "ERROR");
        dispatchError("ERRO_UPLOAD", err);
        this.setLoading(false);
        throw err;
      })
    );
  }

  private buildProcessaTesteTo(): ProcessaTesteTO {
    const respostaPerguntas =
      <RespostaPerguntaTO[]>(
        this.sessionStorageService.getItem(
          SessionStorageKeys.RESPOSTA_BEM_ESTAR
        )
      ) || [];
    const configuracao = <ConfiguracaoTesteTO>(
      this.sessionStorageService.getItem(SessionStorageKeys.CONFIGURACAO)
    );
    const acoes = <AlvoTO[]>(
      this.sessionStorageService.getItem(SessionStorageKeys.ALVOS)
    );
    const flAnaliseGestaoOperador = <BooleanEnum>(
      this.sessionStorageService.getItem(
        SessionStorageKeys.ANALISE_GESTAO_OPERADOR
      )
    );
    const flEnviaRetornoParam = <boolean>(
      this.sessionStorageService.getItem(SessionStorageKeys.ENVIA_RETORNO_PARAM)
    );
    const respostasPsicologia = <RespostaSegurancaPsicologiaTO>(
      this.sessionStorageService.getItem(
        SessionStorageKeys.RESPOSTAS_PSICOLOGIA
      )
    );
    const idEmociograma = <number>(
      this.sessionStorageService.getItem(SessionStorageKeys.ID_EMOCIOGRAMA)
    );
    const respostasMatutinidade = <RespostaPerguntaMatutinidadeV2TO[]>(
      this.sessionStorageService.getItem(
        SessionStorageKeys.RESPOSTA_MATUTINIDADE
      )
    );

    return {
      idUsuario: configuracao.idUsuario,
      idTeste: configuracao.idTesteExecutado,
      alvos: acoes,
      respostaPerguntas: respostaPerguntas,
      flEnviaRetorno: flEnviaRetornoParam,
      flAnaliseGestaoOperador: flAnaliseGestaoOperador,
      dtExecucao: configuracao.dtExecucao,
      idEmociograma: idEmociograma,
      respostaSegurancaPsicologica: respostasPsicologia,
      flEmbedded: environment.flEmbedded,
      respostasPerguntasMatutinidade: respostasMatutinidade,
    } as ProcessaTesteTO;
  }

  setLoading(isLoading: boolean) {
    this.loading = isLoading;
  }

  async handleTempoExcedido() {
    this.logService.append(`Tempo processar excedido, deslogar`);
    this.notificationService.notify({
      message: this.translateService.instant("tentativasReq.excedido"),
      acceptVisible: false,
      handler: () => this.authService.handleLogout(),
    });
  }

  get esperandoNovaTentativa() {
    return this.retryService.esperandoNovaTentativa;
  }

  get tempoEsperaNovaTentaviva() {
    return this.retryService.tempoEsperaNovaTentaviva;
  }

  ngOnDestroy() {
    this.inativoService.stop();
    clearInterval(this.intervalo);
  }
}
