import { PerguntaInicialTO } from "./perguntaInicial.to";
import { PerguntaDiscursivaTO } from "./pergunta-discursiva.to";
import { BooleanEnum } from "../enums/boolean.enum";
import {
  JsonObject,
  JsonProperty,
  PropertyConvertingMode,
} from "json2typescript";
import { TipoExibicaoEADEnum } from "../enums/tipo-exibicao-ead.enum";
import { BaseAutenticacaoEnum } from "../enums/BaseAutenticacao.enum";
import { ResponsePerguntaMatutinidadeTO } from "./response-pergunta-matutinidade.to";
import { ResponderQuestionarioSegPsicologicaEnum } from "../enums/responder-questionario-seg-psicologica.enum";
import { PerguntaSegurancaPsicologicaTO } from "./pergunta-seguranca-psicologia.to";

@JsonObject("ResponseValidarTesteInicial")
export class ResponseValidarTesteInicial {
  @JsonProperty("aa", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idUsuario: number = null;

  @JsonProperty("ab", String, PropertyConvertingMode.IGNORE_NULLABLE)
  nmUsuario: string = null;

  @JsonProperty(
    "ac",
    [PerguntaInicialTO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  perguntasIniciais: PerguntaInicialTO[] = null;

  @JsonProperty("ad", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flQuestionarioInicial: any = null;

  @JsonProperty("ae", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flTesteProntidao: any = null;

  @JsonProperty("ag", String, PropertyConvertingMode.IGNORE_NULLABLE)
  msgNotificacao: string = null;

  @JsonProperty("ah", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flExibeIdTesteImpedimentoResultado: any = null;

  @JsonProperty("ai", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flQuestionarioDiscursivo: any = null;

  @JsonProperty(
    "aj",
    [PerguntaDiscursivaTO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  perguntasDiscursivas: PerguntaDiscursivaTO[] = null;

  @JsonProperty("ak", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flQuestionarioVacinacao: BooleanEnum = null;

  @JsonProperty("ar", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flHabilitadoEmociograma: BooleanEnum = null;

  @JsonProperty(
    "al",
    [PerguntaDiscursivaTO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  perguntasVacinacao: PerguntaDiscursivaTO[] = null;

  @JsonProperty("af", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flSaudeMental: BooleanEnum = null;

  @JsonProperty("ap", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flHabilitadoAvaliacao: BooleanEnum = null;

  @JsonProperty("ao", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flHabilitadoPreTeste: BooleanEnum = null;

  @JsonProperty("aq", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flHabilitadoSaudeMental: BooleanEnum = null;

  @JsonProperty("am", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flExecutarEmociogramaDisponivel: BooleanEnum = null;

  @JsonProperty("an", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flExecutarPreTesteDisponivel: BooleanEnum = null;

  @JsonProperty("at", String, PropertyConvertingMode.IGNORE_NULLABLE)
  cdExibeEAD: TipoExibicaoEADEnum = null;

  @JsonProperty("au", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flExibeSimulador: BooleanEnum = null;

  @JsonProperty("av", String, PropertyConvertingMode.IGNORE_NULLABLE)
  cdBaseAutenticacao: BaseAutenticacaoEnum = null;

  @JsonProperty("aw", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsUrlLogoutBaseAutenticacao: string = null;

  @JsonProperty(
    "ba",
    [ResponsePerguntaMatutinidadeTO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  perguntasMatutinidade: ResponsePerguntaMatutinidadeTO[] = null;

  @JsonProperty("bb", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flPodeExecutarAvaliacaoAgora: BooleanEnum = null;

  @JsonProperty("bc", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsMensagemImpeditivaAvaliacaoAgora: string = null;

  @JsonProperty("bd", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flHabilitadoApenasSegPsicologica: BooleanEnum = null;

  @JsonProperty("be", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flExibeSegurancaPsicologica: ResponderQuestionarioSegPsicologicaEnum = null;

  @JsonProperty(
    "bf",
    [PerguntaSegurancaPsicologicaTO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  perguntasSegurancaPsicologica: PerguntaSegurancaPsicologicaTO[] = null;
}
