<ng-container *ngFor="let pergunta of perguntas; let i= index">
  <div class="p-grid checkboxPergunta" [ngClass]="{'checkboxPerguntaMarcada': respostas[i].flMarcada}">
    <div class="p-col-10 p-md-offset-1">
        <label class="checkboxPergunta" [attr.for]="'pergunta' + i">
          {{i+1}}.{{pergunta.dsPergunta}}
        </label>
    </div>
    <div class="p-col-10 p-md-offset-1 respostas">
      <div class="resposta--sim">
        <p-radioButton name="pergunta{{i}}" value="true" [(ngModel)]="respostas[i].flMarcada" inputId="sim{{i}}"></p-radioButton>
        <label for="sim{{i}}" translate> sim </label>
      </div>
      <div class="resposta--nao">
        <p-radioButton name="pergunta{{i}}" value="false" [(ngModel)]="respostas[i].flMarcada" inputId="nao{{i}}"></p-radioButton>
        <label for="nao{{i}}" translate> nao </label>
      </div>
    </div>
  </div>
</ng-container>
