import { JsonObject, JsonProperty, PropertyConvertingMode } from "json2typescript";
import { BooleanEnum } from "../enums/boolean.enum";

@JsonObject("OpcaoItemPerguntaTo")
class OpcaoItemPerguntaTo {
  @JsonProperty("ar5f1", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idItemOpcao: number = null;

  @JsonProperty("ar5f2", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsPerguntaItemOpcao: string = null;

  @JsonProperty("ar5f3", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  nrOrdem: number = null;
}
@JsonObject("ItemPerguntaTo")
export class ItemPerguntaTo {
  @JsonProperty("ar5a", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idItem: number = null

  @JsonProperty("ar5b", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsPerguntaItem: string = null

  @JsonProperty("ar5c", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  nrOrdem: number = null

  @JsonProperty("ar5d", String, PropertyConvertingMode.IGNORE_NULLABLE)
  cdTipoExibicao: string = null

  @JsonProperty("ar5e", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flRespostaObrigatoria: BooleanEnum = null

  @JsonProperty("ar5f", [OpcaoItemPerguntaTo], PropertyConvertingMode.IGNORE_NULLABLE)
  opcoes: OpcaoItemPerguntaTo[] = null
}
