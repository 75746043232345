<div class="perguntas-list">
  <ng-container *ngFor="let pergunta of getPerguntas(); let i = index">
    <input
      style="display: none"
      type="checkbox"
      name="pergunta1"
      [(ngModel)]="getRespostas()[i].flMarcada"
      [id]="'pergunta' + i"
    />
    <label
      class="pergunta-card"
      [for]="'pergunta' + i"
      role="checkbox"
      tabindex="0"
      [attr.aria-checked]="getRespostas()[i].flMarcada"
    >
      <div class="imagem-wrapper">
        <app-cached-img
          class="pergunta-imagem"
          src="/assets/images/bem-estar-ilustrado/{{ pergunta.nmImagem }}.png"
          [alt]="pergunta.dsPergunta"
          aria-hidden="true"
        />
      </div>
      <p class="pergunta-texto">{{ pergunta.dsPergunta }}</p>
    </label>
  </ng-container>
</div>
