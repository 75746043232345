import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ProcessaTesteTO } from "../tos/processa-teste.to";
import { ResultadoTesteTO } from "../tos/resultado-teste.to";
import { TesteInicialTO } from "../tos/teste-inicial.to";
import { ResultadoTesteInicialTO } from "../tos/resultado-teste-inicial.to";
import { RequestGerarTesteInicialTO } from "../tos/request-gerar-testeinicial.to";
import { Observable, of } from "rxjs";
import { map, timeout } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { RetryService } from "../services/retry.service";
import { environment } from "src/environments/environment";
import { JsonSerializeService } from "../services/json-serialize.service";
import { ClienteEnum } from "../enums/Cliente.enum";
import { RespostaPerguntaCompInseguroV2TO } from "../tos/request-resposta-pergunta-comp-insguro.to";
import { RequestUploadQuestionarioV2TO } from "../tos/request-upload-questionario-v2.to";

@Injectable({
  providedIn: "root",
})
export class TesteService {
  private versaoEndpoint: string;

  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
    private retryService: RetryService,
    private serializer: JsonSerializeService
  ) {
    this.versaoEndpoint = "v2/";
  }

  upload(processaTesteTO: ProcessaTesteTO) {
    const url = this.versaoEndpoint + "teste-executado/upload";
    if (processaTesteTO.alvos) {
      processaTesteTO.alvos.forEach((alvo) => {
        delete alvo["idImagem"];
      });
    }
    const payload = this.serializer.serialize(processaTesteTO, ProcessaTesteTO);
    return this.httpClient.put<ResultadoTesteTO>(url, payload).pipe(
      timeout(40000),
      map((r) => {
        const result: ResultadoTesteTO = this.serializer.deserialize(
          r,
          ResultadoTesteTO
        );
        return result;
      })
    );
  }

  salvarTesteInicial(testeInicialTO: TesteInicialTO) {
    const url = this.versaoEndpoint + "teste-executado/upload-teste-inicial";
    const payload = this.serializer.serialize(testeInicialTO, TesteInicialTO);
    return this.httpClient.put<ResultadoTesteInicialTO>(url, payload).pipe(
      timeout(40000),
      map((r) => {
        return this.serializer.deserialize(r, ResultadoTesteInicialTO);
      })
    );
  }

  sendQuestionarios(request: RequestUploadQuestionarioV2TO) {
    const payload = this.serializer.serialize(
      request,
      RequestUploadQuestionarioV2TO
    );
    return this.httpClient.put<ResultadoTesteInicialTO>(
      `${this.versaoEndpoint}teste-executado/upload-questionario`,
      payload
    );
  }

  downloadTesteInicial(idTesteInicial: number) {
    var testeInicial: RequestGerarTesteInicialTO = {
      idPerguntaInicial: idTesteInicial,
    };
    const url =
      this.versaoEndpoint + "teste-executado/comprovante-teste-inicial";

    return this.httpClient.put<any>(url, testeInicial);
  }

  downloadTesteInicialBlob() {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "*/*");
    const url =
      this.versaoEndpoint + "teste-executado/comprovante-teste-inicial-blob";
    return this.httpClient.get<any>(url, {
      headers: headers,
      responseType: "blob" as "json",
    });
  }

  downloadArquivoTesteInicial(url: string): Observable<Blob> {
    return this.httpClient.get<Blob>(url);
  }

  solicitarApoio(): Observable<any> {
    return this.httpClient.put<any>(
      `${this.versaoEndpoint}teste-executado/solicitar-apoio`,
      {}
    );
  }
}
