import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EmociogramaService } from "src/app/shared/api-services/emociograma.service.";
import { ModulosEnum } from "src/app/shared/enums/Modulos.enum";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { NotificationService } from "src/app/shared/services/notification.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { ConfiguracaoTesteTO } from "src/app/shared/tos/configuracao-teste.to";
import { EmociogramaTO } from "src/app/shared/tos/emociograma.to";
import { Emociograma } from "./emociograma.int";
import { LogService } from "src/app/shared/services/log.service";

@Component({
  selector: "app-emociograma-inicio",
  template: ` <app-emociograma-cards
    [emociogramas]="emociogramas"
    [loadingEmociogramas]="loadingEmociogramas"
    [loadingSubmit]="loadingSubmit"
    (salvar)="salvar($event)"
  >
  </app-emociograma-cards>`,
})
export class EmociogramaInicioComponent implements OnInit, Emociograma {
  emociogramas: EmociogramaTO[] = [];
  loadingEmociogramas: boolean = false;
  loadingSubmit: boolean = false;

  constructor(
    private emociogramaService: EmociogramaService,
    private routerService: RouterService,
    private sessionStorageService: SessionStorageService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private logService: LogService
  ) {}

  ngOnInit(): void {
    this.logService.append(`Acessou Emociograma no Inicio`);
    this.getEmociogramas();
  }

  getEmociogramas() {
    this.loadingEmociogramas = true;
    this.emociogramaService.validarEmociograma().subscribe(
      (r) => {
        this.loadingEmociogramas = false;
        this.emociogramas = r.emociogramas;
      },
      (err) => {
        this.loadingEmociogramas = false;
        this.routerService.toInicio();
        throw err;
      }
    );
  }

  salvar(idEmociograma: number) {
    const configuracao = <ConfiguracaoTesteTO>(
      this.sessionStorageService.getItem(SessionStorageKeys.CONFIGURACAO)
    );
    this.loadingSubmit = true;
    this.emociogramaService
      .salvarEmociograma(configuracao.idUsuario, idEmociograma)
      .subscribe(
        (r) => {
          this.loadingSubmit = false;
          this.notificationService.notifySuccess({
            message: this.translateService.instant("emociograma.sucesso"),
            handler: () =>
              this.routerService.toFinalizar(ModulosEnum.EMOCIOGRAMA),
          });
        },
        (err) => {
          this.loadingSubmit = false;
          throw err;
        }
      );
  }
}
