import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { ClienteEnum } from "src/app/shared/enums/Cliente.enum";
import { EmociogramaTO } from "src/app/shared/tos/emociograma.to";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-emociograma-cards",
  templateUrl: "./emociograma-cards.component.html",
  styleUrls: ["./emociograma-cards.component.scss"],
})
export class EmociogramaCardsComponent implements OnInit, OnChanges {
  @Output() salvar = new EventEmitter<number>();
  @Input() emociogramas: EmociogramaTO[] = [];
  @Input() loadingEmociogramas: boolean;
  @Input() loadingSubmit: boolean;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.emociogramas.currentValue &&
      environment.cliente == ClienteEnum.GERDAU
    ) {
      this.defineBackgroundCards(this.emociogramas);
    }
  }

  defineBackgroundCards(emociogramas: EmociogramaTO[]) {
    const good = "#82D681";
    const neutral = "#F9DF95";
    const bad = "#E87E7E";
    const middle = Math.ceil(emociogramas.length / 2) - 1;
    const isOdd = emociogramas.length % 2;

    if (emociogramas?.[0]) {
      emociogramas[0].backgroundColor = good;
    }
    if (emociogramas?.length > 1 && emociogramas?.[emociogramas.length - 1]) {
      emociogramas[emociogramas.length - 1].backgroundColor = bad;
    }
    for (let i = 1; i < emociogramas.length - 1; i++) {
      if (i < middle) {
        emociogramas[i].backgroundColor = good;
      } else if (i >= middle && i <= middle + (isOdd ? 0 : 1)) {
        emociogramas[i].backgroundColor = neutral;
      } else {
        emociogramas[i].backgroundColor = bad;
      }
    }
  }

  handleSubmit(form: NgForm) {
    this.salvar.emit(form.value.idEmociograma);
  }
}
