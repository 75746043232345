import { JsonObject, JsonProperty, PropertyConvertingMode } from "json2typescript";

@JsonObject("ResponsePerguntaOpcaoMatutinidadeTO")
export class ResponsePerguntaOpcaoMatutinidadeTO {
  @JsonProperty("ba4a", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  id: number = null;

  @JsonProperty("ba4b", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsOpcao: string = null;

  @JsonProperty("ba4c", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  nrOrdem: number = null;
}
