import { Component, OnInit } from "@angular/core";
import {
  PerguntaQuestionario,
  Questionario,
  parsePerguntasAdicionalToQuestionaro,
  parseRespostasToRespostasAdicional,
} from "src/app/shared/components/questionario-geral/PerguntaQuestionario.to";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { LogService } from "src/app/shared/services/log.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { ResultadoTesteTO } from "src/app/shared/tos/resultado-teste.to";

@Component({
  selector: "app-perguntas-sam-perelli",
  templateUrl: "./perguntas-sam-perelli.component.html",
  styleUrls: ["./perguntas-sam-perelli.component.scss"],
})
export class PerguntasSamPerelliComponent implements OnInit {
  questionarios: Questionario[] = [];

  constructor(
    private sessionStorageService: SessionStorageService,
    private routerService: RouterService,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.logService.append("Acessou Perguntas Sam Perelli");
    const resultado = this.sessionStorageService.getItem<ResultadoTesteTO>(
      SessionStorageKeys.RESULTADO
    );
    const perguntas = resultado.perguntasSamPerelli;
    if (!perguntas?.length) {
      this.logService.append(
        "Nao possui perguntas Sam Perelli, encaminhando para Perguntas Karolinska"
      );
      this.routerService.toPerguntasKarolinska();
    } else {
      this.questionarios = parsePerguntasAdicionalToQuestionaro(perguntas);
    }
  }

  onSubmit(event: PerguntaQuestionario[]) {
    const respostas = parseRespostasToRespostasAdicional(event);
    this.sessionStorageService.setItem(
      SessionStorageKeys.RESPOSTAS_SAM_PERELLI,
      respostas
    );
    this.routerService.toPerguntasKarolinska();
  }
}
