<app-card-opacity *ngIf="!habilitado">
  {{ "inicio.mensagemBloqueado" | translate }}
  <a (click)="testeProntidaoClick.emit()" translate> inicio.testeProntidao </a>
</app-card-opacity>

<div [ngClass]="{ blur: !habilitado }">
  <h2 class="titulo" translate>inicio.nivelGeralProntidao</h2>
  <p class="disclaimer" translate>inicio.nivelGeralProntidaoDescricao</p>

  <div class="container">
    <p class="feedback" [innerHTML]="fraseFeedback | translate"></p>

    <div class="cards-container">
      <div class="p-grid">
        <div class="p-col-12 p-md-3" *ngFor="let k of niveisKeys">
          <app-inicio-card>
            <app-cached-img
              class="nivel-img"
              [alt]="getNivel(k).titulo | translate"
              [src]="getNivel(k).icone"
            />
            <p class="nivel-disclaimer" translate>
              {{ getNivel(k).titulo }}
              <br />
              <span class="nivel-disclaimer__opaco" translate>
                {{ getNivel(k).descricao }}
                <em
                  *ngIf="getNivel(k).tooltip as tooltip"
                  class="pi pi-info-circle"
                  [pTooltip]="tooltip | translate"
                ></em>
              </span>
            </p>
            <p-knob
              [ngModel]="getNivel(k).valor"
              [readonly]="true"
              [size]="110"
              valueTemplate="{value}%"
            />
          </app-inicio-card>
        </div>
      </div>
    </div>
  </div>
</div>
