<app-header></app-header>
<div class="conteudoCabecalho">
  <div class="tituloSaudacao">
    <div translate>introducao.textoSaudacao<span class="robotoBold"> Nina!</span></div>
  </div>

  <div class="conteudoDetalhe introducao">
    <div class="p-grid">
      <div class="p-col-7">
        <div [innerHTML]="'configuracao.textoConfiguracao' | translate"></div>
        <br>
      </div>
    </div>
  </div>
</div>

<div class="acesso">Configuração de acesso</div>

<div style="padding-top: 60px; text-align: center;">
  <button style="margin: 0 auto"  [label]="'botaoOnline' | translate" pButton class="botaoGreen botaoOnline" (click)="setIsOnlineSelecionado(true)"  [ngClass]="classBotaoOnline"></button>

  <button style="margin: 0 auto"  [label]="'botaoOffline' | translate" pButton class="botaoGreen botaoOffline" (click)="setIsOnlineSelecionado(false)" [ngClass]="classBotaoOffline"></button>
</div>

<div style="padding-top: 60px; display:flex; margin-top: 7px">
  <button style="margin: 0 auto"  [label]="'botaoContinuar' | translate" pButton class="botao"></button>
</div>
