import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { Roles } from "../enums/roles.enum";

@Injectable({
  providedIn: "root",
})
export class RoleService {
  private systemRoles = [
    "RL_ADMINISTRADOR",
    "RL_GESTOR_SME",
    "RL_GESTOR_SME_MEDICAO",
    "RL_SUPERVISOR",
    "RL_SUPERVISOR_CHECKLIST",
    "RL_MEDICO",
    "RL_GESTOR",
    "RL_GESTOR_CLIENTE",
    "RL_OPERADOR",
  ];

  constructor(private keyCloakService: KeycloakService) {}

  getAll() {
    return this.keyCloakService.getUserRoles();
  }

  getSystemRoles() {
    return this.systemRoles.filter((r) => this.getRoles().includes(r));
  }

  getRoles() {
    return this.getAll().filter((r) => r.indexOf("RL_") >= 0);
  }

  isOnlyOperador() {
    return (
      this.getSystemRoles().length == 1 &&
      this.getSystemRoles()[0] == Roles.OPERADOR
    );
  }

  isRole(role: Roles | string) {
    const hasRole = this.getRoles().includes(role);
    if (role == Roles.OPERADOR) {
      return this.getRoles().length == 1 && hasRole;
    }
    return hasRole;
  }
}
