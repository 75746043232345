import { Component, Input, OnInit } from '@angular/core';
import { ConfiguracaoTesteApiService } from 'src/app/shared/api-services/configuracao-teste.api-service';
import { ResponseHistoricoV2TO } from 'src/app/shared/tos/response-historico-v2.to';

@Component({
  selector: 'app-inicio-historico-resultado',
  templateUrl: './inicio-historico-resultado.component.html',
  styleUrls: ['./inicio-historico-resultado.component.scss']
})
export class InicioHistoricoResultadoComponent implements OnInit {
  @Input() isLoading;
  responseHistoricoEvolucao: ResponseHistoricoV2TO;

  constructor(
    private configuracaoService: ConfiguracaoTesteApiService
  ) { }

  ngOnInit(): void {
    this.getHistoricoEvolucao();
  }

  getHistoricoEvolucao() {
    this.configuracaoService.getHistoricoNivelResultado().subscribe(resultado => {
      this.responseHistoricoEvolucao = resultado;
    })
  }

}
