
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZoomBlockerService {
  private isPortraitMode = this.isPortrait();
  private screenWidth = window.innerWidth;
  private viewportWidth = window.innerWidth;
  private zoomOutOffset = 1.2;
  private zoomInOffset = 0.9;
  private _onDimensionsMismatch = new Subject();

  constructor() {
    this.listenWindowResize();
  }

  private isPortrait() {
    return window.innerHeight > window.innerWidth;
  }

  private listenWindowResize() {
    window.addEventListener('resize', (ev) => {
      const isMobileOrientationChanged = this.isPortraitMode !== this.isPortrait();
      this.viewportWidth = window.innerWidth;
      if (isMobileOrientationChanged) {
        this.isPortraitMode = this.isPortrait();
        this.screenWidth = window.innerWidth
      } else {
        this.verifyDimensions();
      }
    })
  }

  verifyDimensions(): boolean {
    if (this.viewportWidth < this.screenWidth * this.zoomInOffset || this.viewportWidth > this.screenWidth * this.zoomOutOffset) {
      this._onDimensionsMismatch.next({
        viewportWidth: this.viewportWidth,
        screenWidth: this.screenWidth
      })
      return false;
    }
    return true;
  }

  onDimensionsMismatch() {
    setTimeout(() => {
      this.verifyDimensions();
    });
    return this._onDimensionsMismatch;
  }


}
