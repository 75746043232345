export enum ModulosEnum {
  SAUDE_MENTAL = "saudeMental",
  CHECKLIST_COVID = "checklistCovid",
  AVALIACAO = "avaliacao",
  EMOCIOGRAMA = "emociograma",
  SIMULADOR = "simulador",
  PERGUNTAS_MATUTINIDADE = "perguntasMatutinidade",
  QUESTIONARIO_PRE_TESTE = "questionarioPreTeste",
  SEGURANCA_PSICOLOGICA = "segurancaPsicologica",
}
