export enum ACAO_TESTE {
  RESET_WORKER = 'RESET_WORKER',
  START = 'START',
  EXIBIR_IMAGEM_CORRETA = 'EXIBIR_IMAGEM_CORRETA',
  EXIBIR_IMAGEM_DISTRACAO = 'EXIBIR_IMAGEM_DISTRACAO',
  ESCONDER_IMAGEM_DISTRACAO = 'ESCONDER_IMAGEM_DISTRACAO',
  ESCONDER_IMAGEM_CORRETA = 'ESCONDER_IMAGEM_CORRETA',
  VERIFICAR_PARADA = 'VERIFICAR_PARADA',
  REINICIAR = 'REINICIAR',
  CONFIRMAR_EXIBIR_IMAGEM_DISTRACAO = 'CONFIRMAR_EXIBIR_IMAGEM_DISTRACAO',
  CONFIRMAR_ESCONDER_IMAGEM_DISTRACAO = 'CONFIRMAR_ESCONDER_IMAGEM_DISTRACAO',
  CONFIRMAR_EXIBIR_IMAGEM_CORRETA = 'CONFIRMAR_EXIBIR_IMAGEM_CORRETA',
  CONFIRMAR_ESCONDER_IMAGEM_CORRETA = 'CONFIRMAR_ESCONDER_IMAGEM_CORRETA',
  CONFIRMAR_VERIFICAR_PARADADA_CONTINUAR = 'CONFIRMAR_VERIFICAR_PARADADA_CONTINUAR',
  CONFIRMAR_VERIFICAR_PARADADA_REINICIAR = 'CONFIRMAR_VERIFICAR_PARADADA_REINICIAR',
  CONFIRMAR_VERIFICAR_PARADADA_FINALIZAR = 'CONFIRMAR_VERIFICAR_PARADADA_FINALIZAR',
}
