<app-header></app-header>

<div class="finalizar--container">
  <p class="finalizar--titulo">
    <span translate> finalizar.parabens </span>, {{ nomeOperador }}!
  </p>
  <p class="finalizar--texto">
    <span translate> finalizar.msgFinalizar </span>
    <span translate> inicio.{{ nomeModulo }} </span> <br />
    <span translate *ngIf="showBotaoVoltar"> finalizar.msgVoltar </span>
  </p>
  <div class="finalizar--botoes">
    <button
      *ngIf="showBotaoVoltar"
      type="button"
      (click)="voltar()"
      class="botao__voltar"
      translate
    >
      finalizar.voltar
    </button>
    <button type="button" (click)="sair()" class="botao__sair" translate>
      finalizar.sair
    </button>
  </div>
</div>
