export const arDateTranslation = {
  firstDayOfWeek: 0,
  dayNames: ["الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"],
  dayNamesShort: ["ح", "ن", "ث", "ع", "خ", "ج", "س"],
  dayNamesMin: ["ح", "ن", "ث", "ع", "خ", "ج", "س"],
  monthNames: [
    "يناير",
    "فبراير",
    "مارس",
    "أبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ],
  monthNamesShort: [
    "يناير",
    "فبراير",
    "مارس",
    "أبريل",
    "مايو",
    "جون",
    "جول",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ],
  today: "اليوم",
  clear: "مسح",
  dateFormat: "dd/mm/yyyy",
};
