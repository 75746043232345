export const urDateTranslation = {
  firstDayOfWeek: 0,
  dayNames: ["اتوار", "پیر", "منگل", "بدھ", "جمعرات", "جمعہ", "ہفتہ"],
  dayNamesShort: ["سورج", "پیر", "منگل", "بدھ", "جمعرات", "جمعہ", "سات"],
  dayNamesMin: ["ایس یو", "مو", "ٹو", "ہم", "ویں", "Fr", "ص"],
  monthNames: [
    "جنوری",
    "فروری",
    "مارچ",
    "اپریل",
    "مئی",
    "جون",
    "جولائی",
    "اگست",
    "ستمبر",
    "اکتوبر",
    "نومبر",
    "دسمبر",
  ],
  monthNamesShort: [
    "جنوری",
    "فروری",
    "مارچ",
    "اپریل",
    "مئی",
    "جون",
    "جولائی",
    "اگست",
    "ستمبر",
    "اکتوبر",
    "نومبر",
    "دسمبر",
  ],
  today: "آج",
  clear: "صاف",
  dateFormat: "mm/dd/yy",
};
