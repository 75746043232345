export enum HistoricoNormalEnum {
  EVOLUIU = 'EVOLUIU',
  REGREDIU = 'REGREDIU',
  INSUFICIENTE = 'INSUFICIENTE',
}

export function getNmImagem(historicoNormalEnum: HistoricoNormalEnum): string {
  switch (historicoNormalEnum) {
    case HistoricoNormalEnum.EVOLUIU:
      return 'Evoluiu';
    case HistoricoNormalEnum.REGREDIU:
      return 'Regrediu';
    case HistoricoNormalEnum.INSUFICIENTE:
      return 'Insuficiente';
  }
}



