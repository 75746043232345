import {
  JsonObject,
  JsonProperty,
  PropertyConvertingMode,
} from "json2typescript";

@JsonObject("EmociogramaTO")
export class EmociogramaTO {
  @JsonProperty("ae1", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsEmociograma: string = null;

  @JsonProperty("ae4", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  nrOrdem: number = null;

  @JsonProperty("ae3", String, PropertyConvertingMode.IGNORE_NULLABLE)
  nmImagem: string = null;

  @JsonProperty("ae2", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  id: number = null;

  backgroundColor: string;
}
