import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-timer-txt',
  templateUrl: './timer-txt.component.html',
  styleUrls: ['./timer-txt.component.scss']
})
export class TimerTxtComponent implements OnInit {
  @Input() tempo: number;

  tempoRestante: number;

  constructor() { }

  ngOnInit() {
    this.tempoRestante = this.tempo;
    this.iniciarTimer();
  }

  iniciarTimer() {
    const intervalo = setInterval(() => {
      this.tempoRestante--;
      if (this.tempoRestante <= 0) {
        clearInterval(intervalo);
      }
    }, 1000);
  }

}
