<p-dialog
  [showHeader]="false"
  [(visible)]="exibir"
  [modal]="true"
  [style]="{ width: '90%', 'max-width': '500px' }"
>
  <div class="header">
    <app-cached-img src="assets/images/icones/alerta.png" />
  </div>

  <div class="content">
    <p [innerHtml]="'segurancaPsicologica.modalParagrafo1' | translate"></p>
    <p [innerHtml]="'segurancaPsicologica.modalParagrafo2' | translate"></p>
  </div>

  <div class="botoes">
    <button
      type="button"
      class="botao"
      (click)="handleClick('RESPONDIDO')"
      translate
    >
      segurancaPsicologica.modalResponderAgora
    </button>
    <button
      *ngIf="isQuestionarioOpcional"
      type="button"
      class="botao botao-branco"
      (click)="handleConfirm('NAO_VAI_RESPONDER')"
      translate
    >
      segurancaPsicologica.modalPular
    </button>
  </div>
</p-dialog>
