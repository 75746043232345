import { JsonObject, JsonProperty, PropertyConvertingMode } from 'json2typescript';
import { TipoDispositivo } from '../enums/tipo-dispositivo.enum';

@JsonObject("ValidarTO")
export class ValidarTO {
  
  @JsonProperty("aa", String, PropertyConvertingMode.IGNORE_NULLABLE)
  tipoDispositivo: TipoDispositivo = null;
  
  @JsonProperty("ab", Boolean, PropertyConvertingMode.IGNORE_NULLABLE)
  embedded: boolean = null;
}
