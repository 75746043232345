<p-dialog
  [visible]="show"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{
    width: '90%',
    'max-width': '640px',
    'max-height': '98vh',
    overflow: 'auto'
  }"
  [closable]="false"
>
  <p-header>
    <div class="dialog-title p-dialog-titlebar">
      <span translate tabindex="0">
        perguntas.textoQuestionarioComplementar
      </span>
    </div>
  </p-header>

  <div class="container">
    <span class="breadcrumb" tabindex="0">
      {{ "perguntas.textoQuestionario" | translate }}: {{ currentIdx + 1 }} /
      {{ qtdQuestionarios }}</span
    >
    <form class="p-grid" (submit)="handleOnSubmit()">
      <div
        [class]="getPerguntaCol()"
        *ngFor="let pergunta of currentQuestion?.itens; let i = index"
        role="group"
        [attr.aria-labelledby]="'label_' + pergunta.idItem"
      >
        <label
          [id]="'label_' + pergunta.idItem"
          class="titulo-pergunta"
          [ngClass]="{ invalid: submitted && !isPerguntaRespondida(pergunta) }"
        >
          {{ i + 1 }}. {{ pergunta.dsPerguntaItem }}</label
        >
        <ng-container *ngFor="let opc of pergunta.opcoes">
          <div class="p-field-checkbox">
            <p-radioButton
              *ngIf="isRadioButton(pergunta)"
              [name]="pergunta.idItem"
              [(ngModel)]="
                respostaPerguntas[currentQuestion.id][pergunta.idItem]
              "
              [inputId]="'opc' + opc.idItemOpcao"
              [value]="opc.idItemOpcao"
            ></p-radioButton>
            <p-checkbox
              *ngIf="!isRadioButton(pergunta)"
              [name]="pergunta.idItem"
              [(ngModel)]="
                respostaPerguntas[currentQuestion.id][pergunta.idItem]
              "
              [inputId]="'opc' + opc.idItemOpcao"
              [value]="opc.idItemOpcao"
            ></p-checkbox>
            <label [attr.for]="'opc' + opc.idItemOpcao">
              {{ opc.dsPerguntaItemOpcao }}
            </label>
          </div>
        </ng-container>
      </div>

      <div class="button-content" *ngIf="show">
        <button type="submit" class="botao btn-small">
          <span *ngIf="isUltimaPergunta()" translate> botaoFinalizar </span>
          <span *ngIf="!isUltimaPergunta()" translate> botaoProximo </span>
        </button>
      </div>
    </form>
  </div>
</p-dialog>
