<p-dialog
  [visible]="show"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{
    width: '90%',
    'max-width': '640px',
    'max-height': '98vh',
    overflow: 'auto'
  }"
  [closable]="false"
>
  <p-header>
    <app-cached-img
      src="/assets/images/bem-estar/gerdau_mais_cuidado.jpg"
      alt="Logo +Cuidado"
      class="logomarca"
    />
  </p-header>

  <div class="container">
    <p class="titulo" translate>buscaAtiva.tituloGerdau</p>
    <p class="descricao" translate>buscaAtiva.descricaoGerdau</p>
    <p class="descricao" translate>buscaAtiva.descricaoGerdau2</p>
    <app-cached-img
      src="/assets/images/bem-estar/gerdau_mais_cuidado_qrcode.jpg"
      alt="QrCode"
      class="qrcode"
    />

    <button
      type="button"
      [label]="'notificacao.ok' | translate"
      pButton
      class="botao"
      (click)="submit()"
    ></button>
  </div>
</p-dialog>
