import { ClienteEnum } from "./../shared/enums/Cliente.enum";
import { appendScript } from "./../shared/utils/appendScript.util";
import { environment } from "src/environments/environment";

export const initNewRelic = () => {
  const BLOCK_CLIENTS = [ClienteEnum.WEG, ClienteEnum.DESENV];
  const isBlocked = BLOCK_CLIENTS.includes(environment.cliente as ClienteEnum);
  if (!isBlocked) {
    appendScript(`assets/new-relic.js`, "head");
  }
};
