import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Input,
} from "@angular/core";
import { HistoricoNormalEnum } from "src/app/shared/enums/historico-normal.enum";
import * as moment from "moment/moment";
import { Chart } from "angular-highcharts";
import { XAxisOptions } from "highcharts";
import { LocalStorageService } from "src/app/shared/services/local-storage.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { ResponseHistoricoV2TO } from "../../tos/response-historico-v2.to";

@Component({
  selector: "app-historico-resultado",
  templateUrl: "./historico-resultado.component.html",
  styleUrls: ["./historico-resultado.component.scss"],
})
export class HistoricoResultadoComponent implements OnInit, AfterViewInit {
  @Input() historico: ResponseHistoricoV2TO;
  @Input() exibirTextoEvolucao: boolean = true;
  chart: Chart;
  interval: any;
  showLabelUltimoTeste: boolean = false;
  dadosGrafico: any[] = [];

  constructor(
    private routerService: RouterService,
    protected localStorageService: LocalStorageService
  ) {}

  ngAfterViewInit() {
    setTimeout((_) => {
      this.interval = setInterval((_) => {
        const g: any = document.querySelector(
          ".highcharts-markers.highcharts-series-0.highcharts-line-series.highcharts-color-0.highcharts-tracker"
        );
        if (g != null && g.getElementsByTagName("path") != null) {
          const paths: any[] = g.getElementsByTagName("path");
          if (paths.length > 0) {
            const valorCoordenadasUltimoPonto: any = paths[paths.length - 1]
              .getAttribute("d")
              .split(" ");
            this.showLabelUltimoTeste = true;
            const divUltimTeste: HTMLElement =
              document.querySelector("#ultimoTeste");
            if (divUltimTeste != null) {
              divUltimTeste.style.top =
                Number(valorCoordenadasUltimoPonto[2]) + 4 + "px";
              divUltimTeste.style.left =
                Number(valorCoordenadasUltimoPonto[1]) + 50 + "px";
              clearInterval(this.interval);
            }
          }
        }
      }, 1000);
    }, 500);
  }

  ngOnInit() {
    setTimeout(() => {
      this.configurarGrafico();
      this.historico.histNivelResultadoValores.forEach((valor, i) =>
        this.dadosGrafico.push({
          x: i,
          name: new Date(valor.dtExecucao),
          y: valor.vlItem,
        })
      );
      const ultimoPonto: any = this.dadosGrafico[this.dadosGrafico.length - 1];
      if (ultimoPonto) {
        this.dadosGrafico[this.dadosGrafico.length - 1] = {
          name: ultimoPonto.name,
          x: ultimoPonto.x,
          y: ultimoPonto.y,
          marker: { fillColor: "#FFFFFF" },
        };
      }
    }, 500);
  }

  isProgrediu(): boolean {
    return this.historico.cdEvoluiuRegrediu == HistoricoNormalEnum.EVOLUIU;
  }

  isRegrediu(): boolean {
    return this.historico.cdEvoluiuRegrediu == HistoricoNormalEnum.REGREDIU;
  }

  configurarGrafico(): void {
    this.chart = null;
    const periods = [
      { start: 1, end: 1.5, color: "#EE9C1E" },
      { start: 2, end: 2.5, color: "#EE9C1E" },
      { start: 3, end: 3.5, color: "#AD9A66" },
      { start: 4, end: 4.5, color: "#4297DE" },
      { start: 5, end: 5.5, color: "#4297DE" },
    ];

    const width: HTMLElement = document.querySelector("#containeGrafico");

    const zones = [];

    for (let i = 0; i < periods.length; i++) {
      // This zone is a "in-between" zone to fill the blanks.
      zones.push({
        value: periods[i].start,
        color: periods[i].color,
        dashStyle: "dot",
      });

      // This zone is an actual period
      zones.push({
        value: periods[i].end,
        color: periods[i].color,
        dashStyle: "dot",
      });
    }

    // Point of interest options
    const xAxis: XAxisOptions = {};
    xAxis.visible = false;
    xAxis.categories = [].concat(this.dadosGrafico.map((v) => v.x));

    this.chart = new Chart({
      legend: {
        enabled: false,
      },
      xAxis: xAxis,
      yAxis: {
        showEmpty: true,
        startOnTick: false,
        tickInterval: 1,
        min: 1,
        max: 5,
        title: {
          text: null,
        },
        labels: {
          style: {
            display: "none",
          },
        },
      },
      exporting: { enabled: false },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        width: width?.offsetWidth - 45,
        backgroundColor: null,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return moment(new Date(this.key)).format("DD/MM/YY HH:mm");
        },
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true,
            fillColor: null,
            lineWidth: 2,
            lineColor: null,
          },
        },
      },
      title: {
        text: "",
        align: "center",
        verticalAlign: "middle",
        y: 20,
        x: -55,
      },
      series: [
        {
          zones: zones,
          type: "line",
          data: this.dadosGrafico,
        },
      ],
    });
  }

  dateToStringByFormat(data: Date, format: string): string {
    return moment(data).format(format);
  }
}
