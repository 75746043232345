<app-simulador-borda *ngIf="isSimulacao"></app-simulador-borda>
<div
  style="display: flex; width: 100%; height: 100%; background-color: #2e3b4f"
  (touchstart)="registrarTap($event)"
>
  <div id="container-imagens">
    <div [ngClass]="[cantoTelaAtual]" [class.invisible]="!isImagemExameVisible">
      <app-cached-img
        [src]="caminhoImagemExame"
        className="imagemTeste"
      ></app-cached-img>
    </div>
  </div>

  <div
    class="centro"
    [ngClass]="{ invisible: !isImagemDistracaoVisible }"
    style="position: absolute; align-content: center"
  >
    <app-cached-img
      src="{{ imagemDistracao }}"
      id="{{ currentImagemDist }}"
      className="imagemTeste"
    ></app-cached-img>
  </div>

  <div
    [class.orientacaoToqueTela]="isMobile"
    [class.orientacaoToqueTelaHover]="isMobileRemove"
    (click)="isMouseDown()"
  ></div>
</div>
