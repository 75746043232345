const getCaseSensitiveParams = (params: string): URLSearchParams => {
  const originalParams = new URLSearchParams(params) as any;
  const newParams = new URLSearchParams();
  for (const [name, value] of originalParams.entries()) {
    newParams.append(name.toLowerCase(), value);
  }
  return newParams;
}

export {
  getCaseSensitiveParams
}