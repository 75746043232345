import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable, of, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { ApiService } from "./api-service.interface";

import { ConfiguracaoTesteTO } from "../tos/configuracao-teste.to";

import { ApiServicesVersionConfig } from "src/app/api-services-version.config";
import { SessionStorageService } from "../services/session-storage.service";
import { ValidarTO } from "../tos/validar.to";
import { SessionStorageKeys } from "../enums/session-storage-keys.enum";
import { environment } from "src/environments/environment";
import { TipoDispositivoService } from "../services/tipo-dispositivo.service";
import { JsonSerializeService } from "../services/json-serialize.service";
import { ResponseValidarTesteInicial } from "../tos/response-validar-teste-inicial.to";
import { CONFIGURACAO_SIMULADOR } from "../model/simulacao-configuracao.model";
import { dispatchError } from "src/app/core/dispatchError";
import { ResponseHistoricoV2TO } from "../tos/response-historico-v2.to";
import { ResponseHistoricoClassifResultadoV2TO } from "../tos/response-historico-classificacao-resultado-v2.to";

@Injectable({
  providedIn: "root",
})
export class ConfiguracaoTesteApiService implements ApiService {
  configuracao: ConfiguracaoTesteTO;

  constructor(
    private httpClient: HttpClient,
    private sessionStorageService: SessionStorageService,
    private tipoDispositivoService: TipoDispositivoService,
    private serializer: JsonSerializeService
  ) {}

  getApiVersion(): string {
    return ApiServicesVersionConfig.configuracaoTeste;
  }

  private getSimuladorConfiguracaoTeste(): Observable<ConfiguracaoTesteTO> {
    return of(CONFIGURACAO_SIMULADOR);
  }

  getConfiguracaoTesteTO(
    isSimulador: boolean
  ): Observable<ConfiguracaoTesteTO> {
    if (isSimulador) {
      return this.getSimuladorConfiguracaoTeste();
    }
    const validarTO = new ValidarTO();
    validarTO.tipoDispositivo = this.tipoDispositivoService.getDevice();
    validarTO.embedded = environment.flEmbedded;
    const payload = this.serializer.serialize(validarTO, ValidarTO);

    return this.httpClient
      .post<ConfiguracaoTesteTO>(
        `${this.getApiVersion()}/teste-executado/validar`,
        payload
      )
      .pipe(
        map((r) => {
          const config = this.serializer.deserialize(r, ConfiguracaoTesteTO);
          this.ordenarPerguntas([
            config.perguntas,
            config.perguntasSegurancaPsicologica,
            config.perguntasSegurancaPsicologica
              ? [...config.perguntasSegurancaPsicologica?.map((r) => r.opcoes)]
              : [],
            config.perguntasEpworth,
          ]);
          return config;
        }),
        tap((obj) => this.salvarNaSessao(obj))
      );
  }

  getvalidarTesteInicialTO(): Observable<ResponseValidarTesteInicial> {
    const validarTO = new ValidarTO();
    validarTO.tipoDispositivo = this.tipoDispositivoService.getDevice();
    validarTO.embedded = environment.flEmbedded;

    const payload = this.serializer.serialize(validarTO, ValidarTO);

    return this.httpClient
      .post<ResponseValidarTesteInicial>(
        `${this.getApiVersion()}/teste-executado/validar-teste-inicial`,
        payload
      )
      .pipe(
        map((r) => {
          const config: ResponseValidarTesteInicial =
            this.serializer.deserialize(r, ResponseValidarTesteInicial);
          this.ordenarPerguntas([
            config.perguntasVacinacao,
            config.perguntasDiscursivas,
            config.perguntasIniciais,
            config.perguntasMatutinidade,
            config.perguntasMatutinidade
              ? [...config.perguntasMatutinidade?.map((r) => r.opcoes)]
              : [],
          ]);
          return config;
        }),
        catchError((err) => {
          dispatchError("ERRO_VALIDAR_INICIAL", err);
          return this.getConfiguracaoLogout().pipe(
            map((response) => ({
              err,
              response,
            }))
          );
        }),
        tap((response: any) => {
          if (response.err) {
            throw response.err;
          }
          return response;
        })
      );
  }

  getConfiguracaoLogout() {
    return this.httpClient
      .get(`${this.getApiVersion()}/teste-executado/configuracao-logout`)
      .pipe(
        tap(({ cdBaseAutenticacao, dsUrlLogoutBaseAutenticacao }: any) => {
          const config = new ConfiguracaoTesteTO();
          config.cdBaseAutenticacao = cdBaseAutenticacao;
          config.dsUrlLogoutBaseAutenticacao = dsUrlLogoutBaseAutenticacao;
          this.salvarNaSessao(config);
        })
      );
  }

  getComprovanteProntidao(): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "*/*");
    return this.httpClient
      .get<Blob>(
        `${this.getApiVersion()}/teste-executado/comprovante-prontidao`,
        { headers: headers, responseType: "blob" as "json" }
      )
      .pipe(map((file) => new Blob([file], { type: "application/pdf" })));
  }

  getHistoricoNivelResultado(): Observable<ResponseHistoricoV2TO> {
    return this.httpClient
      .get<ResponseHistoricoV2TO>(
        `${this.getApiVersion()}/teste-executado/historico-nivel-resultado`
      )
      .pipe(
        map((response) => {
          return this.serializer.deserialize(response, ResponseHistoricoV2TO);
        })
      );
  }

  getHistoricoClassificacaoResultado(): Observable<ResponseHistoricoClassifResultadoV2TO> {
    return this.httpClient
      .get<ResponseHistoricoClassifResultadoV2TO>(
        `${this.getApiVersion()}/teste-executado/historico-classif-resultado`
      )
      .pipe(
        map((response) => {
          return this.serializer.deserialize(
            response,
            ResponseHistoricoClassifResultadoV2TO
          );
        })
      );
  }

  salvarNaSessao(configuracao: ConfiguracaoTesteTO) {
    this.configuracao = configuracao;
    this.sessionStorageService.setItem(
      SessionStorageKeys.CONFIGURACAO,
      configuracao
    );
  }

  recuperarDaSessao() {
    this.configuracao = this.sessionStorageService.getItem(
      SessionStorageKeys.CONFIGURACAO
    );
  }

  private ordenarPerguntas(...grupoPerguntas: any[]) {
    const ordenar = (a: { nrOrdem }, b: { nrOrdem }) => a?.nrOrdem - b?.nrOrdem;
    grupoPerguntas?.forEach((perguntas) => {
      perguntas?.forEach((p) => {
        p?.sort(ordenar);
        if (p?.itens?.length) {
          p?.sort(ordenar);
        }
      });
    });
  }
}
