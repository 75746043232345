export const idDateTranslation = {
  startsWith: "Dimulai dengan",
  contains: "Mengandung",
  notContains: "Tidak mengandung",
  endsWith: "Berakhir dengan",
  equals: "Sama",
  notEquals: "Tidak sama",
  noFilter: "Tanpa Filter",
  filter: "Filter",
  lt: "Kurang dari",
  lte: "Kurang dari atau sama dengan",
  gt: "Lebih dari",
  gte: "Lebih dari atau sama dengan",
  dateIs: "Tanggal adalah",
  dateIsNot: "Tanggalnya tidak",
  dateBefore: "Tanggalnya sebelum",
  dateAfter: "Tanggalnya sesudah",
  custom: "Custom",
  clear: "Bersih",
  apply: "Terapkan",
  matchAll: "Cocok dengan semua",
  matchAny: "Cocok dengen salah satu",
  addRule: "Tambah Aturan",
  removeRule: "Hapus Aturan",
  accept: "Terima",
  reject: "Tolak",
  choose: "Pilih",
  upload: "Unggah",
  cancel: "Batal",
  completed: "Selesai",
  pending: "Menunggu",
  dayNames: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"],
  dayNamesShort: ["Mig", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
  dayNamesMin: ["Mi", "Se", "Se", "Ra", "Ka", "Ju", "Sa"],
  monthNames: [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ],
  monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"],
  chooseYear: "Pilih Tahun",
  chooseMonth: "Pilih Bulan",
  chooseDate: "Pilih Tanggal",
  prevDecade: "Dekade Sebelumnya",
  nextDecade: "Dekade Selanjutnya",
  prevYear: "Tahun Sebelumnya",
  nextYear: "Tahun Selanjutnya",
  prevMonth: "Bulan Sebelumnya",
  nextMonth: "Bulan Selanjutnya",
  prevHour: "Jam Sebelumnya",
  nextHour: "Jam Selanjutnya",
  prevMinute: "Menit Sebelumnya",
  nextMinute: "Menit Selanjutnya",
  prevSecond: "Detik Sebelumnya",
  nextSecond: "Detik Selanjutnya",
  am: "am",
  pm: "pm",
  today: "Hari ini",
  weekHeader: "Wk",
  firstDayOfWeek: 1,
  showMonthAfterYear: false,
  dateFormat: "dd/mm/yy",
  weak: "Lemah",
  medium: "Sedang",
  strong: "Kuat",
  passwordPrompt: "Masukkan Kata Sandi",
  emptyFilterMessage: "Hasil pencarian tidak ditemukan",
  searchMessage: "{0} hasil tersedia",
  selectionMessage: "{0} item dipilih",
  emptySelectionMessage: "Tidak ada item dipilih",
  emptySearchMessage: "Tidak menemukan hasil pencarian",
  emptyMessage: "Tidak ada opsi tersedia",
  aria: {
    trueLabel: "Betul",
    falseLabel: "Salah",
    nullLabel: "Tidak dipilih",
    star: "bintang 1",
    stars: "{star} bintang",
    selectAll: "Semua item dipilih",
    unselectAll: "Tidak memilih semua item",
    close: "Tutup",
    previous: "Sebelumnya",
    next: "Selanjutnya",
    navigation: "Navigasi",
    scrollTop: "Scroll ke paling atas",
    moveTop: "Pindah ke paling atas",
    moveUp: "Pindah ke atas",
    moveDown: "Pindah ke bawah",
    moveBottom: "Pindah ke paling bawah",
    moveToTarget: "Pindah ke Target",
    moveToSource: "Pindah ke Sumber",
    moveAllToTarget: "Pindah Semua ke Target",
    moveAllToSource: "Pindah Semua ke Sumber",
    pageLabel: "Halaman {page}",
    firstPageLabel: "Halaman Pertama",
    lastPageLabel: "Halaman Terakhir",
    nextPageLabel: "Halaman Selanjutnya",
    previousPageLabel: "Halaman Sebelumnya",
    rowsPerPageLabel: "Baris per halaman",
    jumpToPageDropdownLabel: "Loncat ke halaman Dropdown",
    jumpToPageInputLabel: "Loncat ke halaman Input",
    selectRow: "Baris Dipilih",
    unselectRow: "Baris Tidak Dipilih",
    expandRow: "Bentangkan Baris",
    collapseRow: "Lipat Baris",
    showFilterMenu: "Tampilkan Menu Filter",
    hideFilterMenu: "Sembunyikan Menu Filter",
    filterOperator: "Operator Filter",
    filterConstraint: "Batasan Filter",
    editRow: "Ubah Baris",
    saveEdit: "Simpan Perubahan",
    cancelEdit: "Batalkan Perubahan",
    listView: "Tampilan Daftar",
    gridView: "Tampilan Grid",
    slide: "Slide",
    slideNumber: "{nomorSlide}",
    zoomImage: "Perbesar Gambar",
    zoomIn: "Perbesar",
    zoomOut: "Perkecil",
    rotateRight: "Putar ke Kanan",
    rotateLeft: "Putar ke Kiri",
  },
};
