import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";

import { Observable } from "rxjs";

import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class UrlLocaleInterceptor implements HttpInterceptor {
  constructor(private translateService: TranslateService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.url.includes("prontos-temporario.s3") ||
      req.url.includes("prontos-temporario-dev-hmg.s3") ||
      req.url.includes("{external}") ||
      req.url.includes("assets/i18n")
    ) {
      return next.handle(req);
    }
    const urlReq = req.clone({
      url: this.appendLocaleToURL(req.url, this.translateService.currentLang),
      headers: req.headers.append(
        "timezone-offset",
        new Date().getTimezoneOffset() + ""
      ),
    });
    //const urlReq = req.clone({ headers: req.headers.append('Content-Language', this.translateService.currentLang).append('timezone-offset', new Date().getTimezoneOffset() + '') });
    return next.handle(urlReq);
  }

  appendLocaleToURL(reqURL: string, locale: string): string {
    const paramChar = reqURL.includes("?") ? "&" : "?";
    return `${reqURL}${paramChar}locale=${locale}`;
  }
}
