import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root",
})
export class InactiveTimerService {
  private time: number = 60000 * 2; // 2 minutos
  private interval: any;

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private authService: AuthService
  ) {}

  start(
    onTimeOver?: () => Promise<any>,
    timer?: number,
    deslogarUsuario = true
  ) {
    const tempo = timer || this.time;
    this.stop();
    this.interval = setTimeout(async () => {
      try {
        this.stop();
        onTimeOver && (await onTimeOver());
        if (deslogarUsuario) {
          this.handleUser();
        }
      } catch (err) {
        console.log(err);
        this.handleUser();
      }
    }, tempo);
  }

  stop() {
    clearTimeout(this.interval);
  }

  handleUser() {
    if (environment.flEmbedded) {
      this.notificarUsuario();
    } else {
      this.authService.handleLogout();
    }
  }

  private notificarUsuario() {
    this.notificationService.notify({
      message: this.translateService.instant("notificacao.usuarioInativo"),
      acceptVisible: false,
    });
  }
}
