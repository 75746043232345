<div class="stepper">
  <div
    class="stepper-item"
    *ngFor="let step of stepsList"
    [ngClass]="{ '--atual': step == current, '--passado': step < current }"
  >
    <div class="stepper-circle">
      <img
        class="stepper-number"
        src="/assets/images/stepper/{{ step }}.png"
        [alt]="step"
      />
    </div>
    <div class="stepper-line"></div>
  </div>
</div>
