import { JsonObject, JsonProperty, PropertyConvertingMode } from "json2typescript";

@JsonObject("RespostaPerguntaDiscursivaTO")
export class RespostaPerguntaDiscursivaTO {
    @JsonProperty("ac1", Number, PropertyConvertingMode.IGNORE_NULLABLE)
    id: number = null;

    @JsonProperty("ac2", String, PropertyConvertingMode.IGNORE_NULLABLE)
    dsResposta: string = null;
}
