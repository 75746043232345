import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ItemPerguntaTo } from 'src/app/shared/tos/item-pergunta.to';
import { PerguntaTO } from 'src/app/shared/tos/pergunta.to';

interface RespostaPergunta {
  [idPergunta: string]: {
    [idItem: string]: number[];
  }
}
@Component({
  selector: 'app-questionario-complementar',
  templateUrl: './questionario-complementar.component.html',
  styleUrls: ['./questionario-complementar.component.scss']
})
export class QuestionarioComplementarComponent implements OnInit, OnChanges {
  @Output() onSuccess = new EventEmitter<RespostaPergunta>();
  @Output() onChangeQuestion = new EventEmitter();
  @Input() perguntas: PerguntaTO[];
  @Input() show: boolean;

  submitted = false;
  respostaPerguntas: RespostaPergunta = {};
  qtdQuestionarios = 0;
  currentIdx = 0;

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
  }

  get currentQuestion() {
    return this.perguntas ? this.perguntas[this.currentIdx] : null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.perguntas && changes.perguntas.currentValue) {
      this.qtdQuestionarios = this.perguntas.length;
      this.initRespostas(this.perguntas);
    }
  }

  initRespostas(perguntas: PerguntaTO[]) {
    perguntas.forEach(p => {
      p.itens.forEach(item => {
        if (!this.respostaPerguntas[p.id]) {
          this.respostaPerguntas[p.id] = {[String(item.idItem)]: undefined}
        } else {
          this.respostaPerguntas[p.id][String(item.idItem)] = undefined;
        }
      })
    })
  }

  isUltimaPergunta() {
    return this.currentIdx === this.qtdQuestionarios - 1;
  }

  handleOnSubmit() {
    this.submitted = true;
    if (this.isPerguntasObrigatoriasRespondidas()) {
      this.onChangeQuestion.emit();
      if (this.isUltimaPergunta() === false) {
        this.currentIdx += 1;
        this.submitted = false;
      } else {
        this.enviarRespostas();
      }
    } else {
      this.notificationService.notifyInfo({ message: this.translateService.instant('campoObrigatorio') });
    }
  }

  isPerguntaRespondida(pergunta: ItemPerguntaTo) {
    if (pergunta.flRespostaObrigatoria !== 'SIM') return true;
    const foiRespondido =  (resposta: number | number[]) => (resposta && !Array.isArray(resposta)) || (resposta && Array.isArray(resposta) && resposta.length);
    return foiRespondido(this.respostaPerguntas[this.currentQuestion.id][pergunta.idItem])
  }

  isPerguntasObrigatoriasRespondidas() {
    for (let pergunta of this.currentQuestion.itens) {
      if (!this.isPerguntaRespondida(pergunta)) {
        return false;
      }
    }
    return true;
  }

  enviarRespostas() {
    this.onSuccess.emit(this.respostaPerguntas);
  }

  getPerguntaCol() {
    return this.currentQuestion && this.currentQuestion.itens.length > 1 ? 'p-md-6' : 'p-md-12';
  }

  isRadioButton(itemPergunta: ItemPerguntaTo) {
    return itemPergunta.cdTipoExibicao === 'RADIO';
  }
}
