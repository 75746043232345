import { Output } from '@angular/core';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { PerguntaSegurancaPsicologicaTO } from 'src/app/shared/tos/pergunta-seguranca-psicologia.to';
import { RespostaSegurancaPsicologiaTO } from 'src/app/shared/tos/resposta-seguranca-psicologia.to';

@Component({
  selector: 'app-perguntas-psicologia',
  templateUrl: './perguntas-psicologia.component.html',
  styleUrls: ['./perguntas-psicologia.component.scss']
})
export class PerguntasPsicologiaComponent implements OnInit {
  @Input() perguntas: PerguntaSegurancaPsicologicaTO[];

  private _respostas: RespostaSegurancaPsicologiaTO[];
  @Input() get respostas(): RespostaSegurancaPsicologiaTO[] { return this._respostas; }
  @Output() respostasChange = new EventEmitter<RespostaSegurancaPsicologiaTO[]>();
  set respostas(respostas: RespostaSegurancaPsicologiaTO[]) {
    this._respostas = respostas;
    this.respostasChange.emit(respostas);
  }

  constructor() { }

  ngOnInit() {
  }

}
