import EncryptUtils from "../utils/encrypt-utils";

export class StorageCommon {
  
  crypto = new EncryptUtils();

  constructor(private storage: Storage) {

  }

  get length() {
    return this.storage.length;
  }

  key(index: number): string {
    return this.storage.key(index);
  }

  getItem<T>(key: string): T {
    const item: any = this.storage.getItem(key);

    try {
      return JSON.parse(this.crypto.decrypt(item)) as T;
    } catch (error) {
      return item as T;
    }

  }

  setItem<T>(key: string, item: T): void {
    this.storage.setItem(key, this.crypto.encrypt(JSON.stringify(item)));
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  getAndRemoveItem<T>(key: string): T {
    const item = this.getItem<T>(key);

    this.removeItem(key);

    return item;
  }

  clear(): void {
    this.storage.clear();
  }
}
