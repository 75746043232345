export class Utils {
  static groupBy(
    items: any[],
    cb: (item: any) => string
  ): { [key: string]: any[] } {
    const newItems = {};
    items.forEach((item) => {
      const key = cb(item);
      if (newItems[key]) {
        newItems[key].push(item);
      } else {
        newItems[key] = [item];
      }
    });
    return newItems;
  }
}
