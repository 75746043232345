import { Component, Input, OnInit } from '@angular/core';
import { PerguntaInicialTO } from 'src/app/shared/tos/perguntaInicial.to';

@Component({
  selector: 'app-pergunta-inical-content',
  templateUrl: './pergunta-inical-content.component.html',
  styleUrls: ['./pergunta-inical-content.component.scss']
})
export class PerguntaInicalContentComponent implements OnInit {
  @Input() pergunta: PerguntaInicialTO;
  constructor() { }

  ngOnInit(): void {
  }

}
