import {
  JsonObject,
  JsonProperty,
  PropertyConvertingMode,
} from "json2typescript";
import { RespostaPerguntaAdicionalV2TO } from "./request-resposta-pergunta-adicional.to";

@JsonObject("RespostaPerguntaCompInseguroV2TO")
export class RespostaPerguntaCompInseguroV2TO
  implements RespostaPerguntaAdicionalV2TO
{
  @JsonProperty("ab1", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idPergunta: number = null;

  @JsonProperty("ab2", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idOpcao: number = null;
}
