import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { StringUtils } from "../../utils/StringUtils";

@Component({
  selector: "app-select-card",
  templateUrl: "./select-card.component.html",
  styleUrls: ["./select-card.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectCardComponent),
      multi: true,
    },
  ],
})
export class SelectCardComponent implements OnInit, ControlValueAccessor {
  @Input() customId: string = StringUtils.randomString();
  @Input() name: string;

  private _isSelected: boolean = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() {}

  ngOnInit(): void {}

  get isSelected(): boolean {
    return this._isSelected;
  }

  set isSelected(val: boolean) {
    this._isSelected = val;
    this.onChange(val);
  }

  writeValue(obj: any): void {
    this.isSelected = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
