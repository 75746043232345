import { Injectable } from "@angular/core";
import { TipoDispositivo } from "../enums/tipo-dispositivo.enum";
import { LocalStorageService } from "./local-storage.service";
import { SessionStorageKeys } from "../enums/session-storage-keys.enum";
import { UAParser } from "ua-parser-js";

@Injectable({
  providedIn: "root",
})
export class TipoDispositivoService {
  uaParser = new UAParser();
  screenSizes = {
    mobile: {
      min: 0,
      max: 599,
    },
    tablet: {
      min: 600,
      max: 1100,
    },
  };
  constructor(private localStorage: LocalStorageService) {}

  getInfoDevice() {
    return this.uaParser.getResult();
  }

  private validateScreenSize(min: number, max: number) {
    const portrait = `(orientation: portrait) and (min-width: ${min}px) and (max-width: ${max}px)`;
    const landscape = `(orientation: landscape) and (min-height: ${min}px) and (max-height: ${max}px)`;
    const portraitMatches = matchMedia(portrait).matches;
    const landscapeMatches = matchMedia(landscape).matches;
    return portraitMatches || landscapeMatches;
  }

  isTouchDevice() {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
  }

  private isAvaiableType() {
    const type = this.uaParser.getDevice().type;
    return ["tablet", "mobile"].includes(type);
  }

  private validateTablet() {
    const localTabletVersions = /sm-t385m|sm-p585m|sm-t515/i.test(
      navigator.userAgent
    );
    if (
      this.localStorage.getItem<TipoDispositivo>(
        SessionStorageKeys.SME_DEVICE
      ) == TipoDispositivo.TABLET
    ) {
      return true;
    }
    if (!this.isAvaiableType()) {
      return (
        this.isTouchDevice() &&
        this.validateScreenSize(
          this.screenSizes.tablet.min,
          this.screenSizes.tablet.max
        )
      );
    }
    return (
      localTabletVersions ||
      this.uaParser.getDevice().type == "tablet" ||
      (this.uaParser.getDevice().type == "mobile" &&
        this.validateScreenSize(
          this.screenSizes.tablet.min,
          this.screenSizes.tablet.max
        ))
    );
  }

  private validateMobile() {
    if (
      this.localStorage.getItem<TipoDispositivo>(
        SessionStorageKeys.SME_DEVICE
      ) == TipoDispositivo.MOBILE
    ) {
      return true;
    }
    if (!this.isAvaiableType()) {
      return (
        this.isTouchDevice() &&
        this.validateScreenSize(
          this.screenSizes.mobile.min,
          this.screenSizes.mobile.max
        )
      );
    }
    return this.uaParser.getDevice().type == "mobile";
  }

  getDevice() {
    if (this.validateTablet()) {
      return TipoDispositivo.TABLET;
    } else if (this.validateMobile()) {
      return TipoDispositivo.MOBILE;
    } else {
      return TipoDispositivo.DESKTOP;
    }
  }

  isSafari() {
    return (
      this.uaParser.getBrowser().name?.toLowerCase().indexOf("safari") >= 0
    );
  }

  isSafariMaior12() {
    return (
      this.isSafari() &&
      Number(this.uaParser.getBrowser().version?.split(".")[0]) > 12
    );
  }

  isMobile() {
    return this.getDevice() == TipoDispositivo.MOBILE;
  }

  isTablet() {
    return this.getDevice() == TipoDispositivo.TABLET;
  }

  isDesktop() {
    return this.getDevice() == TipoDispositivo.DESKTOP;
  }
}
