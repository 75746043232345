import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { MessageService, SelectItem } from "primeng/api";
import { RespostaDoseEnum } from "src/app/shared/enums/RespostaDoseEnum";
import { ResopstaProximaDoseEnum } from "src/app/shared/enums/RespostaProimaDoseEnum";
import { FocusService } from "src/app/shared/services/focus.service";
import { PerguntaDiscursivaTO } from "src/app/shared/tos/pergunta-discursiva.to";
import { RespostaPerguntaDiscursivaTO } from "src/app/shared/tos/resposta-pergunta-discursiva.to";

@Component({
  selector: "app-pesquisa-vacinacao",
  templateUrl: "./pesquisa-vacinacao.component.html",
  styleUrls: ["./pesquisa-vacinacao.component.scss"],
})
export class PesquisaVacinacaoComponent implements OnInit, OnChanges {
  @Output() onSuccess = new EventEmitter<any>();
  @Input() perguntasVacinacao: PerguntaDiscursivaTO[];

  respostas: RespostaPerguntaDiscursivaTO[] = [];
  show: boolean = false;
  doses: SelectItem[] = [];
  proximasDoses: SelectItem[] = [];
  today = new Date();

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private focusService: FocusService
  ) {}

  ngOnInit() {
    this.doses = this.montarDoses();
    this.proximasDoses = this.montarProximasDoses();
    if (this.hasPerguntaTerceiraDose()) {
      this.show = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.perguntasVacinacao) {
      this.montarRespostas(changes.perguntasVacinacao.currentValue);
    }
  }

  montarRespostas(perguntas: PerguntaDiscursivaTO[]) {
    if (perguntas?.length) {
      this.respostas = perguntas.map((p) => {
        const resposta = new RespostaPerguntaDiscursivaTO();
        resposta.id = p.id;
        return resposta;
      });
    } else {
      this.respostas = [];
    }
  }

  montarDoses() {
    return [
      {
        label: this.translateService.instant("selecione"),
        value: null,
      },
      {
        label: this.translateService.instant("pesquisaVacinacao.dose1"),
        value: RespostaDoseEnum.DOSE1,
      },
      {
        label: this.translateService.instant("pesquisaVacinacao.dose2"),
        value: RespostaDoseEnum.DOSE2,
      },
      {
        label: this.translateService.instant("pesquisaVacinacao.doseUnica"),
        value: RespostaDoseEnum.DOSEUNICA,
      },
    ];
  }

  montarProximasDoses() {
    return [
      {
        label: this.translateService.instant("selecione"),
        value: null,
      },
      {
        label: this.translateService.instant("pesquisaVacinacao.dias30"),
        value: ResopstaProximaDoseEnum.DIAS30,
      },
      {
        label: this.translateService.instant("pesquisaVacinacao.dias60"),
        value: ResopstaProximaDoseEnum.DIAS60,
      },
      {
        label: this.translateService.instant("pesquisaVacinacao.dias90"),
        value: ResopstaProximaDoseEnum.DIAS90,
      },
    ];
  }

  toggle() {
    this.show = !this.show;
    if (this.show) {
      this.focusService.setElementFocus("#pesquisa-vacionacao-dialog");
    }
  }

  primeiraDoseTomada() {
    return this.respostas[0].dsResposta === RespostaDoseEnum.DOSE1;
  }

  isPerguntaDoses(ordem: number) {
    return ordem === 1;
  }

  isPerguntaCalendario(ordem: number) {
    return ordem === 2;
  }

  isPerguntaProximaDose(ordem: number) {
    return ordem === 3;
  }

  isPerguntaTerceiraDose(ordem: number) {
    return ordem === 4;
  }

  hasPerguntaTerceiraDose() {
    return this.perguntasVacinacao.some((pergunta) => pergunta.nrOrdem === 4);
  }

  handleSubmit() {
    this.show = false;
    this.messageService.add({
      severity: "success",
      summary: this.translateService.instant("pesquisaVacinacao.sucessoTitulo"),
      detail: this.translateService.instant(
        "pesquisaVacinacao.sucessoMensagem"
      ),
    });
    const copy = this.respostas.map((r) => Object.assign({}, r));
    this.converterDataRespostaCalendario(copy);
    const respostas = copy.filter(
      (r) => r.dsResposta && r.dsResposta !== "null"
    );
    this.onSuccess.emit(respostas);
  }

  converterDataRespostaCalendario(respostas: RespostaPerguntaDiscursivaTO[]) {
    const perguntaCalendario = this.perguntasVacinacao.find((p) =>
      this.isPerguntaCalendario(p.nrOrdem)
    );
    if (perguntaCalendario) {
      const respostaCalendario = respostas.find(
        (r) => r.id == perguntaCalendario.id
      );
      if (respostaCalendario?.dsResposta) {
        respostaCalendario.dsResposta = moment(
          respostaCalendario?.dsResposta
        ).format("DD/MM/YYYY");
      }
    }
  }
}
