<app-header
  [titulo]="'emociograma.textoTitulo' | translate"
  [subtitulo]="'emociograma.textoOrientacao' | translate"
></app-header>

<div class="conteudoDetalhe">
  <div
    *ngIf="loadingEmociogramas"
    class="loader"
    style="position: initial; margin: auto"
  ></div>
  <form
    *ngIf="!loadingEmociogramas"
    #form="ngForm"
    (submit)="handleSubmit(form)"
  >
    <div class="cards" role="group">
      <ng-container *ngFor="let item of emociogramas">
        <input
          class="cards__input"
          ngModel
          name="idEmociograma"
          [value]="item.id"
          type="radio"
          [id]="item.id"
          required
        />
        <label
          [for]="item.id"
          [ngClass]="{ '--has-background': !!item.backgroundColor }"
          [ngStyle]="{ 'background-color': item.backgroundColor }"
          class="cards__item"
          tabindex="0"
          role="checkbox"
          aria-required="true"
          [attr.aria-checked]="form.value.idEmociograma == item.id"
        >
          <app-cached-img
            class="cards__imagem"
            [src]="'assets/images/emociograma/' + item.nmImagem"
            [alt]="item.dsEmociograma"
            aria-hidden="true"
          />
          <p class="cards__texto">{{ item.dsEmociograma }}</p>
        </label>
      </ng-container>
    </div>

    <div class="p-mt-4">
      <button
        [label]="'botaoFinalizar' | translate"
        pButton
        class="botao"
        [disabled]="!form.valid || loadingSubmit"
      >
        <div *ngIf="loadingSubmit" class="loader"></div>
      </button>
    </div>
  </form>
</div>
