import {
  JsonObject,
  JsonProperty,
  PropertyConvertingMode,
} from "json2typescript";
import { BooleanEnum } from "../enums/boolean.enum";
import { AlvoTO } from "./alvo.to";
import { RequestPerguntaEpworh } from "./request-pergunta-epworth.to";
import { RespostaPerguntaCompInseguroV2TO } from "./request-resposta-pergunta-comp-insguro.to";
import { RespostaPerguntaMatutinidadeV2TO } from "./request-resposta-pergunta-matutinidade.to";
import { RespostaPerguntaTO } from "./resposta-pergunta.to";
import { RespostaSegurancaPsicologiaTO } from "./resposta-seguranca-psicologia.to";
import { RespostaBuscaAtivaV2TO } from "./request-resposta-busca-ativa.to";

@JsonObject("ProcessaTesteTO")
export class ProcessaTesteTO {
  @JsonProperty("aa", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idUsuario: number = null;

  @JsonProperty("ab", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idTeste: number = null;

  @JsonProperty("ac", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dtExecucao: string = null;

  @JsonProperty("ad", Boolean, PropertyConvertingMode.IGNORE_NULLABLE)
  flEmbedded: boolean = null;

  @JsonProperty("ae", Boolean, PropertyConvertingMode.IGNORE_NULLABLE)
  flEnviaRetorno: boolean = null;

  @JsonProperty("af", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flAnaliseGestaoOperador: BooleanEnum = null;

  @JsonProperty("ag", [AlvoTO], PropertyConvertingMode.IGNORE_NULLABLE)
  alvos: AlvoTO[] = null;

  @JsonProperty(
    "ah",
    [RespostaPerguntaTO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  respostaPerguntas: RespostaPerguntaTO[] = null;

  @JsonProperty(
    "ai",
    RespostaSegurancaPsicologiaTO,
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  respostaSegurancaPsicologica: RespostaSegurancaPsicologiaTO = null;

  @JsonProperty("aj", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idEmociograma: number = null;

  @JsonProperty("ak", [String], PropertyConvertingMode.IGNORE_NULLABLE)
  logWeb: string[] = null;

  @JsonProperty(
    "an",
    [RespostaPerguntaMatutinidadeV2TO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  respostasPerguntasMatutinidade: RespostaPerguntaMatutinidadeV2TO[] = null;

  @JsonProperty(
    "ao",
    RespostaBuscaAtivaV2TO,
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  respostaBuscaAtiva: RespostaBuscaAtivaV2TO = null;
}
