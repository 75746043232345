import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.scss"],
})
export class StepperComponent implements OnInit {
  @Input() current: number;
  @Input() total: number;
  stepsList: number[] = [];

  ngOnInit(): void {
    for (let i = 1; i <= this.total; i++) {
      this.stepsList.push(i);
    }
  }
}
