<div class="backgroud-login container">
  <form #form="ngForm" (submit)="autenticarUsuario(form)">
    <div class="content">
      <div class="logo">
        <label style="font-size: 18px">Sua logo aqui</label>
      </div>

      <div class="formulario">
        <input [(ngModel)]="loginTO.login" name="login" type="text" pInputText class="input" [placeholder]="'auth.loginPlaceholder' | translate" style="text-align: center;" autocomplete="off"/>
        <input [(ngModel)]="loginTO.password" name="password" type="password" pInputText class="input" [placeholder]="'auth.passwordPlaceholder' | translate" style="text-align: center;" />

        <div class="p-grid header" >
            <div class="p-col-2"></div>
            <div class="p-col-8">
              <button type="submit" label="Autenticar" pButton class="botao" style="width: 100%;" [disabled]="loading">
                <div class="loader" *ngIf="loading"></div>
              </button>
            </div>
            <div class="p-col-2"></div>
        </div>
      </div>
    </div>
  </form>
</div>
