<app-alert-bar />
<app-header [exibirSair]="true" />

<div class="menu">
  <app-inicio-saudacao [configuracao]="configuracaoTeste" />

  <div
    class="menu-row"
    *ngIf="!loadingConfig && configuracaoTeste?.flHabilitadoAvaliacao == 'SIM'"
  >
    <div class="avaliar-prontidao-wrapper">
      <app-inicio-card class="bigger" style="display: block; max-width: 285px">
        <app-cached-img
          class="img-avaliar-prontidao"
          src="assets/images/inicio/man-working.png"
        />
        <button
          type="button"
          class="botao-inicio"
          (click)="acessarAvaliacao()"
          [disabled]="configuracaoTeste?.flPodeExecutarAvaliacaoAgora == 'NAO'"
          translate
        >
          inicio.avaliarProntidao
        </button>
        <small
          class="mensagem-impeditiva"
          *ngIf="configuracaoTeste?.flPodeExecutarAvaliacaoAgora == 'NAO'"
          [innerHtml]="configuracaoTeste?.dsMensagemImpeditivaAvaliacaoAgora"
        >
        </small>
      </app-inicio-card>
    </div>
    <div class="historico-resultado-wrapper">
      <app-inicio-historico-resultado
        class="grafico-item"
        [isLoading]="loadingConfig"
      />
    </div>
  </div>

  <div
    *ngIf="loadingConfig"
    class="loader"
    style="margin: auto; position: initial"
  ></div>

  <app-inicio-acesso-rapido
    [ngClass]="{ hide: loadingConfig }"
    *ngIf="configuracaoTeste"
    [configuracao]="configuracaoTeste"
  />

  <app-inicio-nivel-geral-prontidao
    *ngIf="exibirNivelGeralProntidao"
    [ngClass]="{ hide: loadingConfig }"
    [habilitado]="configuracaoTeste?.flPodeExecutarAvaliacaoAgora == 'NAO'"
    (testeProntidaoClick)="acessarAvaliacao()"
  />

  <app-inicio-comunicados
    *ngIf="exibirComunicados"
    [ngClass]="{ hide: loadingConfig }"
    [habilitado]="
      configuracaoTeste?.flPodeExecutarAvaliacaoAgora == 'NAO' ||
      configuracaoTeste?.flHabilitadoApenasSegPsicologica == 'SIM'
    "
    (testeProntidaoClick)="acessarAvaliacao()"
  />
</div>
