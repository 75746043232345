import { Injectable } from "@angular/core";
import { RouterService } from "./router-service.service";
import { LocalStorageService } from "./local-storage.service";
import { SessionStorageKeys } from "../enums/session-storage-keys.enum";

@Injectable({
  providedIn: "root",
})
export class RedirectUriService {
  constructor(
    private localStoarge: LocalStorageService,
    private routerService: RouterService
  ) {}

  getUri() {
    const uri = this.localStoarge.getItem(SessionStorageKeys.SME_REDIRECT);
    return uri && uri != "null" && uri != "undefined" ? uri : null;
  }

  getRedirectAzure() {
    const urlBase = this.routerService.getRootUrl();
    return (
      "https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=" +
      urlBase
    );
  }
}
