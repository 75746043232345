import { Component, OnInit } from "@angular/core";
import { TesteService } from "src/app/shared/api-services/teste.service";
import {
  PerguntaQuestionario,
  Questionario,
  parsePerguntasAdicionalToQuestionaro,
  parseRespostasToRespostasAdicional,
} from "src/app/shared/components/questionario-geral/PerguntaQuestionario.to";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { LogService } from "src/app/shared/services/log.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { ConfiguracaoTesteTO } from "src/app/shared/tos/configuracao-teste.to";
import { RequestPerguntaEpworh } from "src/app/shared/tos/request-pergunta-epworth.to";
import { RespostaPerguntaAdicionalV2TO } from "src/app/shared/tos/request-resposta-pergunta-adicional.to";
import { RequestUploadQuestionarioV2TO } from "src/app/shared/tos/request-upload-questionario-v2.to";
import { ResultadoTesteTO } from "src/app/shared/tos/resultado-teste.to";

@Component({
  selector: "app-perguntas-karolinska",
  templateUrl: "./perguntas-karolinska.component.html",
  styleUrls: ["./perguntas-karolinska.component.scss"],
})
export class PerguntasKarolinskaComponent implements OnInit {
  questionarios: Questionario[] = [];
  loading: boolean = false;

  constructor(
    private sessionStorageService: SessionStorageService,
    private routerService: RouterService,
    private logService: LogService,
    private testeService: TesteService
  ) {}

  ngOnInit() {
    this.logService.append("Acessou Perguntas Karolinska");
    const resultado = this.sessionStorageService.getItem<ResultadoTesteTO>(
      SessionStorageKeys.RESULTADO
    );
    const perguntas = resultado.perguntasKarolinska;
    if (!perguntas?.length) {
      this.logService.append(
        "Nao possui perguntas Karolinska, encaminhando para Resultado"
      );
      this.submitRespostas();
    } else {
      this.questionarios = parsePerguntasAdicionalToQuestionaro(perguntas);
    }
  }

  onSubmit(event: PerguntaQuestionario[]) {
    const respostasKarolinska = parseRespostasToRespostasAdicional(event);
    this.sessionStorageService.setItem(
      SessionStorageKeys.RESPOSTAS_KAROLINSKA,
      respostasKarolinska
    );
    this.submitRespostas();
  }

  submitRespostas() {
    const respostasKarolinska = this.sessionStorageService.getItem<
      RespostaPerguntaAdicionalV2TO[]
    >(SessionStorageKeys.RESPOSTAS_KAROLINSKA);
    const respostasPerguntasCompInseguro = this.sessionStorageService.getItem<
      RespostaPerguntaAdicionalV2TO[]
    >(SessionStorageKeys.RESPOSTAS_COMPORTAMENTO_INSEGURO);
    const respostasSamPerelli = this.sessionStorageService.getItem<
      RespostaPerguntaAdicionalV2TO[]
    >(SessionStorageKeys.RESPOSTAS_SAM_PERELLI);
    const respostasAvulsoCliente = this.sessionStorageService.getItem<
      RespostaPerguntaAdicionalV2TO[]
    >(SessionStorageKeys.RESPOSTAS_AVULSO_CLIENTE);
    const idTeste = this.sessionStorageService.getItem<ResultadoTesteTO>(
      SessionStorageKeys.RESULTADO
    ).idTesteAtual;
    const idUsuario = this.sessionStorageService.getItem<ConfiguracaoTesteTO>(
      SessionStorageKeys.CONFIGURACAO
    ).idUsuario;
    const respostasEpworth = this.sessionStorageService.getItem<
      RequestPerguntaEpworh[]
    >(SessionStorageKeys.RESPOSTA_EPWORTH);
    const request = new RequestUploadQuestionarioV2TO();
    request.idTeste = idTeste;
    request.idUsuario = idUsuario;
    request.respostasPerguntasCompInseguro = respostasPerguntasCompInseguro;
    request.respostasPerguntasKarolinska = respostasKarolinska;
    request.respostasPerguntasSamPerelli = respostasSamPerelli;
    request.respostaPerguntasEpworth = respostasEpworth;
    request.respostasPerguntasAvulsoCliente = respostasAvulsoCliente;

    if (
      !request.respostasPerguntasCompInseguro?.length &&
      !request.respostasPerguntasKarolinska?.length &&
      !request.respostasPerguntasSamPerelli?.length &&
      !request.respostasPerguntasCompInseguro?.length &&
      !request.respostasPerguntasAvulsoCliente?.length
    ) {
      this.routerService.toResultado();
      return;
    }

    this.loading = true;
    this.testeService.sendQuestionarios(request).subscribe(
      (r) => {
        this.logService.append(
          "Finalizado perguntas Karolinska, encaminhando para o Resultado"
        );
        this.loading = false;
        this.routerService.toResultado();
      },
      (err) => {
        this.loading = false;
        throw err;
      }
    );
  }
}
