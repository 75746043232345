<app-header
  subtitulo="Leia com atenção cada questão antes de responder e responda a cada questão com toda a honestidade possível. Suas respostas e os resultados são confidenciais."
/>

<div class="conteudoDetalhe">
  <app-questionario-geral
    [loading]="loading"
    [questionarios]="questionario"
    (onSuccess)="onQuestionarioSuccess($event)"
  />
  <p class="copyright">
    Adaptado de Horne, J. A. & Ostberg, O., A self-assessment questionnaire to
    determine morningness-eveningness in human circadian rhythm. International
    Journal of Chronobiology, vol.4: 97-110, 1976).
  </p>
</div>
