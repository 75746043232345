import { Component, OnInit } from "@angular/core";
import { LogService } from "src/app/shared/services/log.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { ContratoSegurancaPsicologicaComponent } from "./components/seguranca-psicologica-component.int";
import { ResponderQuestionarioSegPsicologicaEnum } from "src/app/shared/enums/responder-questionario-seg-psicologica.enum";
import { PerguntaSegurancaPsicologicaTO } from "src/app/shared/tos/pergunta-seguranca-psicologia.to";
import { ConfiguracaoTesteTO } from "src/app/shared/tos/configuracao-teste.to";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { RespostaSegurancaPsicologiaTO } from "src/app/shared/tos/resposta-seguranca-psicologia.to";
@Component({
  selector: "app-seguranca-psicologica-teste",
  template: `<app-seguranca-psicologica
    *ngIf="isSegurancaPsicologicaDisponivel"
    [perguntas]="perguntas"
    [flExibeSegurancaPsicologica]="flExibeSegurancaPsicologica"
    (onSubmit)="salvar($event)"
  />`,
})
export class SegurancaPsicologicaTesteComponent
  implements OnInit, ContratoSegurancaPsicologicaComponent
{
  perguntas: PerguntaSegurancaPsicologicaTO[];
  flExibeSegurancaPsicologica: ResponderQuestionarioSegPsicologicaEnum;
  isSegurancaPsicologicaDisponivel: boolean;
  constructor(
    private sessionStorageService: SessionStorageService,
    private routerService: RouterService,
    private logService: LogService
  ) {}

  ngOnInit(): void {
    const configuracao: ConfiguracaoTesteTO =
      this.sessionStorageService.getItem(SessionStorageKeys.CONFIGURACAO);
    this.perguntas = configuracao.perguntasSegurancaPsicologica;
    this.flExibeSegurancaPsicologica = configuracao.flExibeSegurancaPsicologica;
    this.isSegurancaPsicologicaDisponivel =
      this.handleSegurancaPsicologicaDisponivel(
        this.perguntas,
        this.flExibeSegurancaPsicologica
      );

    if (!this.isSegurancaPsicologicaDisponivel) {
      this.logService.append(
        `Nao possui seguranca psicologica, encaminhando para Emociograma`
      );
      this.routerService.toEmociograma();
    }
  }

  handleSegurancaPsicologicaDisponivel(
    perguntas: PerguntaSegurancaPsicologicaTO[],
    flExibeSegurancaPsicologica: ResponderQuestionarioSegPsicologicaEnum
  ): boolean {
    return (
      flExibeSegurancaPsicologica !=
        ResponderQuestionarioSegPsicologicaEnum.NAO && perguntas?.length > 0
    );
  }

  salvar(request: RespostaSegurancaPsicologiaTO) {
    this.logService.append(
      `Finalizou Seguranca Psicologica, encaminhando para Perguntas Epworth`
    );
    this.sessionStorageService.setItem(
      SessionStorageKeys.RESPOSTAS_PSICOLOGIA,
      request
    );
    this.routerService.toEmociograma();
  }
}
