import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { getCookie, setCookie } from "./app/shared/utils/cookie.utils";
import v from "./assets/version.json";

if (environment.production) {
  enableProdMode();
}

const tratarMudancaVersao = () => {
  const oldVersion = getCookie("_sme-version");
  const currentVersion = v.version;
  if (!oldVersion || oldVersion != currentVersion) {
    localStorage.clear();
    sessionStorage.clear();
    setCookie("_sme-version", v.version, 365);
  }
};

tratarMudancaVersao();
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
