import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SessionStorageService } from "./session-storage.service";
import { PageNames } from "../enums/page-names.enum";
import { SessionStorageKeys } from "../enums/session-storage-keys.enum";
import { environment } from "src/environments/environment";
import { ModulosEnum } from "../enums/Modulos.enum";

@Injectable({
  providedIn: "root",
})
export class RouterService {
  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) {}

  getRootUrl() {
    return window.location.protocol + "//" + window.location.hostname;
  }

  toIntroducao() {
    this.setCurrentUrlAndNavigate(PageNames.INTRODUCAO);
  }

  toPerguntas() {
    this.setCurrentUrlAndNavigate(PageNames.PERGUNTAS);
  }

  toPerguntasInicial() {
    this.setCurrentUrlAndNavigate(PageNames.PERGUNTASINICIAL);
  }

  toPerguntasMatutinidade() {
    this.setCurrentUrlAndNavigate(PageNames.PERGUNTAS_MATUTINIDADE);
  }

  toSaudeMental() {
    this.setCurrentUrlAndNavigate(PageNames.SAUDE_MENTAL);
  }

  toTeste() {
    this.setCurrentUrlAndNavigate(PageNames.TESTE);
  }

  toTesteSimulacao() {
    this.setCurrentUrlAndNavigate(PageNames.SIMULACAO_TESTE);
  }

  toIntroducaoSimulacao() {
    this.setCurrentUrlAndNavigate(PageNames.SIMULACAO_INTRODUCAO);
  }

  toPerguntaCompInseguro() {
    this.setCurrentUrlAndNavigate(PageNames.PERGUNTAS_COMPORTAMENTO_INSEGURO);
  }

  toPerguntasSamPerelli() {
    this.setCurrentUrlAndNavigate(PageNames.PERGUNTAS_SAM_PERELLI);
  }

  toPerguntasAvulsoCliente() {
    this.setCurrentUrlAndNavigate(PageNames.PERGUNTAS_AVULSO_CLIENET);
  }

  toPerguntasKarolinska() {
    this.setCurrentUrlAndNavigate(PageNames.PERGUNTAS_KAROLINSKA);
  }

  toResultado() {
    this.setCurrentUrlAndNavigate(PageNames.RESULTADO);
  }

  toDesempenho() {
    this.setCurrentUrlAndNavigate(PageNames.DESEMPENHO);
  }

  toProcessarTeste() {
    this.setCurrentUrlAndNavigate(PageNames.PROCESSAR_TESTE);
  }

  toAbordagensNotificacoes() {
    this.setCurrentUrlAndNavigate(PageNames.ABORDAGENS);
  }

  toDica() {
    this.setCurrentUrlAndNavigate(PageNames.DICA);
  }

  toConfiguracao() {
    this.setCurrentUrlAndNavigate(PageNames.CONFIGURACAO);
  }

  toRedirect() {
    this.setCurrentUrlAndNavigate(PageNames.REDIRECT);
  }

  toNivelProntidao() {
    this.setCurrentUrlAndNavigate(PageNames.NIVELPRONTIDAO);
  }

  toInicio() {
    this.setCurrentUrlAndNavigate(PageNames.INICIO);
  }

  toComunicados() {
    this.setCurrentUrlAndNavigate(PageNames.COMUNICADOS);
  }

  toFinalizar(modulo: ModulosEnum) {
    this.setCurrentUrlAndNavigate(PageNames.FINALIZAR, { modulo });
  }

  toEmociograma() {
    this.setCurrentUrlAndNavigate(PageNames.EMOCIOGRAMA);
  }

  toPerguntasEpworth() {
    this.setCurrentUrlAndNavigate(PageNames.PERGUNTAS_EPWORTH);
  }

  toEmociogramaInicial() {
    this.setCurrentUrlAndNavigate(PageNames.EMOCIOGRAMA_INICIO);
  }

  toSegurancaPsicologicaInicial() {
    this.setCurrentUrlAndNavigate(PageNames.SEGURANCA_PSICOLOGICA_INICIO);
  }

  toSegurancaPsicologica() {
    this.setCurrentUrlAndNavigate(PageNames.SEGURANCA_PSICOLOGICA);
  }

  goToPortal() {
    const urlPortal =
      location.protocol +
      "//" +
      environment.cliente +
      "-portal.sistemaprontos.com.br";
    window.location.href = urlPortal;
  }

  goToEAD() {
    const urlPortal =
      location.protocol +
      "//" +
      environment.cliente +
      "-ead.sistemaprontos.com.br";
    window.location.href = urlPortal;
  }

  private setCurrentUrlAndNavigate(pageName: PageNames, queryParams?: any) {
    this.sessionStorageService.setItem<PageNames>(
      SessionStorageKeys.CURRENT_URL,
      pageName
    );
    this.router.navigate([pageName], { queryParams });
  }
}
