<app-header></app-header>

<p-dialog
  [visible]="showQuestionario"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{width: '90%', 'max-width': '640px', 'max-height': '98vh', 'overflow': 'auto'}"
  [closable]="false">
  <p-header>
    <div class="dialog-title p-dialog-titlebar">
      <span translate tabindex="0" [innerHTML]="'compInseguro.titulo' | translate"> </span>
    </div>
  </p-header>

  <app-questionario-geral [questionarios]="questionarios" [loading]="loading"></app-questionario-geral>
</p-dialog>