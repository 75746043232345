import * as CryptoJS from 'crypto-js';
import { dispatchError } from 'src/app/core/dispatchError';

export default class EncryptUtils {

  TOKEN: string = "x)Hh${f445.Cq>P-4CC+wZ3J@M";

  encrypt(json: string) {
    try {
      return CryptoJS.AES.encrypt(json, this.TOKEN).toString();
    } catch (err) {
      dispatchError("Criptografar", err);
      return json;
    }
  }

  decrypt(encrypted: string) {
    if (encrypted == null || encrypted == undefined) return;
    try {
      return CryptoJS.AES.decrypt(encrypted, this.TOKEN).toString(CryptoJS.enc.Utf8);
    } catch (err) {
      dispatchError("Descriptografar", err);
      return encrypted;
    }
  }
}
