import { Injectable } from "@angular/core";
import { TipoDispositivoService } from "./tipo-dispositivo.service";
import { BROWSER_VERSIONS } from "src/app/config/BrowserVersions";
import { dispatchEvent } from "src/app/core/dispatchEvent";

@Injectable({
  providedIn: "root",
})
export class BrowserVersionService {
  constructor(private tipoDispositivoService: TipoDispositivoService) {}

  isValidBrowserVersion(): boolean {
    const browser = this.tipoDispositivoService.getInfoDevice()?.browser?.name;
    const browserVersion = Number(
      this.tipoDispositivoService
        .getInfoDevice()
        ?.browser?.version?.split(".")?.[0]
    );
    if (!browser || !browserVersion) {
      dispatchEvent("BROWSER_VERSION", {
        message: "Não foi possível detectar versão",
        browser,
        browserVersion,
        userAgent: navigator.userAgent,
      });
      return true;
    }

    const minVersion = BROWSER_VERSIONS.find(
      (b) => browser.indexOf(b.name) >= 0
    )?.minVersion;
    if (!minVersion) {
      dispatchEvent("BROWSER_VERSION", {
        message: "Versão do browser não mapeada",
        browser,
        browserVersion,
        userAgent: navigator.userAgent,
      });
      return true;
    }

    const isValidBrowser = minVersion <= browserVersion;
    if (!isValidBrowser) {
      dispatchEvent("BROWSER_VERSION", {
        message: "Versão do browser menor que o permitido",
        browser,
        browserVersion,
        minVersion,
        userAgent: navigator.userAgent,
      });
    }
    if (minVersion + 3 >= browserVersion) {
      dispatchEvent("BROWSER_VERSION", {
        message: "Versão do browser próximo do permitido",
        browser,
        browserVersion,
        minVersion,
        userAgent: navigator.userAgent,
      });
    }

    return isValidBrowser;
  }
}
