import { Injectable } from '@angular/core';
import { StorageCommon } from './storage-common.service';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService extends StorageCommon {

  constructor() {
    super(sessionStorage)
  }
}
