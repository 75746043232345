import { RespostaPerguntaTO } from './resposta-pergunta.to';
import { RespostaPerguntaDiscursivaTO } from './resposta-pergunta-discursiva.to';
import { JsonObject, JsonProperty, PropertyConvertingMode } from 'json2typescript';


@JsonObject("TesteInicialTO")
export class TesteInicialTO {
    @JsonProperty("aa", Number, PropertyConvertingMode.IGNORE_NULLABLE)
    idUsuario: number = null;

    @JsonProperty("ab", [RespostaPerguntaTO], PropertyConvertingMode.IGNORE_NULLABLE)
    respostaPerguntas: RespostaPerguntaTO[] = null;
    
    @JsonProperty("ac", [RespostaPerguntaDiscursivaTO], PropertyConvertingMode.IGNORE_NULLABLE)
    respostaPerguntasDiscursivas: RespostaPerguntaDiscursivaTO[] = null;
}
  