import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { ResponseListNovidadeTO } from "../tos/response-novidade.to";

@Injectable({
  providedIn: "root",
})
export class NovidadesService {
  constructor(private httpClient: HttpClient) {}

  private versionAPI: string = "/v1";
  private api: string = "/geral-api";
  private path: string = "/novidade";
  private url: string = this.api + this.versionAPI + this.path;

  getNovidadesCompleto(nrPagina: number, qtRegistrosPagina: number = 6) {
    const params = new HttpParams()
      .append("nrPagina", nrPagina)
      .append("qtRegistrosPagina", qtRegistrosPagina);
    return this.httpClient
      .get<ResponseListNovidadeTO>(`${this.url}/operador`, { params })
      .pipe(
        map((result) => {
          const palavrasPorMinuto = 200;
          for (let novidade of result.dado) {
            const qtdPalavras = novidade.dsConteudoHtml.split(" ")?.length;
            novidade.tempoLeitura = qtdPalavras
              ? Math.ceil(qtdPalavras / palavrasPorMinuto)
              : 1;
          }
          return result;
        })
      );
  }
}
