import { JsonObject, JsonProperty, PropertyConvertingMode } from "json2typescript";

@JsonObject("RespostaPerguntaTO")
export class RespostaPerguntaTO {
  @JsonProperty("ah1", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  id: number = null;

  @JsonProperty("ah2", Boolean, PropertyConvertingMode.IGNORE_NULLABLE)
  flMarcada: boolean = null;

  @JsonProperty("ah3", [Number], PropertyConvertingMode.IGNORE_NULLABLE)
  idItemOpcoes?: number[] = null;
}
