import { arDateTranslation } from "./ar-date-translation";
import { deDateTranslation } from "./de-date-translation";
import { enDateTranslation } from "./en-date-translation";
import { esDateTranslation } from "./es-date-translation";
import { hiDateTranslation } from "./hi-date-translation";
import { idDateTranslation } from "./id-date-translation";
import { ptDateTranslation } from "./pt-date-translation";
import { urDateTranslation } from "./ur-date-translation";
import { zhDateTranslation } from "./zh-date-translation";

// GET LOCALE HERE: https://github.com/primefaces/primelocale
export const dateTranslateConfig = {
  pt: ptDateTranslation,
  en: enDateTranslation,
  es: esDateTranslation,
  ar: arDateTranslation,
  hi: hiDateTranslation,
  ur: urDateTranslation,
  de: deDateTranslation,
  id: idDateTranslation,
  zh: zhDateTranslation,
};
