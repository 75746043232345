import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { AnimationEvent } from "@angular/animations";
import { NotificationService } from "../../services/notification.service";
import { Notification } from "../../view/notification.view";
import { ConfirmationService } from "primeng/api";
import { ConfirmDialog } from "primeng/confirmdialog";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-dialog-notification",
  templateUrl: "./dialog-notification.component.html",
  styleUrls: ["./dialog-notification.component.scss"],
})
export class DialogNotificationComponent implements OnInit {
  isShowingNotification: boolean;
  pendingNotifications: Notification[] = [];
  dsTituloLabel: string;
  dsOkLabel: string;
  acceptVisible: boolean = true;
  rejectVisible: boolean = false;
  rejectLabel: string;

  @ViewChild(ConfirmDialog, { static: true })
  confirmDialogComponent: ConfirmDialog;

  constructor(
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.notificationService.listen().subscribe((notification) => {
      this.showNotification(notification);
    });
  }

  showNotification(notification: Notification) {
    this.dsTituloLabel = this.translateService.instant("notificacao.titulo");
    this.dsOkLabel =
      notification.notificationConfig.acceptLabel ||
      this.translateService.instant("notificacao.ok");
    this.rejectLabel =
      notification.notificationConfig.rejectLabel ||
      this.translateService.instant("nao");
    this.acceptVisible =
      notification.notificationConfig.acceptVisible !== undefined
        ? notification.notificationConfig.acceptVisible
        : true;
    this.rejectVisible =
      notification.notificationConfig.rejectVisible !== undefined
        ? notification.notificationConfig.rejectVisible
        : false;
    this.confirmationService.confirm({
      rejectVisible: this.rejectVisible,
      header: this.dsTituloLabel,
      acceptLabel: this.dsOkLabel,
      rejectLabel: this.rejectLabel,
      message: notification.notificationConfig.message,
      key: "confirmDialog",
      accept: () => {
        setTimeout((_) => {
          if (notification.notificationConfig.handler) {
            notification.notificationConfig.handler();
          }
          this.cdr.detectChanges();
        });
      },
      reject: () => {
        notification.notificationConfig.handlerReject &&
          notification.notificationConfig.handlerReject();
      },
    });

    //Adicionado para resolver bug TODO melhorar descrição
    this.cdr.detectChanges();
  }

  onCloseNotification() {
    if (this.pendingNotifications.length) {
      const nextNotification = this.pendingNotifications.shift();
      this.showNotification(nextNotification);
    }
  }
}
