<app-card-opacity *ngIf="!habilitado" translate>
  {{ "inicio.mensagemBloqueado" | translate }}
  <a (click)="testeProntidaoClick.emit()" translate> inicio.testeProntidao </a>
</app-card-opacity>

<div [ngClass]="{ blur: !habilitado }">
  <h2 class="titulo" translate>avisos.titulo</h2>
  <p class="disclaimer" translate>inicio.comunicadosDescricao</p>
  <app-inicio-card>
    <div
      class="loader p-m-auto"
      style="position: relative; left: auto; top: auto"
      *ngIf="isLoading"
    ></div>
    <div
      class="p-text-center"
      *ngIf="!isLoading && !novidades?.length"
      translate
    >
      inicio.semComunicados
    </div>
    <div
      class="comunicado-item"
      [ngClass]="{ '--isOpen': comunicado.isOpen }"
      *ngFor="let comunicado of novidades"
    >
      <div id="col-img" class="comunicado-col">
        <img
          class="comunicado-img"
          [src]="comunicado.dsLinkImagem"
          [alt]="comunicado.dsTitulo"
        />
      </div>
      <div id="col-textos" class="comunicado-col">
        <p class="comunicado-titulo" [innerHTML]="comunicado.dsTitulo"></p>
        <p
          class="comunicado-resume"
          [innerHTML]="comunicado.dsConteudoHtml"
        ></p>
      </div>
      <div id="col-button" class="comunicado-col">
        <button
          type="button"
          class="comunicado-button"
          (click)="toggleComunicado(comunicado)"
        >
          <i
            class="pi pi-angle-down"
            [ngClass]="{ turn: comunicado.isOpen }"
          ></i>
        </button>
      </div>
    </div>
    <button
      *ngIf="!isLoading && novidades?.length"
      type="button"
      class="botao-ver-mais"
      (click)="goToComunicados()"
      translate
    >
      inicio.todosAvisos
    </button>
  </app-inicio-card>
</div>
