import { Component, OnInit } from "@angular/core";
import { TesteService } from "../../api-services/teste.service";
import { NotificationService } from "../../services/notification.service";
import { TranslateService } from "@ngx-translate/core";
import { ResultadoTesteTO } from "../../tos/resultado-teste.to";
import { SessionStorageKeys } from "../../enums/session-storage-keys.enum";
import { SessionStorageService } from "../../services/session-storage.service";

@Component({
  selector: "app-botao-conversa-lider",
  templateUrl: "./botao-conversa-lider.component.html",
  styleUrls: ["./botao-conversa-lider.component.scss"],
})
export class BotaoConversaLiderComponent implements OnInit {
  loadingApoio: boolean = false;
  isConversaHabilitado: boolean = true;
  exibirConversaLider: boolean = false;

  constructor(
    private testeService: TesteService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    const resultado: ResultadoTesteTO = this.sessionStorageService.getItem(
      SessionStorageKeys.RESULTADO
    );
    this.exibirConversaLider = resultado.flExibirSolicitarConversa;
  }

  handleSolicitarApoio() {
    this.loadingApoio = true;
    this.testeService.solicitarApoio().subscribe(
      () => {
        this.loadingApoio = false;
        this.isConversaHabilitado = false;
        this.notificationService.notifySuccess({
          message: `
          <p> 
          ${this.translateService.instant(
            "resultado.conversarComLiderSucesso"
          )} 
          </p>
          <p> <strong> ${this.translateService.instant(
            "resultado.conversarComLiderSucesso2"
          )} 
          </strong></p>`,
          acceptLabel: this.translateService.instant(
            "resultado.conversarComLidarBotao"
          ),
        });
      },
      (err) => {
        this.loadingApoio = false;
        throw err;
      }
    );
  }
}
