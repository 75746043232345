import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";
import { ImagemCacheService } from "../../services/imagem-cache.service";
import { StringUtils } from "../../utils/StringUtils";

@Component({
  selector: "app-cached-img",
  templateUrl: "./cached-img.component.html",
  styleUrls: ["./cached-img.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CachedImgComponent implements OnInit, OnChanges {
  @ViewChild("imagem", { static: true }) imagemEl: ElementRef;
  @Output() imgDisplayed = new EventEmitter(false);
  @Input() src: string;
  @Input() alt: string = "";
  @Input() className: string;
  srcCached: SafeUrl | string;

  constructor(
    private imagemCacheService: ImagemCacheService,
    private elRef: ElementRef
  ) {}

  ngOnInit() {
    this.imagemEl && this.emitirImagemExibida();
  }

  emitirImagemExibida() {
    this.imgDisplayed.emit(Date.now());
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes?.src?.currentValue) {
      this.srcCached =
        (await this.imagemCacheService.getImagem(changes.src.currentValue)) ||
        changes.src.currentValue;
    } else {
      this.srcCached = changes?.src?.currentValue;
    }
  }
}
