import {
  JsonObject,
  JsonProperty,
  PropertyConvertingMode,
} from "json2typescript";
import { RespostaPerguntaMatutinidadeV2TO } from "./request-resposta-pergunta-matutinidade.to";

@JsonObject("RequestUploadMatutinidadeV2TO")
export class RequestUploadMatutinidadeV2TO {
  @JsonProperty(
    "an",
    [RespostaPerguntaMatutinidadeV2TO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  respostasPerguntasMatutinidade: RespostaPerguntaMatutinidadeV2TO[] = null;
}
