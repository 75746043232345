<div class="escala__container">
  <label [for]="id"> {{ label }} </label>
  <div class="escala__respostas">
    <ng-container *ngFor="let valor of [0,1,2,3]">
      <input 
        style="display: none;"
        type="radio" 
        [name]="id+'resposta'" 
        [(ngModel)]="escala" 
        [value]="valor" 
        [id]="id+valor" 
        [disabled]="disabled"
        (change)="escalaChanged()"
      />
      <label class="escala__respostas{{valor}}" [for]="id + valor" [title]="'epworth.probabilidade'+valor | translate"> {{ valor }} </label>
    </ng-container>
  </div>
</div>