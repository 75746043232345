import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsonSerializeService } from '../services/json-serialize.service';
import { RequestSalvarEmociogramaTO } from '../tos/request-salvar-emociograma.to';
import { ResponseValidarEmociogramaTO } from '../tos/response-validar-emociograma.to';

@Injectable({
  providedIn: 'root'
})
export class EmociogramaService {

  constructor(
    private httpClient: HttpClient,
    private serializer: JsonSerializeService
  ) {
  }

  validarEmociograma(): Observable<ResponseValidarEmociogramaTO> {
    return this.httpClient.post<ResponseValidarEmociogramaTO>(`v2/emociograma/validar`, {}).pipe(
      map(r => {
        return this.serializer.deserialize(r, ResponseValidarEmociogramaTO)
      })
    );
  }

  salvarEmociograma(idUsuario: number, idEmociograma: number): Observable<any> {
    const request = new RequestSalvarEmociogramaTO();
    request.idUsuario = idUsuario;
    request.idEmociograma = idEmociograma;
    const payload = this.serializer.serialize(request, RequestSalvarEmociogramaTO);
    return this.httpClient.put<any>(`v2/emociograma/upload`, payload);
  }
}
