<div class="container">
  <span *ngIf="exibirBreadcrumb" class="breadcrumb" tabindex="0">
    {{ "perguntas.textoQuestionario" | translate }}: {{ currentGroupIdx + 1 }} /
    {{ qtdQuestionarios }}
  </span>
  <form #form="ngForm" class="p-grid" (submit)="handleOnSubmit(form)">
    <div *ngIf="exibirStepper" class="stepper-content">
      <app-stepper [current]="currentGroupIdx + 1" [total]="qtdQuestionarios" />
    </div>
    <div class="questions-content">
      <p
        *ngIf="exibirTempoResposta"
        class="tempo-resposta"
        translate
        [translateParams]="{ '0': tempoResposta }"
      >
        questionarioGeral.tempoResposta
      </p>
      <p *ngIf="instrucao" class="instrucao">{{ instrucao }}</p>
      <ng-container *ngFor="let currentQuestion of perguntas; let i = index">
        <div
          class="option-container"
          role="group"
          [attr.aria-labelledby]="
            'label-grupo-' + currentGroupIdx + '-perg-' + currentQuestion.id
          "
        >
          <label
            class="titulo-pergunta"
            id="label-grupo-{{ currentGroupIdx }}-perg-{{ currentQuestion.id }}"
          >
            {{ i + 1 }}. {{ currentQuestion?.dsPergunta }}
          </label>
          <p-dropdown
            *ngIf="isSelect(currentQuestion.tipo)"
            [options]="currentQuestion.itens"
            [name]="currentQuestion.id"
            [(ngModel)]="currentQuestion.respostas"
            [required]="currentQuestion.obrigatorio"
          >
          </p-dropdown>

          <ng-container *ngIf="!isSelect(currentQuestion.tipo)">
            <div
              *ngFor="let opc of currentQuestion?.itens"
              class="p-field-checkbox checkbox-container"
            >
              <p-radioButton
                *ngIf="isRadio(currentQuestion.tipo)"
                [name]="currentQuestion.id"
                [(ngModel)]="currentQuestion.respostas"
                [inputId]="'opc' + currentIdx + opc.value"
                [value]="opc.value"
                [required]="currentQuestion.obrigatorio"
              />

              <p-checkbox
                *ngIf="isCheck(currentQuestion.tipo)"
                [name]="currentQuestion.id"
                [(ngModel)]="currentQuestion.respostas"
                [inputId]="'opc' + currentIdx + opc.value"
                [value]="opc.value"
                [required]="currentQuestion.obrigatorio"
              />
              <label
                class="radio-label"
                [attr.for]="'opc' + currentIdx + opc.value"
              >
                {{ opc.label }}
              </label>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <div class="button-content">
        <button
          type="submit"
          class="botao"
          [disabled]="form.invalid || loading"
        >
          <span class="loader" *ngIf="loading"></span>
          <span *ngIf="isUltimaPergunta()" translate> botaoFinalizar </span>
          <span *ngIf="!isUltimaPergunta()" translate> botaoProximo </span>
        </button>
      </div>
    </div>
  </form>
</div>
