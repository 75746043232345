import {
  JsonObject,
  JsonProperty,
  PropertyConvertingMode,
} from "json2typescript";

@JsonObject("RespostaPerguntaAdicionalV2TO")
export class RespostaPerguntaAdicionalV2TO {
  @JsonProperty("ab1", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idPergunta: number = null;

  @JsonProperty("ab2", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idOpcao: number = null;
}
