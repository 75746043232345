import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BooleanEnum } from "src/app/shared/enums/boolean.enum";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { LogService } from "src/app/shared/services/log.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { ConfiguracaoTesteTO } from "src/app/shared/tos/configuracao-teste.to";
import { wait } from "src/app/shared/utils/wait.util";

@Component({
  selector: "app-abordagens",
  templateUrl: "./abordagens.component.html",
  styleUrls: ["./abordagens.component.scss"],
})
export class AbordagensComponent implements OnInit {
  configuracao: ConfiguracaoTesteTO;

  constructor(
    private notificationService: NotificationService,
    private sessionStorageService: SessionStorageService,
    private translateService: TranslateService,
    private routerService: RouterService,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.logService.append(`Acessou Abordagens de Notificacoes`);
    this.configuracao = this.sessionStorageService.getItem(
      SessionStorageKeys.CONFIGURACAO
    );
    setTimeout(async () => {
      await this.exibirPopups();
      this.routerService.toProcessarTeste();
    });
  }

  async exibirPopups() {
    if (this.configuracao.flExibeAnaliseGestaoOperador === BooleanEnum.SIM) {
      this.logService.append(`Exibir notificar abordagem gestor`);
      await this.notificarAbordagemGestor();
      await wait(200);
    }
  }

  private notificarAbordagemGestor() {
    return new Promise((res) => {
      const responseUser = (accept: boolean) => {
        const acceptEnum = accept ? BooleanEnum.SIM : BooleanEnum.NAO;
        this.sessionStorageService.setItem(
          SessionStorageKeys.ANALISE_GESTAO_OPERADOR,
          acceptEnum
        );
        res(accept);
      };

      this.notificationService.notify({
        rejectVisible: true,
        acceptLabel: this.translateService.instant("sim"),
        handler: () => responseUser(true),
        handlerReject: () => responseUser(false),
        message: this.translateService.instant("notificacao.abordagemGestor"),
      });
    });
  }
}
