import {
  JsonObject,
  JsonProperty,
  PropertyConvertingMode,
} from "json2typescript";
import { RespostaSegurancaPsicologiaTO } from "./resposta-seguranca-psicologia.to";

@JsonObject("RequestUploadSegPsicologicaTO")
export class RequestUploadSegPsicologicaTO {
  @JsonProperty(
    "aa",
    RespostaSegurancaPsicologiaTO,
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  respostaSegurancaPsicologica: RespostaSegurancaPsicologiaTO = null;
}
