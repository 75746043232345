import { Component, OnInit } from "@angular/core";
import { LogService } from "src/app/shared/services/log.service";

@Component({
  selector: "app-dica",
  templateUrl: "./dica.component.html",
  styleUrls: ["./dica.component.scss"],
})
export class DicaComponent implements OnInit {
  dicaDia = "Dica do dia!";
  tituloDica = "Para uma boa noite de sono...";
  textoDica =
    '<div align="justify"><b>-Não coma muito e evite cafeína, nicotina e álcool pelo menos 4 horas antes de ir para a cama;<br><br> -Mantenha um ritmo de sono: vá para a cama na mesma hora todas asnoites e tente manter essa rotina nos fins de semana;</b></div>';
  constructor(private logService: LogService) {}

  ngOnInit() {
    this.logService.append("Acessou dicas");
  }
}
