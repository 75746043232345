import { JsonObject, JsonProperty, PropertyConvertingMode } from "json2typescript";

@JsonObject("SubBlocoTO")
export class SubBlocoTO {
  @JsonProperty("ah9a", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  id: number = null;

  @JsonProperty("ah9b", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  qtAlvosCorretos: number = null;

  @JsonProperty("ah9c", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  qtAlvosErrados: number = null;

  @JsonProperty("ah9d", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  qtTempoReset: number = null;

  @JsonProperty("ah9e", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  qtTempoDelay: number = null;

  @JsonProperty("ah9f", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  qtTempoExposicao: number = null;

  @JsonProperty("ah9g", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  qtTempoHide: number = null;

  @JsonProperty("ah9h", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  nrOrdem: number = null;
}
