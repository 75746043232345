<div class="p-grid bem-estar-container">
  <div
    *ngFor="let pergunta of getPerguntas(); let i = index"
    class="pergunta-item"
  >
    <input
      type="checkbox"
      [(ngModel)]="getRespostas()[i].flMarcada"
      [id]="'pergunta' + i"
    />
    <label
      [for]="'pergunta' + i"
      class="box-pergunta"
      tabindex="0"
      [attr.aria-checked]="getRespostas()[i].flMarcada"
      [attr.aria-label]="
        getRespostas()[i].flMarcada
          ? pergunta.dsPergunta + ' ' + pergunta.dsResposta
          : pergunta.dsPergunta
      "
      role="checkbox"
    >
      <span class="pergunta">
        {{ pergunta.dsPergunta }}
      </span>
      <span
        class="resposta"
        *ngIf="pergunta.dsResposta"
        [attr.aria-hidden]="!getRespostas()[i].flMarcada"
      >
        {{ pergunta.dsResposta }}
      </span>
    </label>
  </div>
</div>
