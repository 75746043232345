import { JsonObject, JsonProperty, PropertyConvertingMode } from "json2typescript";

@JsonObject("PerguntaOpcaoSegurancaPsicologicaTO")
export class PerguntaOpcaoSegurancaPsicologicaTO {
  @JsonProperty("as4a", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idOpcao: number = null;

  @JsonProperty("as4b", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsPergunta: string = null;

  @JsonProperty("as4c", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  nrOrdem: number = null;
}