export const dispatchError = (name, error) => {
  try {
    console.error(name, error);
    (window as any)?.newrelic?.noticeError(
      `#${name}: ${JSON.stringify({
        message: error?.message,
        stack: error?.stack,
        error: error,
      })}`
    );
  } catch {
    try {
      (window as any)?.newrelic?.noticeError(`##${name}: ${error}`);
    } catch (er) {
      console.log("NEWRELIC_ERROR_NOT_SEND", er);
      if ((window as any)?.newrelic?.noticeError) {
        (window as any)?.newrelic?.noticeError(`NEWRELIC_ERROR_NOT_SEND`);
      }
    }
  }
};
