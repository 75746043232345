<div class="botoes-holder" role="list">
  <button
    (click)="goToResultado()"
    #rlaResultado="routerLinkActive"
    routerLink="/resultado"
    routerLinkActive="--active"
    class="link"
    translate
    role="tab"
    [attr.aria-selected]="rlaResultado.isActive"
  >
    resultado.exibirDica
  </button>
  <button
    *ngIf="exibirDesempenho"
    (click)="goToDesempenho()"
    #rlaDesempenho="routerLinkActive"
    routerLink="/desempenho"
    routerLinkActive="--active"
    class="link"
    translate
    role="tab"
    [attr.aria-selected]="rlaDesempenho.isActive"
  >
    resultado.exibirResultado
  </button>
  <button
    *ngIf="exibirBotaoEvolucao()"
    (click)="goToNivelProntidao()"
    #rlaEvolucao="routerLinkActive"
    routerLink="/nivel-prontidao"
    routerLinkActive="--active"
    class="link"
    translate
    role="tab"
    [attr.aria-selected]="rlaEvolucao.isActive"
  >
    resultado.botaoNivelDeProntidao
  </button>

  <button
    *ngIf="exibirDownload"
    (click)="downloadComprovante()"
    class="link"
    role="listitem"
    translate
    [disabled]="loadingDownload"
  >
    resultado.downloadComprovante
    <div class="loader" *ngIf="loadingDownload"></div>
  </button>

  <app-botao-conversa-lider />

  <button class="link--destaque" (click)="concluir()" translate role="listitem">
    botaoFinalizar
  </button>
</div>
