<app-header></app-header>

<div class="conteudoDetalhe conteudoDetalheResultado">
  <h1
    class="texto-retorno"
    [innerHtml]="resultadoTesteTO.dsMensagemRetorno"
  ></h1>

  <div class="imagem-resultado" *ngIf="!isEmbedded">
    <app-cached-img
      src="assets/images/teste/resultado/{{ nmImagem }}"
      className="ilustracao"
    ></app-cached-img>
  </div>

  <p class="texto-id" *ngIf="exibirID">
    <strong translate>ID</strong>: {{ resultadoTesteTO.idTesteAtual }}
  </p>

  <ng-container *ngIf="exibirDica">
    <p class="dica-texto" id="resultado-dica" tabindex="0">
      <strong translate>resultado.aproveiteDica</strong>:
      {{ resultadoTesteTO.dsDica }} <br />
    </p>

    <app-botoes-resultado></app-botoes-resultado>
  </ng-container>

  <div class="botoes-holder">
    <button
      class="btn-continuar"
      [ngClass]="{ show: exibirBotaoContinuar }"
      (click)="continuar()"
      translate
    >
      resultado.continuar
    </button>

    <app-botao-conversa-lider [ngClass]="{ show: exibirBotaoContinuar }" />
    <button
      class="btn-finalizar"
      [ngClass]="{ show: exibirBotaoContinuar }"
      (click)="finalizar()"
      translate
    >
      botaoFinalizar
    </button>
  </div>
</div>
