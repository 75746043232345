import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";

import { Observable } from "rxjs";
import { KeycloakService } from "keycloak-angular";

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  constructor(private keycloakService: KeycloakService) {}

  private getLoginName() {
    return this.keycloakService?.getKeycloakInstance?.()?.tokenParsed
      ?.preferred_username;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.url.includes("prontos-temporario.s3") ||
      req.url.includes("prontos-temporario-dev-hmg.s3")
    ) {
      return next.handle(req);
    }

    const urlReq = req.clone({
      headers: req.headers.append("X-PRONTOS-WAF", this.getLoginName()),
    });
    return next.handle(urlReq);
  }
}
