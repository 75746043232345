<div class="acesso-rapido" *ngIf="exibirAcessoRapido">
  <h2 class="titulo" translate>inicio.acessoRapido</h2>
  <div class="p-grid">
    <ng-container *ngFor="let item of menuItens">
      <div class="p-col-6 p-md-3" *ngIf="item.exibir">
        <app-inicio-card>
          <div class="item-container">
            <app-cached-img
              class="item-img"
              [src]="item.img"
              [alt]="item.nome"
            />
            <label class="item-titulo" translate> {{ item.nome }} </label>
            <p *ngIf="item.descricao" class="item-descricao" translate>
              {{ item.descricao }}
            </p>
            <p *ngIf="item.msgAlerta" class="item-msgAlerta" translate>
              {{ item.msgAlerta }}
            </p>
            <button
              class="item-botao"
              (click)="item.onClick()"
              [disabled]="!item.habilitado"
              translate
            >
              {{ item.textoBotao }}
            </button>
          </div>
        </app-inicio-card>
      </div>
    </ng-container>
  </div>
</div>
