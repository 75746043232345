export enum TabelaAscii {
  numeral1 = 49,
  numeral2 = 50,
  numeral3 = 51,
  numeral4 = 52,
  numeral5 = 53,
  numeral6 = 54,
  numeral7 = 55,
  numeral8 = 56,
  numeral9 = 57,
  numeral0 = 48,
  teclaEnter = 13,
  teclaEspaco = 32,
}
