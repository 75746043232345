import {
  JsonObject,
  JsonProperty,
  PropertyConvertingMode,
} from "json2typescript";

@JsonObject("RequestPerguntaEpworh")
export class RequestPerguntaEpworh {
  @JsonProperty("af1", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  id: number = null;

  @JsonProperty("af2", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  vlEscala: number = null;
}
