import { DOCUMENT } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { environment } from "src/environments/environment";
import { ImagemCacheService } from "./shared/services/imagem-cache.service";
import { KeycloakService } from "keycloak-angular";
import { IdiomaService } from "./shared/services/idioma.service";
import { FocusService } from "./shared/services/focus.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "teste-web";

  constructor(
    private keycloakService: KeycloakService,
    private idiomaService: IdiomaService,
    private focusService: FocusService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.idiomaService.configurarServicoTraducao();
    this.focusService.focusOnNavigate();
    this.disableZoom();

    if (!environment.flEmbedded) {
      this.loadStyle("fonts.css");
    }
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName("head")[0];

    let themeLink = this.document.getElementById(
      "style" + styleName
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement("link");
      style.id = "style" + styleName;
      style.rel = "stylesheet";
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  disableZoom() {
    document.firstElementChild["style"].zoom = "reset";

    //Bloqueio da tecla Ctrl
    document.addEventListener("keydown", (e) => {
      if (
        (e.ctrlKey == true || e.metaKey == true) &&
        (e.which == 61 ||
          e.which == 107 ||
          e.which == 173 ||
          e.which == 109 ||
          e.which == 187 ||
          e.which == 189)
      ) {
        e.preventDefault();
      }
    });

    //Bloqueio do scroll do mouse
    document.addEventListener(
      "mousewheel",
      (e) => {
        if (e["ctrlKey"]) {
          e.preventDefault();
        }
      },
      { passive: false }
    );

    document.addEventListener(
      "DOMMouseScroll",
      (e) => {
        if (e["ctrlKey"]) {
          e.preventDefault();
        }
      },
      { passive: false }
    );

    //Bloqueio do zoom no modo mobile
    document.addEventListener(
      "touchmove",
      function (event) {
        event = event["originalEvent"] || event;
        if (event["scale"] > 1) {
          event.preventDefault();
        }
      },
      false
    );

    document.addEventListener("gesturestart", function (e) {
      e.preventDefault();
    });
  }
}
